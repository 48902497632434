import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Utils } from "Utils/utils";
import { sequenceRenderState } from "Pages/Dashboard/sequences/SequencePage/sequenceProperties";

const StepsList = observer((props = {}) => {
  const {
    metricData = {},
    analyticStepLoader = true,
    setAnalyticStepLoader = () => {},
    addFilter = () => {},
    setMetricData = () => {},
    getTableContacts = () => {},
    getStepMetricData = () => {}
  } = props || {};

  const stepFilterAction = (currentStep = null) => {
    let tempCurrentStep = currentStep;
    const existingFilterByStep = sequenceRenderState?.filterByStep;
    if (+existingFilterByStep === currentStep) {
      removeFilter();
      tempCurrentStep = "all";
    } else {
      addFilter(tempCurrentStep, metricData);
    }
    getTableContacts(sequenceRenderState?.currentStage);
  };

  const removeFilter = () => {
    const tempData = Utils.createObjWithoutRef(metricData);
    if (tempData?.length > 0) {
      tempData.map(val => (val.selected = false));
    }
    setMetricData(tempData);
    sequenceRenderState.setFilterByStep(null);
  };

  useEffect(() => {
    getStepMetricData();
  }, []);

  return (
    <div className="stepsListWrapper">
      {analyticStepLoader ? (
        <Loader />
      ) : (
        <div className="stepsListCont">
          {metricData?.map((data = {}) => {
            const {
              currentStep = 0,
              count = 0,
              order = 0,
              all = false,
              selected = false
            } = data || {};
            const label = all ? "All Step" : "Step " + order;
            return (
              <div
                key={"stepData" + order}
                className={`stepData${all ? " allStep" : ""}${
                  selected ? " selected" : ""
                }`}
                onClick={() => stepFilterAction(currentStep)}
              >
                <div className="stepLabel">{label}</div>
                <div className="stepValue">
                  {Utils.formatNumberToString(count)}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
});

const Loader = () => {
  return (
    <div className="stepsListCont stepListloader">
      {Array.from({ length: 25 }, (value, index) => (
        <div key={"stepsMetricLoader" + index} className="allStep stepData">
          <div className="linear-background" />
        </div>
      ))}
    </div>
  );
};

export default StepsList;
export { StepsList };
