import React from "react";
import DateUtils from "Utils/DateUtils";
import { syncLogsHistoryPopupState } from "./state";
import { observer } from "mobx-react";

const syncStatusConfig = {
  PROCESSED: "Completed",
  IN_PROGRESS: "In Progress",
  FAILED: "Failed"
};

const syncSourceConfig = {
  sync_now: "Manual",
  polling: "Polling"
};

const TableBody = observer(() => {
  return syncLogsHistoryPopupState?.loader ? (
    <TableBodyLoader />
  ) : syncLogsHistoryPopupState?.data?.length > 0 ? (
    <div className="popupTableContent">
      {syncLogsHistoryPopupState.data.map((item, index) => {
        return (
          <div className="tableWrapper" key={`${item?.updateTs}-${Date.now}`}>
            <span className="syncDate">
              {DateUtils.convertDateFormat(item?.updateTs)}
            </span>
            <span className="syncSource">
              {syncSourceConfig[item?.source?.toLowerCase()]}
            </span>
            <span className="syncStatus">{syncStatusConfig[item?.status]}</span>
            <span className="addedCount">{item?.addedCount}</span>
            <span className="updatedCount">{item?.updatedCount}</span>
            <span className="failedCount">{item?.failedCount}</span>
          </div>
        );
      })}
    </div>
  ) : (
    <div className="noLogsFound borderDone">No Logs found</div>
  );
});

const TableBodyLoader = () => {
  return (
    <div className="popupTableContent popupTableContentLoader">
      {Array.from({ length: 10 }, (value, index) => (
        <div className="tableWrapper" key={`${index}-${Date.now}`}>
          <span className="syncDate">
            <div className="linear-background" />
          </span>
          <span className="syncSource">
            <div className="linear-background" />
          </span>
          <span className="syncStatus">
            <div className="linear-background" />
          </span>
          <span className="addedCount">
            <div className="linear-background" />
          </span>
          <span className="updatedCount">
            <div className="linear-background" />
          </span>
          <span className="failedCount">
            <div className="linear-background" />
          </span>
        </div>
      ))}
    </div>
  );
};

export { TableBody };
export default TableBody;
