import React, { useState } from "react";
import { ReactComponent as FilterIcon } from "Assets/svg/filter.svg";
import DropDownMenu from "Components/common/DropDownMenu";
import { syncLogsHistoryPopupState } from "./state";

const TableHeader = () => {
  const [showDropdown, setShowDropdown] = useState(false);

  const handleFilterAction = (ref, item) => {
    setShowDropdown(false);
    syncLogsHistoryPopupState.setLimit(10);
    syncLogsHistoryPopupState.setPageNo(1);
    syncLogsHistoryPopupState.setSource(
      item?.name?.toLowerCase() === "all" ? "" : item?.name
    );
  };

  const tableHeader = [
    { label: "Date", name: "syncDate" },
    {
      label: "Type",
      name: "syncSource",
      hasFilter: true,
      filterOptions: [
        {
          value: "Polling",
          key: "Polling",
          name: "POLLING",
          method: handleFilterAction
        },
        {
          value: "Manual",
          key: "Manual",
          name: "SYNC_NOW",
          method: handleFilterAction
        },
        {
          value: "All",
          key: "All",
          name: "ALL",
          method: handleFilterAction
        }
      ]
    },
    {
      label: "Status",
      name: "syncStatus"
    },
    { label: "Contacts Added", name: "addedCount" },
    { label: "Contacts Updated", name: "updatedCount" },
    { label: "Contacts Failed", name: "failedCount" }
  ];

  return (
    <div className="popupTableHeader">
      {tableHeader.map(item => (
        <div className="flexCenterWithBorder">
          <span className={item?.name}>{item?.label}</span>
          {item?.hasFilter && (
            <div className="filterWrapper">
              <span
                className="filterIcon"
                onClick={() => setShowDropdown(true)}
              >
                <FilterIcon />
              </span>
              {showDropdown && (
                <>
                  <DropDownMenu
                    options={item?.filterOptions}
                    referenceId="filterByDropdown"
                  />
                  <div
                    className="filterDropdownOverlay"
                    onClick={() => setShowDropdown(false)}
                  />
                </>
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export { TableHeader };
export default TableHeader;
