import React, { useContext } from "react";
import Utils from "Utils/utils";
import { observer } from "mobx-react";
import { ReactComponent as LinkedinIcon } from "Assets/svg/linkedin.svg";
import { outboxStoreData } from "./outboxStore";
import { OutboxContext } from "./main";

const TopFilterContents = observer(({}) => {
  const {
    inputValues = {},
    setAllEmailsSelectedCheckbox = () => {},
    setAllEmailsSelectedInView = () => {}
  } = useContext(OutboxContext);

  const { filterObject = {}, filterLoading = false } = inputValues || {};

  const {
    count = 0,
    activeCount = 0,
    openCount = 0,
    clickCount = 0,
    replyCount = 0,
    sentCount = 0,
    bounceCount = 0,
    nonOpenedCount = 0,
    failedCount = 0,
    autoReplyCount = 0,
    liCrAcceptedCount = 0,
    liCrSentCount = 0,
    profileViewsCount = 0
  } = filterObject || {};

  const filterData = [
    {
      label: "Replied",
      name: "replied",
      id: "REPLY_COUNT",
      count: replyCount || 0
    },
    {
      label: "Scheduled",
      name: "scheduled",
      id: "ACTIVE",
      count: activeCount || 0
    },
    {
      label: "Sent",
      name: "delivered",
      id: "SENT",
      count: sentCount || 0
    },
    {
      label: "All",
      name: "all",
      id: "ALL",
      count: count || 0
    },
    {
      label: "Opened",
      name: "opened",
      id: "OPEN_COUNT",
      count: openCount || 0
    },
    {
      label: "Clicked",
      name: "clicked",
      id: "CLICK_COUNT",
      count: clickCount || 0
    },
    {
      label: "Bounced",
      name: "bounced",
      id: "BOUNCE_COUNT",
      count: bounceCount || 0
    },
    {
      label: "Not Opened",
      name: "not_opened",
      id: "NOT_OPEN_COUNT",
      count: nonOpenedCount || 0
    },
    {
      label: "Failed",
      name: "failed",
      id: "ERROR",
      count: failedCount || 0
    },
    {
      label: "Auto Replies",
      name: "auto_reply",
      id: "AUTO_REPLY_COUNT",
      count: autoReplyCount || 0
    },
    {
      label: "Requests sent",
      name: "requests_sent",
      id: "LI_CR_SENT_COUNT",
      count: liCrSentCount || 0,
      isFromLi: true
    },
    {
      label: "Accepted",
      name: "accepted",
      id: "LI_CR_ACCEPTED_COUNT",
      count: liCrAcceptedCount || 0,
      isFromLi: true
    },
    {
      label: "Profiles Viewed",
      name: "profiles_viewed",
      id: "LI_PROFILE_VIEWS_COUNT",
      count: profileViewsCount || 0,
      isFromLi: true
    }
  ];

  const filterAction = (item = {}) => {
    const { name = "", id = "" } = item || {};
    outboxStoreData.setCurrentPage(1);
    outboxStoreData.setActiveFilter({ name, id });
    setAllEmailsSelectedCheckbox(false);
    setAllEmailsSelectedInView(false);
    outboxStoreData.setActiveOutboxId("");
  };

  return (
    <div className="filterContentsWrapper" id="outboxFilter">
      {!filterLoading && filterData?.length > 0
        ? filterData?.map((item, key) => {
            const {
              name = "",
              count = 0,
              label = "",
              isFromLi = false
            } = item || {};
            return (
              <div
                className={`filterContainer ${
                  outboxStoreData?.activeFilter?.name === name ? "active" : ""
                }`}
                key={key}
                onClick={() => filterAction(item)}
              >
                <div className="filterOption">
                  <div className="filterValueCont">
                    <div className="flexValue">
                      <span className="filterValue">
                        {count ? Utils.formatNumberToString(count) : 0}
                      </span>
                      {isFromLi && (
                        <span className="liIcon">
                          <LinkedinIcon />
                        </span>
                      )}
                    </div>
                    <span className="filterLabel">{label}</span>
                  </div>
                </div>
              </div>
            );
          })
        : Array.from({ length: 13 }, (_, index) => (
            <li
              className="linear-background"
              key={`seqContactsNavLoader${index}`}
            />
          ))}
    </div>
  );
});

export { TopFilterContents };
export default TopFilterContents;
