export const textOperatorList = [
  {
    value: "<span class='conditionSymbol'>&equals;</span> (Equals)",
    subKey: "EQUALS",
    key: "equalCond"
  },
  {
    value: "<span class='conditionSymbol'>&#8717;</span> (Contains)",
    subKey: "CONTAINS",
    key: "containCond"
  },
  {
    value: "<span class='conditionSymbol'>&ne;</span> (Not equals)",
    subKey: "NOT_EQUALS",
    key: "notEqualCond"
  },
  {
    value: "<span class='conditionSymbol'>&#94;</span> (starts with)",
    subKey: "STARTS_WITH",
    key: "startsWithCond"
  },
  {
    value: "<span class='conditionSymbol'>&#36;</span> (ends with)",
    subKey: "ENDS_WITH",
    key: "endsWithCond"
  }
];

export const booleanOptions = [true, false];
export const liConnectionReqOptions = ["Sent", "Accepted"];
export const liMsgOptions = ["Sent", "Replied"];

export const websiteFilters = [
  {
    value: "Website Visit Count",
    subKey: "WEBSITEVISITEDCOUNT",
    key: "websiteVisitedCount "
  },
  {
    value: "Has Visited Website",
    subKey: "HASVISITEDWEBSITE",
    key: "hasVisitedWebsite"
  },
  {
    value: "Website Visit URL",
    subKey: "WEBSITEVISITEDURL",
    key: "websiteVisitedUrl"
  }
];

export const linkedInFilters = [
  {
    value: "LinkedIn connection request",
    subKey: "LINKEDINCONNNECTIONREQUEST",
    key: "linkedInConnectionRequest"
  },
  {
    value: "LinkedIn message",
    subKey: "LINKEDINMESSAGE",
    key: "linkedInMessage"
  },
  {
    value: "LinkedIn profile viewed",
    subKey: "PROFILEVIEWSCOUNT",
    key: "profileviewscount"
  }
];

export const contactMetricFilters = [
  {
    value: "Reply Count",
    subKey: "REPLIED",
    key: "replyCount"
  },
  {
    value: "Open Count",
    subKey: "OPENED",
    key: "openCount"
  },
  {
    value: "Click Count",
    subKey: "CLICKED",
    key: "clickCount"
  },
  {
    value: "Sent Count",
    subKey: "SENT",
    key: "sentCount"
  },
  {
    value: "Calls Made",
    subKey: "CALLMADE",
    key: "callsMade"
  },
  {
    value: "Calls Logged",
    subKey: "CALLLOGGED",
    key: "callsLogged"
  }
];
