import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import userDetail from "Stores/userDetail";
import { toasterState } from "Components/common/toaster";
import { emailPreviewContentPopupState } from "Components/PreviewPopup/EmailPreviewContentPopup";
import LiveFeedState from "../state";
import { LiveFeedMainContext } from "../main";
import { LiveFeedContext } from "./LiveFeed";
import AccordionContent from "./AccordionContent";
import DateUtils from "Utils/DateUtils";
import { Utils } from "Utils/utils";
import { ContactUtils } from "Utils/ContactUtils";
import InboxUtils from "Utils/InboxUtils";

const intervals = [
  { label: "year", seconds: 31536000 },
  { label: "month", seconds: 2592000 },
  { label: "day", seconds: 86400 },
  { label: "hour", seconds: 3600 },
  { label: "min", seconds: 60 },
  { label: "Just Now", seconds: 0 }
];

const HeaderSection = () => {
  const {
    handleAccordionClick,
    currentAccordion,
    MAIL_STATUS_CONFIG,
    gmailMapper
  } = useContext(LiveFeedMainContext);

  const {
    index,
    emailDetails: {
      id = "",
      emailData = {},
      autoReplyType = "",
      clickCount = 0,
      viewCount = 0,
      updateTs = "",
      source = "",
      connectedInboxType = "",
      stage = "",
      sequenceName = "",
      sequenceStageNumber = "",
      sequenceStageType = "",
      eventType: emailDetailsEventType = "",
      syncedEmail = false,
      contactId = "",
      meetingBookedDate = "",
      meetingBookedTimeZone = "",
      replyContent = "",
      replySource = "",
      firstName = "",
      lastName = "",
      email = ""
    } = {},
    trackedEmailStatus = []
  } = useContext(LiveFeedContext);

  const {
    to = "",
    from = "",
    subject = "",
    messageId = "",
    threadId = ""
  } = emailData || {};
  const history = useHistory();

  const isMigratedMessageId = messageId?.includes("SG_");
  const finalThreadId = threadId
    ? threadId
    : isMigratedMessageId
    ? ""
    : messageId;

  let eventType = [
    "li_message_sent",
    "li_connection_req_sent",
    "li_connection_req_accepted"
  ].includes(emailDetailsEventType?.toLowerCase())
    ? emailDetailsEventType
    : !syncedEmail
    ? trackedEmailStatus?.[0]?.eventType
    : emailDetailsEventType;

  const stageEvent = {
    reply: "replied",
    bounce: "bounced",
    optout: "optout",
    opted_out: "opted_out",
    click: "clicked",
    views: "opened",
    un_open: "sent",
    meeting_booked: "meeting_booked",
    auto_reply: "auto_reply"
  };

  const updatedEventType =
    replySource?.toLowerCase() === "linkedin"
      ? "li_replied"
      : [
          "reply",
          "bounce",
          "optout",
          "opted_out",
          "meeting_booked",
          "auto_reply"
        ].includes(stage?.toLowerCase() || "")
      ? stageEvent[stage?.toLowerCase()]
      : eventType?.toLowerCase() || stageEvent[stage?.toLowerCase()] || "";

  const getIsFromLinkedIn = () => {
    let isFromLinkedIn = false;
    let isSendMsg = false;
    if (replySource?.toLowerCase() === "linkedin") {
      isFromLinkedIn = true;
      isSendMsg = true;
    }
    if (updatedEventType?.toLowerCase() === "li_message_sent") {
      isSendMsg = true;
    }
    if (
      [
        "li_connection_req_sent",
        "li_connection_req_accepted",
        "li_message_sent"
      ].includes(updatedEventType?.toLowerCase())
    ) {
      isFromLinkedIn = true;
    }
    return { isFromLinkedIn, isSendMsg };
  };

  const { isFromLinkedIn = false, isSendMsg = false } = getIsFromLinkedIn();

  const handleLinkedInPreview = event => {
    event && Utils.preventDefaultFn(event);
    if (isSendMsg) {
      LiveFeedState.getLinkedInReplyForActivity(contactId);
    }
  };

  const enableAccordion =
    !isFromLinkedIn &&
    ![
      "sent",
      "bounced",
      "optout",
      "opted_out",
      "received_email",
      "meeting_booked",
      "auto_reply"
    ].includes(updatedEventType);
  let isOptoutContact = ["optout", "opted_out"]?.includes(updatedEventType);
  let isMeetingBooked = ["meeting_booked"]?.includes(updatedEventType);

  const isOutlookSequenceEmail =
    (source?.toLowerCase() === "sequence" &&
      connectedInboxType !== "gmail_oath") ||
    false;

  const isOutlookEmail = connectedInboxType === "outlook_oauth" || false;

  const getOpenOrClickInfo = () => {
    const list = ["Open", "Click"];
    return list.map((item, idx) => {
      const isOpened = item?.toLowerCase() === "open";
      const svgIcon = isOpened ? "opened" : "clicked";
      const itemCount = isOpened ? viewCount : clickCount;

      return (
        itemCount > 0 && (
          <div
            className="openClickInfo"
            key={`${item}-${idx}`}
            onClick={e => {
              Utils.preventDefaultFn(e);
              eventType && handleAccordionClick(index);
            }}
          >
            <span
              className="roundedIcon"
              style={{
                backgroundColor: MAIL_STATUS_CONFIG[svgIcon]?.color || ""
              }}
            >
              {MAIL_STATUS_CONFIG[svgIcon]?.svg || ""}
            </span>
            <div className="openClickInfoText">{`${itemCount} ${
              itemCount === 1 ? item : `${item}s`
            }`}</div>
          </div>
        )
      );
    });
  };

  const redirectToContactActivity = (event = "") => {
    event && Utils.preventDefaultFn(event);
    contactId && history.push(`/contacts/${contactId}/activity`);
  };

  const getSyncedEmailContent = () => {
    const length = to?.length || 0;

    const getToAddressForReceivedEmails = () => {
      return to?.[0]?.name || to?.[0]?.email || "You";
    };

    const getToAddressForSentEmails = () => {
      return to?.length === 1
        ? to?.[0]?.name || to?.[0]?.email || "Someone"
        : to?.length > 1
        ? `${to?.[0]?.name || to?.[0]?.email} and ${length - 1} ${
            length - 1 > 1 ? "others " : "other "
          }`
        : "";
    };

    const isFromOutlook = isOutlookSequenceEmail || isOutlookEmail;

    return (
      <>
        <span
          className={`recipientName ${
            !contactId || (syncedEmail && !isFromOutlook) ? "noCursor" : ""
          }`}
          onClick={e => {
            !syncedEmail && isFromOutlook && redirectToContactActivity(e);
          }}
        >
          {updatedEventType === "received_email"
            ? getToAddressForReceivedEmails()
            : getToAddressForSentEmails()}
        </span>
        {["auto_reply", "received_email"].includes(updatedEventType)
          ? `received an email `
          : `${length - 1 > 1 ? "were" : "was"} sent an email `}
      </>
    );
  };

  const getHeadingContent = (to = "", from = "", updatedEventType = "") => {
    const getFromAddressIfAccordianEnabled = () => {
      return to?.length === 1 ? to[0]?.email : "Someone";
    };

    const getFromAddressIfAccordianDisabled = () => {
      return to?.[0]?.email || "Someone";
    };

    const getLinkedInMessage = () => {
      if (isFromLinkedIn) {
        let customLinkedInText = "";
        if (updatedEventType?.toLowerCase() === "li_message_sent") {
          customLinkedInText = " was sent a message on LinkedIn";
        } else if (
          updatedEventType?.toLowerCase() === "li_connection_req_sent"
        ) {
          customLinkedInText = " was sent a connection request on LinkedIn";
        } else if (
          updatedEventType?.toLowerCase() === "li_connection_req_accepted"
        ) {
          customLinkedInText = " accepted connection request on LinkedIn";
        } else if (replySource?.toLowerCase() === "linkedin") {
          customLinkedInText = " replied to a message on LinkedIn";
        }

        return (
          <>
            <span
              className={`recipientName ${contactId ? "" : "noCursor"}`}
              onClick={e => redirectToContactActivity(e)}
            >
              {firstName || lastName || email || "Someone"}
            </span>{" "}
            {customLinkedInText}{" "}
          </>
        );
      } else {
        return <></>;
      }
    };

    return isFromLinkedIn ? (
      getLinkedInMessage()
    ) : syncedEmail && !isOptoutContact && !isMeetingBooked ? (
      getSyncedEmailContent()
    ) : isMeetingBooked ? (
      <>
        <span
          className={`recipientName ${contactId ? "" : "noCursor"}`}
          onClick={e => redirectToContactActivity(e)}
        >
          {from?.email || "Someone"}
        </span>{" "}
        booked a meeting with{" "}
        <span
          className={`recipientName ${contactId ? "" : "noCursor"}`}
          onClick={e => redirectToContactActivity(e)}
        >
          {getFromAddressIfAccordianDisabled()}
        </span>{" "}
        on {DateUtils.getDateFullFormat(meetingBookedDate)} at{" "}
        {DateUtils.getTimeByMerdian(meetingBookedDate)}{" "}
        {meetingBookedTimeZone || ""}
      </>
    ) : (
      <>
        <span
          className={`recipientName ${contactId ? "" : "noCursor"}`}
          onClick={e => redirectToContactActivity(e)}
        >
          {enableAccordion
            ? getFromAddressIfAccordianEnabled()
            : getFromAddressIfAccordianDisabled()}
        </span>
        {isOptoutContact
          ? "has been opted out from all your sequences"
          : updatedEventType?.toLowerCase() === "auto_reply"
          ? "received an email"
          : updatedEventType === "sent"
          ? "was sent"
          : updatedEventType}
      </>
    );
  };

  const mapInboxLink = () => {
    if (finalThreadId && connectedInboxType === "gmail_oath") {
      let gmailUrl = `https://mail.google.com/mail/u/<<GMAIL_ID>>/#inbox/${finalThreadId}`;
      if (from && gmailMapper?.gmailListMapper?.length) {
        const { gmailListMapper } = gmailMapper;
        gmailListMapper.map(obj => {
          const { key, value } = obj || {};
          if (
            key?.toLowerCase() === from?.toLowerCase() &&
            typeof value != undefined
          ) {
            gmailUrl = gmailUrl.replace("<<GMAIL_ID>>", value);
          }
        });
      }
      if (gmailUrl.indexOf("<<GMAIL_ID>>") !== -1) {
        gmailUrl = `https://mail.google.com/mail/u/0/#inbox/${finalThreadId}`;
      }
      return gmailUrl;
    }
  };

  const mapSubjectLink = () => {
    if (finalThreadId && connectedInboxType === "gmail_oath") {
      let gmailUrl = `https://mail.google.com/mail/u/<<GMAIL_ID>>/#sent/${finalThreadId}`;
      if (from && gmailMapper?.gmailListMapper?.length) {
        const { gmailListMapper } = gmailMapper;
        gmailListMapper.map(obj => {
          const { key, value } = obj || {};
          if (
            key?.toLowerCase() === from?.toLowerCase() &&
            typeof value != undefined
          ) {
            gmailUrl = gmailUrl.replace("<<GMAIL_ID>>", value);
          }
        });
      }
      if (gmailUrl.indexOf("<<GMAIL_ID>>") !== -1) {
        gmailUrl = `https://mail.google.com/mail/u/0/#sent/${finalThreadId}`;
      }
      return gmailUrl;
    }
  };

  const getFromAddressOrName = () => {
    return from?.name || from?.email || "Someone";
  };

  const getSubjectLink = () => {
    let subjectText =
      (subject?.length > 40 && `${subject.substring(0, 40)}...`) ||
      subject ||
      "(No Subject)";
    const autoReplyContent = Utils.convertDomToPlainText(replyContent);
    let autoReplyText =
      (autoReplyContent?.length > 40 &&
        `${autoReplyContent.substring(0, 40)}...`) ||
      autoReplyContent;
    return (
      <>
        {updatedEventType?.toLowerCase() === "auto_reply" ? (
          <span className="replyContent">{autoReplyText}</span>
        ) : isOutlookSequenceEmail || isOutlookEmail ? (
          <span className={`sequenceSubject ${!subject ? "noSubject" : ""}`}>
            {subjectText}
          </span>
        ) : (
          <>
            <a
              className={`subject ${
                !subject && finalThreadId ? "noSubject" : ""
              }`}
              href={
                updatedEventType === "received_email"
                  ? mapInboxLink()
                  : mapSubjectLink()
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              {subjectText}
            </a>
            {(updatedEventType === "received_email" ||
              (updatedEventType === "sent" && syncedEmail)) && (
              <>
                <span className="fromText">from</span>
                <span
                  className={`recipientName ${contactId ? "" : "noCursor"}`}
                  onClick={e => redirectToContactActivity(e)}
                >
                  {getFromAddressOrName()}
                </span>
              </>
            )}
          </>
        )}
      </>
    );
  };

  const timeDifference = date => {
    const seconds =
      Math.floor((Date.now() - new Date(date).getTime()) / 1000) || 0;
    if (typeof seconds !== undefined) {
      const interval = intervals.find(i => i.seconds < seconds);
      if (interval?.label) {
        const count = Math.floor(seconds / interval?.seconds);
        return interval?.label === "Just Now"
          ? "Just now"
          : `${count} ${interval?.label}${count !== 1 ? "s" : ""} ago`;
      }
    }
    return "";
  };

  let allowEmailDataPreview =
    (updatedEventType &&
      [
        "replied",
        "reply",
        "click",
        "clicked",
        "views",
        "opened",
        "un_open",
        "sent",
        "received_email",
        "auto_reply"
      ].includes(updatedEventType?.toLowerCase())) ||
    false;

  const handlePreview = async (event = "") => {
    event && Utils.preventDefaultFn(event);
    if (allowEmailDataPreview) {
      emailPreviewContentPopupState.setShowPopup(true);
      emailPreviewContentPopupState.setEditorLoading(true);
      let res = await LiveFeedState.getPreviewEmailData(id);
      if (
        res?.message?.message?.toLowerCase() === "cancel" ||
        res?.message?.toLowerCase() === "cancel"
      ) {
        console.error("Getting preview email data cancel");
      } else if (
        res?.data &&
        Object.keys(res?.data)?.length > 0 &&
        +res?.status === 200
      ) {
        let obj = res?.data || {};
        let tempObj = { ...obj, pageType: "liveFeed" };
        const { replyContent = "", emailData = {} } = obj || {};

        const {
          from = {},
          to = {},
          cc = {},
          repliedCcAddress = [],
          repliedToAddress = [],
          repliedFromAddress = []
        } = emailData || {};
        if (obj?.eventType?.toLowerCase() === "replied" || replyContent) {
          let tempEmailData = {
            ...emailData,
            from: repliedFromAddress ? { email: repliedFromAddress } : from,
            to:
              Array.isArray(repliedToAddress) && repliedToAddress.length > 0
                ? InboxUtils.getEmailAddressList(repliedToAddress)
                : to,
            cc:
              Array.isArray(repliedCcAddress) && repliedCcAddress.length > 0
                ? InboxUtils.getEmailAddressList(repliedCcAddress)
                : cc,
            content: replyContent
          };
          tempObj = {
            ...tempObj,
            emailData: tempEmailData
          };
        }
        emailPreviewContentPopupState.setEditorLoading(false);
        emailPreviewContentPopupState.setShowReplyContent(
          obj?.eventType?.toLowerCase() === "replied"
        );
        emailPreviewContentPopupState.setPopupValues({
          ...tempObj,
          currentUserEmail: userDetail?.userInfo?.email,
          isEnableReplyFrwd: obj?.eventType?.toLowerCase() === "replied",
          showViewReplyBtn: false,
          hidePopupAnimate: true
        });
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later.",
          "fail"
        );
      }
    }
  };

  const getAccordionRightPanel = () => {
    return (
      <div className="accordionRightPanel">
        <span className="timeNoted">{timeDifference(updateTs)}</span>
        {enableAccordion && eventType && (
          <i
            className="material-icons dropdownIconArrow accordionArrow"
            onClick={e => {
              Utils.preventDefaultFn(e);
              handleAccordionClick(index);
            }}
          >
            {currentAccordion.id === index && currentAccordion.accordionOpen
              ? "keyboard_arrow_up"
              : "keyboard_arrow_down"}
          </i>
        )}
      </div>
    );
  };

  const getSequenceDetails = () => {
    return (
      (sequenceStageNumber || sequenceName || sequenceStageType) && (
        <div className="sequenceDetailedInfo">
          {sequenceStageNumber > 0 && (
            <span className="chip sequenceStageNo">{`Step ${sequenceStageNumber}`}</span>
          )}
          {sequenceName && (
            <span className="chip sequenceName">{sequenceName}</span>
          )}
          {sequenceStageType && (
            <span className="chip sequenceStageType">
              {sequenceStageType?.toLowerCase() === "linkedinauto"
                ? "Auto LinkedIn"
                : sequenceStageType === "manualEmail"
                ? "Manual Email"
                : "Auto Email"}
            </span>
          )}
        </div>
      )
    );
  };

  return (
    <div className="accordionSection">
      <div
        className="leftPanel"
        style={{
          backgroundColor: MAIL_STATUS_CONFIG[updatedEventType]?.color
        }}
      >
        {MAIL_STATUS_CONFIG[updatedEventType]?.svg}
        <div className="verticalLine" />
      </div>
      <div className="rightPanel">
        <div
          className={`wrapRightPanel ${
            allowEmailDataPreview || isSendMsg ? "cursorPointer" : ""
          }`}
          onClick={e =>
            isSendMsg ? handleLinkedInPreview(e) : handlePreview(e)
          }
        >
          <div className="accordionWithArrow">
            <div className="accorWrap">
              {["auto_reply"].includes(updatedEventType) &&
                ContactUtils.getAutoReplyDom(autoReplyType)}
              <div className="accordionInfo">
                {getHeadingContent(to, from, updatedEventType)}
                {!isOptoutContact &&
                  !isMeetingBooked &&
                  !isFromLinkedIn &&
                  getSubjectLink()}
              </div>
            </div>
            {getAccordionRightPanel()}
          </div>
          {enableAccordion && (
            <div className="additionalInfoWrapper">
              <div className="additionalInfoSection">
                {getOpenOrClickInfo()}
              </div>
            </div>
          )}
          {enableAccordion && <AccordionContent />}
        </div>
        {getSequenceDetails() || ""}
      </div>
    </div>
  );
};

export { HeaderSection };
export default HeaderSection;
