import React from "react";
import { observable, action } from "mobx";
import { withRouter, useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import { toasterState } from "Components/common/toaster";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import userDetail from "Stores/userDetail";
import { outboxStoreData } from "Pages/Dashboard/outbox/outboxStore";
import { fileAttachmentStore } from "Stores/FileAttachmentStore";
import EmailComposeEditor from "./EmailComposeEditor";
import EmailInsightsComponent from "Components/common/EmailInsights";
import Utils from "Utils/utils";
import { DateUtils } from "Utils/DateUtils";
import { TASK_EVENT_TYPE } from "Model/model";
import InboxUtils from "Utils/InboxUtils";
import { MXP_EVENT } from "Static/MixPanelEvents";
import { makeApi, URLS } from "Utils/apiURL";
import { ReactComponent as ReplySvg } from "Assets/svg/emailPreview/reply.svg";
import { ReactComponent as ReplyAllSvg } from "Assets/svg/emailPreview/replyAll.svg";
import { ReactComponent as ForwardSvg } from "Assets/svg/emailPreview/forward.svg";

export const defaultEditorInputValue = {
  emailType: "",
  sequenceId: "",
  openTrackingEnabled: false,
  clickTrackingEnabled: false,
  emailData: {
    from: "",
    to: [],
    cc: [],
    bcc: [],
    subject: "",
    attachments: [],
    content: ""
  },
  localState: {
    isTemplateInserted: false,
    templateName: "",
    showCcInputAddress: false,
    showBccInputAddress: false,
    cursorPosition: 0,
    editorInsertCounter: 0
  },
  isOutboxPage: false,
  hidePopupAnimate: false,
  isFromGlobalHeader: false
};

const emailTypeObj = {
  reply: "REPLY",
  replyAll: "REPLY_ALL",
  forward: "FORWARD"
};

class EmailPreviewContentPopupState {
  @observable showPopup = false;
  @observable editorLoading = false;
  @observable popupValues = "";
  @observable showEditorComposePopup = false;
  @observable editorInputValue = { ...defaultEditorInputValue };
  @observable showReplyContent = false;
  @observable scheduleLaterDateTime = "";
  @observable isFromTimeline = false;

  @action
  setShowPopup = (value = false) => {
    this.showPopup = value;
  };

  @action
  setIsFromTimeline = (value = false) => {
    this.isFromTimeline = value;
  };

  @action
  setPopupValues = (obj = {}) => {
    this.popupValues = obj;
  };

  @action
  setshowEditorComposePopup = (value = false) => {
    this.showEditorComposePopup = value;
  };

  @action
  setEditorInputValue = (obj = {}) => {
    this.editorInputValue = obj;
  };

  @action
  setEditorLoading = (value = false) => {
    this.editorLoading = value;
  };

  @action
  setShowReplyContent = (value = false) => {
    this.showReplyContent = value;
  };

  @action
  setScheduleLaterDateTime = (val = "") => {
    this.scheduleLaterDateTime = val;
  };

  @action
  setResetPopup = () => {
    this.showEditorComposePopup = false;
    this.editorInputValue = { ...defaultEditorInputValue };
    this.showPopup = false;
    this.popupValues = "";
    this.editorLoading = false;
    this.showEditorComposePopup = false;
    this.showReplyContent = false;
    this.scheduleLaterDateTime = "";
  };
}

const emailPreviewContentPopupState = new EmailPreviewContentPopupState();

const EmailPreviewContentPopup = observer(() => {
  const {
    emailData = {},
    taskType = "",
    group = "",
    eventType = "",
    activityId = "",
    hidePopupAnimate = false,
    isFromGlobalHeader = false,
    isEnableReplyFrwd = false,
    taskId = ""
  } = emailPreviewContentPopupState.popupValues || {};
  let history = useHistory();

  const handleErrorStatusCode = (statusCode = "", errorInbox = "") => {
    const codeTextMsg = {
      407: errorInbox?.length
        ? `Oops! Looks like the inbox ${errorInbox} is not connected. Please connect this inbox to send the email.`
        : "Please connect an inbox to send emails.",
      401: "Oops! You don't have access to send emails to this contact.",
      408: "Oops! you cannot reply or forward emails from an inbox connected via SMTP.",
      410: "Oops! you cannot send one-off emails from an inbox connected via SMTP."
    };
    let msg = codeTextMsg?.[statusCode] || "";
    toasterState.setToastMsg(
      msg || "Something went wrong. Please try again later",
      "fail"
    );
    emailPreviewContentPopupState.setEditorLoading(false);
  };

  const showConnectInboxPopup = (email = "") => {
    Utils.showAddAccountPopup(
      { history },
      `Please connect ${email || "an inbox"} before sending emails`,
      () => emailPreviewContentPopupState.setResetPopup()
    );
  };

  const getReplyAndForwardDetails = async (id = "") => {
    if (activityId || (isEnableReplyFrwd && taskId)) {
      if (Utils.isAccountConnected()) {
        emailPreviewContentPopupState.setEditorLoading(true);
        const url = isEnableReplyFrwd
          ? URLS.getReplyEmailContent
          : URLS.getReplyAndForwardDetails;
        const config = {
          url,
          method: "POST",
          data: isEnableReplyFrwd
            ? {
                taskId
              }
            : { activityId }
        };
        let res = await makeApi(config);
        if (res?.data?.statusCode === 200) {
          Utils.mixpanelTrack(
            id === "forward"
              ? MXP_EVENT.REPLIED_FROM_TIMELINE
              : MXP_EVENT.FORWARDED_FROM_TIMELINE,
            {
              pageType: "Contacts page"
            }
          );
          handleReplyForwardResponseData(id, res?.data);
        } else {
          handleErrorStatusCode(res?.data?.statusCode, res?.data?.errorInbox);
        }
      } else {
        showConnectInboxPopup(emailData.from?.email || emailData.from);
        return;
      }
    } else {
      toasterState.setToastMsg(
        "Something went wrong. Please try again later.",
        "fail"
      );
    }
  };

  const userHasAccessToEmail = (fromEmail = "") => {
    if (fromEmail) {
      if (userDetail?.connectedInboxes?.length) {
        let tempList = userDetail?.connectedInboxes.map(item => {
          if (
            item?.email === fromEmail &&
            userDetail?.userInfo?.memberId === item?.memberId
          ) {
            return item;
          }
        });
        if (!tempList?.length) {
          toasterState.setToastMsg(
            `Oops! You cannot send emails using ${fromEmail}`,
            "fail"
          );
          return false;
        }
        return true;
      }
    }
    Utils.showAddAccountPopup(
      { history },
      `Please connect ${
        emailData.from?.email || emailData.from || "an inbox"
      } before sending emails`,
      () => emailPreviewContentPopupState.setResetPopup()
    );
    return;
  };

  const handleReplyForwardResponseData = async (id = "", data = {}) => {
    if (id && data && Object.keys(data)?.length > 0) {
      const {
        ccEmail = [],
        bccEmail = [],
        forwardContent = "",
        forwardSubject = "",
        fromEmail = "",
        replyContent = "",
        replySubject = "",
        toEmail = [],
        threadId = ""
      } = data || {};
      if (userHasAccessToEmail(fromEmail?.email || fromEmail)) {
        let isSmtpEmailAccount = InboxUtils.smtpConnectedAccount(
          fromEmail?.email || fromEmail
        );
        if (!isSmtpEmailAccount) {
          let newContent = Utils.addEmptySpaceAboveThreadEmail(
            emailTypeObj[id] === "FORWARD" ? forwardContent : replyContent
          );
          let emailStatusObj = await Utils.validateEmailAddressStatus(
            Utils.getEmailList({
              to: toEmail,
              cc: ccEmail,
              bcc: bccEmail
            })
          );
          if (typeof emailStatusObj === "object") {
            let validatedEmailStatusObj = Utils.addEmailStatus(
              {
                to: toEmail || [],
                cc: ccEmail || [],
                bcc: bccEmail || []
              },
              emailStatusObj
            );
            let emailDataObj = {
              ...emailPreviewContentPopupState.popupValues.emailData,
              from: fromEmail,
              to: validatedEmailStatusObj["to"],
              cc:
                emailTypeObj[id] === "REPLY"
                  ? []
                  : validatedEmailStatusObj["cc"],
              bcc:
                emailTypeObj[id] === "REPLY"
                  ? []
                  : validatedEmailStatusObj["bcc"],

              threadId,
              subject:
                emailTypeObj[id] === "FORWARD" ? forwardSubject : replySubject,
              content: Utils.splitAndJoinDomWithoutSpace(newContent)
            };
            let localStateObj = {
              ...emailPreviewContentPopupState.editorInputValue.localState,
              showCcInputAddress:
                emailTypeObj[id] === "REPLY" ? false : ccEmail?.length > 0,
              showBccInputAddress:
                emailTypeObj[id] === "REPLY" ? false : bccEmail?.length > 0,
              editorInsertCounter: 1
            };
            let tempObj = {
              emailType: emailTypeObj[id],
              ...emailPreviewContentPopupState.popupValues,
              ...{ emailData: emailDataObj },
              ...{ localState: localStateObj },
              ...{ replyForwardData: data }
            };
            emailPreviewContentPopupState.setEditorInputValue(tempObj);
            emailPreviewContentPopupState.setEditorLoading(false);
            emailPreviewContentPopupState.setshowEditorComposePopup(true);
          } else {
            toasterState.setToastMsg(
              "Something went wrong. Please try again later",
              "fail"
            );
          }
        } else {
          handleErrorStatusCode(408, null);
        }
      } else {
        confirmationPopupState.setPopupValues({
          content: `Please connect ${
            fromEmail?.email || fromEmail
          } before sending emails`,
          actionBtnText: "Ok",
          callback: () => {
            confirmationPopupState.setShowPopup(false);
            history && history.push("/settings/account");
          }
        });
        confirmationPopupState.setShowPopup(true);
      }
    }
    emailPreviewContentPopupState.setEditorLoading(false);
  };

  const handleCloseAction = () => {
    fileAttachmentStore.clearAttachmentList();
    emailPreviewContentPopupState.setResetPopup();
    outboxStoreData.setActiveDataInPopup("");
    outboxStoreData.setShowOutboxReplyContent(false);
    outboxStoreData.setOutboxPopupContent("");
  };

  return emailPreviewContentPopupState.showPopup ? (
    <div className="emailPreviewContentPopup">
      <div
        className={`card ${!hidePopupAnimate ? "animated customzoomIn" : ""}`}
      >
        {!emailPreviewContentPopupState?.editorLoading && (
          <div
            className={`cardTitle ${
              eventType === "TASK_COMPLETED" ? "showStatus" : ""
            }`}
          >
            <PreviewSubject />
            {(!emailPreviewContentPopupState?.showEditorComposePopup ||
              hidePopupAnimate ||
              isFromGlobalHeader) && (
              <i className="material-icons close" onClick={handleCloseAction}>
                close
              </i>
            )}
          </div>
        )}
        <div
          className={`cardBody ${
            emailPreviewContentPopupState.showEditorComposePopup
              ? "editorBody"
              : ""
          }`}
        >
          {emailPreviewContentPopupState?.editorLoading ? (
            <div className="emailContentWrapper composeEditorLoader">
              <div className="emailContentSpinner" />
            </div>
          ) : emailPreviewContentPopupState.showEditorComposePopup ? (
            <EmailComposeEditor {...emailPreviewContentPopupState.showPopup} />
          ) : (
            <>
              {["linkedin"].includes(taskType?.toLowerCase()) ? (
                <LinkedInDetails />
              ) : ["customtask"].includes(taskType?.toLowerCase()) ? (
                <></>
              ) : ["phone"].includes(taskType?.toLowerCase()) ||
                group?.toLowerCase() === "phone" ? (
                <PhoneDetails />
              ) : (
                <EmailDetails
                  getReplyAndForwardDetails={getReplyAndForwardDetails}
                />
              )}
              <PreviewContent />
            </>
          )}
        </div>
        {!emailPreviewContentPopupState.showEditorComposePopup &&
          !emailPreviewContentPopupState?.editorLoading && <PreviewFooter />}
      </div>
      <div className="bgOverlay" />
    </div>
  ) : (
    ""
  );
});

const PreviewSubject = observer(() => {
  const {
    eventType = "",
    group = "",
    taskType = "",
    emailData = {},
    isOutboxPage = false,
    emailInsights = "",
    hideViewReplyBack = false,
    pageType = ""
  } = emailPreviewContentPopupState.popupValues || {};

  let isTask =
    ["task", "phone"].includes(group?.toLowerCase()) ||
    ["linkedin", "customtask", "phone"].includes(taskType?.toLowerCase());
  let isManualEmailTask = taskType?.toLowerCase() === "manualemail";

  return (
    <>
      <div className="previewSubject">
        {emailPreviewContentPopupState.showEditorComposePopup ? (
          <span className="label">
            {isOutboxPage ? "Update Email" : "New Email"}
          </span>
        ) : (
          <>
            {!hideViewReplyBack &&
              emailPreviewContentPopupState.showReplyContent && (
                <i
                  className="material-icons backBtn"
                  onClick={() =>
                    emailPreviewContentPopupState.setShowReplyContent(false)
                  }
                >
                  keyboard_backspace
                </i>
              )}
            <div className="subjectAndEmailInsight">
              <span
                className={`labelAndSubject ${
                  pageType &&
                  ["outboxreply", "sequencereply"].includes(
                    pageType?.toLowerCase()
                  )
                    ? "reducedWidth"
                    : ""
                }`}
              >
                {(!isTask || isManualEmailTask) && (
                  <span className="label">Subject:</span>
                )}
                <span
                  className={`subject ${
                    !isTask || isManualEmailTask ? "underlineBlue" : "taskTitle"
                  }`}
                >
                  {isTask && !isManualEmailTask
                    ? `${
                        TASK_EVENT_TYPE[
                          taskType?.toLowerCase() || group?.toLowerCase()
                        ]
                      } Task`
                    : emailData?.subject || "--"}
                </span>
              </span>
              {emailInsights && Object.keys(emailInsights)?.length > 0 && (
                <span className="emailInsight">
                  <EmailInsightsComponent emailInsight={emailInsights} />
                </span>
              )}
            </div>
          </>
        )}
      </div>
      {eventType === "TASK_COMPLETED" && (
        <div className="completedStatus">
          <i className="material-icons">check</i>
          <span className="text">Completed</span>
        </div>
      )}
    </>
  );
});

const EmailDetails = observer((props = {}) => {
  const { getReplyAndForwardDetails = () => {} } = props || {};
  const {
    popupValues = {},
    isFromTimeline = false,
    showReplyContent = false,
    setShowReplyContent = () => {}
  } = emailPreviewContentPopupState || {};
  const {
    eventType = "",
    emailData = {},
    activityOwner = {},
    currentUserEmail = "",
    createTs = "",
    contactEmail = "",
    sequenceId = "",
    timelineEmail = false,
    oneOffEmail = false,
    showViewReplyBtn = true,
    isEnableReplyFrwd = false
  } = popupValues || {};

  const { from = "", to = [], cc = [], bcc = [] } = emailData || {};

  const fromAddress =
    from?.email?.trim()?.replace(/['"]+/g, "") ||
    activityOwner?.email ||
    currentUserEmail ||
    "You";

  const toAddress = to?.length
    ? to.filter(item => item?.email?.trim()?.replace(/['"]+/g, ""))
    : [{ email: contactEmail }];

  const actionButton = [
    {
      id: "viewReply",
      name: "View Reply",
      show: !showReplyContent && eventType === "REPLIED" && showViewReplyBtn
    },
    {
      id: "reply",
      name: "Reply",
      svg: <ReplySvg />,
      show: true
    },
    {
      id: "replyAll",
      name: "Reply All",
      svg: <ReplyAllSvg />,
      show: true
    },
    {
      id: "forward",
      name: "Forward",
      svg: <ForwardSvg />,
      show: true
    }
  ];

  let fromEmail = showReplyContent ? fromAddress : toAddress?.[0]?.email;
  let toEmail = showReplyContent ? toAddress : [{ email: fromAddress }];

  if (isFromTimeline) {
    fromEmail = fromAddress;
    toEmail = toAddress;
    emailPreviewContentPopupState.setIsFromTimeline(false);
  }

  const handleAction = (id = "") => {
    if (id?.toLowerCase() !== "viewreply") {
      showReplyContent && setShowReplyContent(false);
      getReplyAndForwardDetails(id);
    } else {
      setShowReplyContent(true);
    }
  };

  const addressList = [
    {
      id: "cc",
      list: cc
    },
    {
      id: "bcc",
      list: bcc
    }
  ];
  return (
    <>
      <div className="fromEmailDetails">
        <div className="fromAddress">
          <span className="labelName">From:</span>
          <a
            className="fromEmailText highlightBlue"
            href={`mailto:${fromEmail}`}
            target="_blank"
            rel="noreferrer noopener nofollow"
          >
            {fromEmail}
          </a>
        </div>
        <div className="eventDate">
          {`${Utils.formatDate(createTs)} at ${DateUtils.getTimeByMerdian(
            createTs
          )}`}
        </div>
      </div>
      <div className="toEmailDetails">
        <RecipientEmailList id="to" list={toEmail} />
        {(timelineEmail || oneOffEmail || sequenceId || isEnableReplyFrwd) &&
          ["SENT", "OPENED", "CLICKED", "REPLIED"].includes(eventType) && (
            <div className="emailActionListBtn">
              <ul className="actionList">
                {actionButton.map(item => {
                  const {
                    id = "",
                    name = "",
                    svg = "",
                    show = ""
                  } = item || {};
                  if (show)
                    return (
                      <li
                        data-key={id}
                        key={id}
                        className={id}
                        onClick={() => handleAction(id)}
                      >
                        {svg}
                        <span className={`name ${svg ? "icon" : ""}`}>
                          {name}
                        </span>
                      </li>
                    );
                })}
              </ul>
            </div>
          )}
      </div>
      {addressList.map(item => (
        <RecipientEmailList
          {...item}
          key={`${item.id}ContatcTimelineRecipient`}
        />
      ))}
    </>
  );
});

const RecipientEmailList = (props = {}) => {
  const { id = "", list = [], cc = "" } = props || {};
  return (
    (list?.length > 0 && Utils.arrayContainsValidValues(list) && (
      <div className={id?.toLowerCase() === "to" ? "" : "recipientDetails"}>
        <div className="toAddress">
          <span className="labelName">{Utils.firstLetterCapitialize(id)}:</span>
          <div className="toEmailText">
            {list.map(item => {
              const { email } = item || {};
              return (
                <a
                  className="chipEmail"
                  href={`mailto:${email}`}
                  target="_blank"
                  rel="noreferrer noopener nofollow"
                  key={email}
                >
                  {email}
                </a>
              );
            })}
          </div>
        </div>
      </div>
    )) ||
    ""
  );
};

const PhoneDetails = () => {
  const {
    fromNumber = "",
    toNumber = "",
    callOutcome = "",
    createTs = ""
  } = emailPreviewContentPopupState.popupValues || {};

  return (
    <div className="phoneDetails">
      <div className="dialerRecipient">
        {fromNumber && toNumber && (
          <>
            <div className="fromNumber">
              <span className="labelName">From:</span>
              <a
                className="fromNumberText highlightBlue"
                href={`tel:${fromNumber}`}
                target="_blank"
                rel="noreferrer noopener nofollow"
              >
                {fromNumber}
              </a>
            </div>
            <div className="toNumber">
              <span className="labelName">To:</span>
              <a
                className="toNumberText highlightBlue"
                href={`tel:${toNumber}`}
                target="_blank"
                rel="noreferrer noopener nofollow"
              >
                {toNumber}
              </a>
            </div>
          </>
        )}
        {callOutcome && <span className="eventTypeBadge">{callOutcome}</span>}
      </div>
      {createTs && (
        <div className="eventDate">
          {`${Utils.formatDate(createTs)} at ${DateUtils.getTimeByMerdian(
            createTs
          )}`}
        </div>
      )}
    </div>
  );
};

const LinkedInDetails = () => {
  const { linkedInTaskType = "" } =
    emailPreviewContentPopupState.popupValues || {};
  return (
    linkedInTaskType && (
      <div className="linkedinDetails">
        <span className="label">Task type:</span>
        <span className="text">
          {Utils.convertTaskTypeEnumToString(linkedInTaskType)}
        </span>
      </div>
    )
  );
};

const PreviewContent = observer(() => {
  const {
    taskType = "",
    group = "",
    eventType = "",
    emailData = {},
    createTs = "",
    taskNotes = "",
    callNotes = "",
    autoEmail = false,
    timelineEmail = false,
    oneOffEmail = false,
    replyContent = ""
  } = emailPreviewContentPopupState.popupValues || {};

  const { content = "" } = emailData || {};

  let isPhoneTask = taskType === "phone" || group?.toLowerCase() === "phone";
  let isCustomLinkedinTask = ["linkedin", "customtask"].includes(
    taskType?.toLowerCase()
  );

  let isEmailPreview =
    ["email"].includes(group?.toLowerCase()) ||
    ["received_email"].includes(eventType?.toLowerCase()) ||
    ["manualemail", "email"].includes(taskType?.toLowerCase());

  return (
    <>
      {isEmailPreview && (
        <div
          className="prevContent"
          dangerouslySetInnerHTML={{
            __html: Utils.contentReadyOnly(
              emailPreviewContentPopupState.showReplyContent
                ? replyContent
                : content
            )
          }}
        />
      )}
      {!autoEmail &&
        !timelineEmail &&
        !oneOffEmail &&
        eventType !== "RECEIVED_EMAIL" &&
        (taskNotes || callNotes) && (
          <div
            className={`seperateNotes ${
              isPhoneTask && taskNotes ? "taskPreview" : ""
            }`}
          >
            {taskNotes && (
              <div
                className={`prevNotesSection ${
                  isCustomLinkedinTask ? "taskBlock" : ""
                }`}
              >
                <div className="label">
                  <div className="labelTitle">Task notes:</div>
                  {isCustomLinkedinTask && (
                    <div className="eventDate">
                      {`${Utils.formatDate(
                        createTs
                      )} at ${DateUtils.getTimeByMerdian(createTs)}`}
                    </div>
                  )}
                </div>
                <div
                  className="prevNotes"
                  dangerouslySetInnerHTML={{ __html: taskNotes || "--" }}
                />
              </div>
            )}
            {isPhoneTask && (
              <div className={`prevNotesSection ${group?.toLowerCase()}`}>
                <div className="label">Call notes:</div>
                <div
                  className="prevNotes"
                  dangerouslySetInnerHTML={{ __html: callNotes }}
                />
              </div>
            )}
          </div>
        )}
    </>
  );
});

const PreviewFooter = withRouter(() => {
  let history = useHistory();

  const {
    taskType = "",
    group = "",
    sequenceId = "",
    folderId = "",
    sequenceShareType = "",
    email = "",
    sequenceName = "",
    order = "",
    currentUserEmail = "",
    autoEmail = ""
  } = emailPreviewContentPopupState.popupValues || {};

  let allowSequenceRedirection =
    currentUserEmail === email || sequenceShareType;

  let sequenceLink = `/sequences/${
    sequenceShareType === "SHARED" && currentUserEmail !== email
      ? "shared"
      : folderId || "all"
  }/${sequenceId}`;

  let type =
    group === "TASK"
      ? TASK_EVENT_TYPE[taskType?.toLowerCase()]
      : group?.toLowerCase() === "email" && autoEmail
      ? "Auto Email"
      : "Manual Email";

  const handleRedirection = () => {
    emailPreviewContentPopupState.setResetPopup();
    history.push(sequenceLink);
  };

  return (
    <div className="cardFooter">
      <div className="sequenceDetails">
        {order > 0 && (
          <span className="stepNumber">
            Step {order} - {type}
          </span>
        )}
        {allowSequenceRedirection && sequenceName && (
          <span className="sequenceLink" onClick={() => handleRedirection()}>
            {sequenceName}
          </span>
        )}
      </div>
    </div>
  );
});

export default EmailPreviewContentPopup;
export { EmailPreviewContentPopup, emailPreviewContentPopupState };
