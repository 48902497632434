import React, { useState, useEffect, useContext } from "react";
import Utils from "Utils/utils";
import { observer } from "mobx-react";
import userDetail from "Stores/userDetail";
import { tagsStoreData } from "Stores/TagsData";
import MultiSelectDropdown from "Pages/Dashboard/contacts/ContactFilter/MultiSelectDropdown";
import ContactFieldFilter from "./ContactFieldFilter";
import { settingsStoreData } from "Stores/settingsData";
import ContactSequenceFilter from "./ContactSequenceFilter";
import ContactDateFilter from "./ContactDateFilter";
import contactStoreData from "Stores/contactData";
import { hideIntercomChat } from "Utils/Scripts/intercom";
import { DateUtils } from "Utils/DateUtils";
import { ContactUtils } from "Utils/ContactUtils";
import customListState from "Pages/Dashboard/dataSearch/CustomList/state";
import ContactTeamFilter from "./ContactTeamFilter";
import ContactMetricsFilter from "./ContactMetricsFilter";
import { TeamDataFilterContext } from "../ContactModule";
import {
  contactMetricFilters,
  websiteFilters,
  linkedInFilters
} from "./FilterConstants";

export const ContactFilterByOption = observer(props => {
  const { hasTeamMember } = userDetail?.userInfo || {};

  const {
    filterArr = [],
    limit = 10,
    setActiveTab = () => {},
    searchTerm = "",
    filterLoadingFn = () => {},
    tableLoadingFn = () => {},
    setContactFilter = () => {},
    stageId = "",
    selectedFilter = true,
    setSelectedFilter = () => {},
    constructTeamDataInFilter = () => {}
  } = props || {};

  const {
    teamFilterDropDownData = [],
    setTeamFilterDropDownData = () => {},
    setTeamCount = () => {},
    teamCount = 0
  } = useContext(TeamDataFilterContext);

  const [data, setData] = useState(contactStoreData.contactFilterObject);
  const [activeFilter, setActiveFilter] = useState(false);
  const [sequenceLoader, setSequenceLoader] = useState(true);
  const [errMsg, setErrMsg] = useState("");
  const [teamData, setTeamData] = useState("");
  const [disableContactFieldAddBtn, setDisableContactFieldAddBtn] =
    useState(true);
  const [dataFromLS, setDataFromLS] = useState(false);

  const validateData = (data = []) => {
    let validData = false;
    const dataLength = data?.length;
    if (dataLength) {
      const { fieldName = "", value = "" } = data?.[dataLength - 1];
      validData = fieldName && value;
    }
    return validData;
  };

  const mergeTwoArrWithoutDuplicate = (firstArr = [], secondArr = []) => {
    if (firstArr?.length > 0) {
      firstArr.map(item => {
        const itemId = item?.id;
        if (secondArr?.includes(itemId)) {
          item.checked = true;
        }
        return item;
      });
    }
    return firstArr;
  };

  const handleFilterObject = (key = "", value = "", firstTime = false) => {
    if (dataFromLS) {
      let updatedValue = value;
      if (firstTime && ["tags", "owners"]?.includes(key)) {
        const keyValue = data?.[key] || [];
        if (keyValue?.length > 0) {
          const filteredValue = keyValue
            .map(item => {
              if (item?.checked) {
                return item?.id;
              }
            })
            .filter(Boolean);
          updatedValue = mergeTwoArrWithoutDuplicate(
            updatedValue,
            filteredValue,
            "id"
          );
        }
      }
      let tempObj = { ...data, [key]: updatedValue };
      contactStoreData.setContactFilterObject(tempObj);
      setData({ ...tempObj });
      if (key === "contactFields") {
        const validData = validateData(tempObj?.[key]);
        setDisableContactFieldAddBtn(!validData);
      }
    }
  };

  const handleActiveFilter = (value = "") => {
    contactStoreData.setResetTeam(false);
    setActiveFilter(value === activeFilter ? "" : value);
  };

  const resetOwnersList = (item = []) => {
    if (filterArr?.length && filterArr.includes("owners") && item?.length) {
      let tempData = [...item];
      tempData.map(item => (item.checked = false));
      userDetail.setUserInfo({
        ...userDetail.userInfo,
        hasTeamMember: tempData || false
      });
      return tempData;
    }
    return hasTeamMember;
  };

  const resetTagsList = (item = []) => {
    if (filterArr?.length && filterArr.includes("tags") && item?.length) {
      let tempData = [...item];
      tempData.map(item => (item.checked = false));
      tagsStoreData.setTagsData(tempData);
      return tempData;
    }
    return tagsStoreData.tagsData;
  };

  const resetAll = () => {
    setActiveFilter("");
    let tags = resetTagsList(data?.tags);
    let owners = resetOwnersList(data?.owners);
    const defaultFilterObject = {
      contactFields: [
        {
          fieldName: "",
          operator: "EQUALS",
          value: ""
        }
      ],
      metrics: [
        {
          fieldName: "",
          operator: "EQUALS",
          value: ""
        }
      ],
      dates: [
        {
          fieldName: "",
          isSet: false,
          isNotSet: false,
          fromDate: new Date(),
          toDate: null,
          operator: "BETWEEN"
        }
      ],
      sequences: [
        {
          fieldName: "",
          sequenceId: "",
          sequenceName: ""
        }
      ]
    };
    let tempObj = { ...defaultFilterObject, ...{ tags, owners } };
    setData(tempObj);
    contactStoreData.setContactFilterObject(tempObj);
    const payload = {
      filter: {},
      stageId: null,
      searchTerm: "",
      pageNo: 1,
      listId: "",
      limit
    };
    contactStoreData.setFilterPayload(payload);
  };

  const selectedFilterAction = (type = "", value = "") => {
    contactStoreData.setResetTeam(false);
    let tempArr = [...filterArr];
    if (type === "remove" && tempArr?.length) {
      tempArr = tempArr.filter(item => item !== value);
    } else if (type === "add") {
      !tempArr.includes(value) && tempArr.push(value);
    }
    contactStoreData.setFilteredList(tempArr);
  };

  const cancelFilter = () => {
    clearAllFilter();
    setContactFilter(false);
  };

  const validateSelected = (errMsg = true) => {
    let count = 0;
    const { dates = [], sequences = [], contactFields = [] } = data || {};
    if (contactFields?.length) {
      for (const obj of contactFields) {
        const { fieldName = "", operator = "EQUALS", value = "" } = obj || {};
        if (fieldName && operator && !value) {
          setActiveFilter("contactFields");
          setErrMsg("contactFields");
          count++;
          break;
        }
      }
      if (count > 0) {
        return false;
      }
    }
    if (dates?.length) {
      for (const obj of dates) {
        const {
          fieldName = "",
          operator = "BETWEEN",
          fromDate = "",
          toDate = ""
        } = obj || {};
        if (
          fieldName &&
          operator === "BETWEEN" &&
          (!fromDate || !toDate) &&
          errMsg
        ) {
          setActiveFilter("dates");
          setErrMsg("dates");
          count++;
          break;
        }
      }
      if (count > 0) {
        return false;
      }
    }
    if (sequences?.length) {
      for (const obj of sequences) {
        const { fieldName = "", sequenceId = "" } = obj || {};
        if (fieldName && !sequenceId && errMsg) {
          setActiveFilter("sequences");
          setErrMsg("sequences");
          count++;
          break;
        }
      }
      if (count > 0) {
        return false;
      }
    }
    errMsg && setErrMsg("");
    activeFilter && setActiveFilter("");
    return true;
  };

  const getValidateList = (
    updatedFilterArr = [],
    updatedData = {},
    paramKey = ""
  ) => {
    if (paramKey) {
      return (
        updatedFilterArr?.length &&
        updatedFilterArr.includes(paramKey) &&
        updatedData?.[paramKey]?.length > 0
      );
    }
  };

  const getTagIds = (updatedFilterArr = filterArr, updatedData = data) => {
    let tagIds = [];
    const validateKey = getValidateList(updatedFilterArr, updatedData, "tags");
    if (validateKey) {
      updatedData.tags.forEach(ele => {
        if (ele?.checked) {
          tagIds.push(ele.id);
        }
      });
    }
    return tagIds;
  };

  const getMemberIds = (updatedFilterArr = filterArr, updatedData = data) => {
    let memberIds = [];
    const validateKey = getValidateList(
      updatedFilterArr,
      updatedData,
      "owners"
    );
    if (validateKey) {
      updatedData.owners.forEach(ele => {
        if (ele?.checked && !memberIds?.includes(ele?.memberId)) {
          memberIds.push(ele.memberId);
        }
      });
    }
    return memberIds;
  };

  const getContactFields = (
    updatedFilterArr = filterArr,
    updatedData = data
  ) => {
    const validateKey = getValidateList(
      updatedFilterArr,
      updatedData,
      "contactFields"
    );
    if (validateKey) {
      return updatedData.contactFields.filter(
        item => item?.fieldName && item?.operator
      );
    }
    return [];
  };

  const mapObj = {
    hasvisitedwebsite: "websiteVisitedCount",
    profileviewscount: "profileViewsCount",
    linkedinconnectionrequest: {
      sent: "liCrSentCount",
      accepted: "liCrAcceptedCount"
    },
    linkedinmessage: {
      sent: "liMessageSentCount",
      replied: "liMessageReplyCount"
    }
  };

  const mapMetricsItem = item => {
    if (item?.fieldName && item?.operator) {
      return ["hasvisitedwebsite", "profileviewscount"].includes(
        item?.fieldName?.toLowerCase()
      )
        ? {
            ...item,
            value: 0,
            operator: Boolean(item?.value === "true")
              ? "GREATER_THAN"
              : "EQUALS",
            fieldName: mapObj[item?.fieldName?.toLowerCase()]
          }
        : ["linkedinconnectionrequest", "linkedinmessage"].includes(
            item?.fieldName?.toLowerCase()
          )
        ? {
            ...item,
            value: 0,
            operator: "GREATER_THAN",
            fieldName:
              mapObj[item?.fieldName?.toLowerCase()][item.value?.toLowerCase()]
          }
        : item;
    }
  };

  const getMetrics = (updatedFilterArr = filterArr, updatedData = data) => {
    const validateKey = getValidateList(
      updatedFilterArr,
      updatedData,
      "metrics"
    );
    if (validateKey) {
      return updatedData.metrics
        .filter(item => item?.fieldName && item?.operator)
        .map(mapMetricsItem);
    }
    return [];
  };

  const getDates = (updatedFilterArr = filterArr, updatedData = data) => {
    const validateKey = getValidateList(updatedFilterArr, updatedData, "dates");
    if (validateKey) {
      return DateUtils.getDateFilterObj(data?.dates || []);
    }
    return "";
  };

  const getSequences = (updatedFilterArr = filterArr, updatedData = data) => {
    const validateKey = getValidateList(
      updatedFilterArr,
      updatedData,
      "sequences"
    );
    if (validateKey) {
      let sequenceObj = {};
      const fieldKey = {
        ACTIVE_IN_SEQUENCE: "activeSequences",
        COMPLETED_IN_SEQUENCE: "completedSequences",
        ADDED_TO_SEQUENCE: "addedSequences"
      };
      const operatorKey = {
        ACTIVE_IN_SEQUENCE: "activeSequencesOperator",
        COMPLETED_IN_SEQUENCE: "completedSequencesOperator",
        ADDED_TO_SEQUENCE: "addedSequencesOperator"
      };
      updatedData.sequences.forEach(item => {
        const {
          fieldName = "",
          sequenceId = "",
          operator = "EQUALS"
        } = item || {};
        if (fieldName && sequenceId) {
          sequenceObj[fieldKey[fieldName]] = [sequenceId];
          sequenceObj[operatorKey[fieldName]] = operator;
        }
      });
      return sequenceObj;
    }
    return "";
  };

  const applyFilterInit = async (
    updatedFilterArr = [],
    updatedData = {},
    selectedTeamMembers = teamData,
    canSave = true
  ) => {
    if (validateSelected(false)) {
      const payload = {
        filter: {
          tagIds: getTagIds(updatedFilterArr, updatedData),
          memberIds: getMemberIds(updatedFilterArr, updatedData)
        },
        stageId,
        searchTerm,
        listId: customListState?.activeList?.id || "",
        pageNo: 1,
        limit
      };
      let fields = getContactFields(updatedFilterArr, updatedData);
      if (fields?.length) {
        payload.filter = { ...payload.filter, fields };
      }
      let metrics = getMetrics(updatedFilterArr, updatedData);
      if (metrics?.length) {
        fields = fields?.concat(metrics);
        payload.filter = { ...payload.filter, fields };
      }
      let memberIdsFromTeam = [...selectedTeamMembers];
      if (memberIdsFromTeam?.length) {
        memberIdsFromTeam.forEach(item => {
          if (!payload?.filter?.memberIds?.includes(item)) {
            payload.filter.memberIds.push(item);
          }
        });
      }
      let dates = getDates(updatedFilterArr, updatedData);
      if (dates && Object.keys(dates)?.length) {
        payload.filter = { ...payload.filter, ...dates };
      }
      let sequences = getSequences(updatedFilterArr, updatedData);
      if (sequences && Object.keys(sequences)?.length) {
        payload.filter = { ...payload.filter, ...sequences };
      }
      ContactUtils.contactFilterByPayload(
        payload,
        tableLoadingFn,
        filterLoadingFn,
        true,
        canSave
      );
    }
  };

  const applyFilter = async () => {
    if (validateSelected()) {
      filterLoadingFn(true);
      tableLoadingFn(true);

      const payload = {
        filter: {
          tagIds: getTagIds(filterArr, data),
          memberIds: getMemberIds(filterArr, data)
        },
        stageId,
        searchTerm,
        listId: customListState?.activeList?.id || "",
        pageNo: 1,
        limit
      };
      let fields = getContactFields(filterArr, data);
      if (fields?.length) {
        payload.filter = { ...payload.filter, fields };
      }
      let metrics = getMetrics(filterArr, data);
      if (metrics?.length) {
        fields = fields?.concat(metrics);
        payload.filter = { ...payload.filter, fields };
      }
      let memberIdsFromTeam = [];
      if (teamData?.length > 0) {
        memberIdsFromTeam = [...teamData];
      }
      if (memberIdsFromTeam?.length > 0) {
        memberIdsFromTeam.forEach(item => {
          if (!payload?.filter?.memberIds?.includes(item)) {
            payload.filter.memberIds.push(item);
          }
        });
      }
      let dates = getDates(filterArr, data);
      if (dates && Object.keys(dates)?.length) {
        payload.filter = { ...payload.filter, ...dates };
      }
      let sequences = getSequences(filterArr, data);
      if (sequences && Object.keys(sequences)?.length) {
        payload.filter = { ...payload.filter, ...sequences };
      }
      ContactUtils.contactFilterByPayload(
        payload,
        tableLoadingFn,
        filterLoadingFn,
        true
      );
    }
  };

  const clearAllFilter = () => {
    setTeamData([]);
    contactStoreData.setResetTeam(true);
    if (filterArr?.length > 0) {
      resetAll();
      contactStoreData.setFilteredList([]);
      tableLoadingFn(true);
      filterLoadingFn(true);
      setActiveTab("total");
      const payload = {
        filter: {},
        stageId: null,
        searchTerm: "",
        listId: "",
        pageNo: 1,
        limit
      };
      ContactUtils.contactFilterByPayload(
        payload,
        () => {
          customListState.setActiveList("");
          tableLoadingFn(false);
        },
        filterLoadingFn,
        true
      );
    }
  };

  const actionList = [
    {
      className: "cancelFilter",
      method: cancelFilter,
      name: "Cancel",
      id: "cancelFilter"
    },
    {
      className: "applyFilter",
      method: applyFilter,
      name: "Apply Filter",
      id: "applyFilter"
    }
  ];

  const getSequenceList = (pageNo = "") => {
    const payload = {
      pageNo: pageNo || contactStoreData?.sequenceCurrentPageNo,
      limit: 500,
      filters: {
        memberIds: [userDetail?.userInfo?.memberId]
      }
    };
    contactStoreData.listSequences(payload);
  };

  const handleScrollEnd = event => {
    const {
      scrollHeight = 0,
      scrollTop = 0,
      clientHeight = 0
    } = event?.currentTarget;
    if (
      scrollHeight - scrollTop - 10 < clientHeight &&
      contactStoreData.sequenceCurrentPageNo <
        contactStoreData.sequenceTotalPages
    ) {
      const newPageNumber = contactStoreData.sequenceCurrentPageNo + 1;
      contactStoreData.setSequenceCurrentPageNo(newPageNumber);
      getSequenceList(newPageNumber);
    }
  };

  const getSelectedItems = (item = []) => {
    if (item?.length) {
      return item.filter(item => item?.checked) || [];
    }
    return [];
  };

  const getFilterDataFromLocalStorage = key => {
    const itemStr = localStorage.getItem(key);
    // if the item doesn't exist, return null
    if (!itemStr) {
      return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now?.getTime() > item?.expiry) {
      localStorage.removeItem(key);
    }
    return item?.value || {};
  };

  const getDefaultObj = () => {
    return {
      tags: tagsStoreData?.tagsData || [],
      owners: userDetail?.userInfo?.hasTeamMember || [],
      contactFields: [
        {
          fieldName: "",
          operator: "EQUALS",
          value: ""
        }
      ],
      metrics: [
        {
          fieldName: "",
          operator: "EQUALS",
          value: ""
        }
      ],
      dates: [
        {
          fieldName: ""
        }
      ],
      sequences: [
        {
          fieldName: "",
          sequenceId: "",
          sequenceName: ""
        }
      ]
    };
  };

  const constructFilterArr = updatedData => {
    let newArr = [];
    const defaultObj = getDefaultObj();
    let selectedTeamMembers = [];
    for (let item in updatedData) {
      if (item === "team") {
        let selectedTeam = [];
        const filteredItem =
          updatedData?.[item]?.length > 0 &&
          updatedData?.[item]
            .map(item => {
              return (
                item?.submenu?.length > 0 &&
                item.submenu.filter(submenuItem => {
                  if (submenuItem.checked) {
                    !selectedTeamMembers.includes(submenuItem?.id) &&
                      selectedTeamMembers.push(submenuItem?.id);
                    !selectedTeam.includes(item?.name) &&
                      selectedTeam.push(item.name);
                  }
                  return submenuItem.checked;
                })
              );
            })
            .filter(Boolean);
        if (filteredItem?.length > 0) {
          newArr.push(item);
          setTeamFilterDropDownData(updatedData?.[item]);
          setTeamCount(selectedTeam?.length);
          setTeamData(selectedTeamMembers);
        }
      } else if (["tags", "owners"]?.includes(item)) {
        const filteredItem =
          updatedData?.[item]?.length > 0 &&
          updatedData?.[item].filter(item => item.checked === true);
        if (filteredItem?.length > 0) {
          newArr.push(item);
        }
      } else if (item === "dates") {
        if (
          JSON.stringify(updatedData?.[item]?.[0]?.fieldName) !==
          JSON.stringify(defaultObj?.[item]?.[0]?.fieldName)
        ) {
          newArr.push(item);
        }
      } else if (
        JSON.stringify(updatedData?.[item]) !== JSON.stringify(defaultObj[item])
      ) {
        newArr.push(item);
      }
    }
    contactStoreData.setFilteredList(newArr);
    return { filterArr: newArr, selectedTeamMembers };
  };

  const getValueFromLocalStorage = async () => {
    const filterValue =
      getFilterDataFromLocalStorage("sgContactFilterData") || {};
    setDataFromLS(true);
    let tempData = { ...contactStoreData.contactFilterObject };
    const updatedData = { ...tempData, ...filterValue };
    setData({ ...updatedData });
    let { filterArr: updatedFilterArr, selectedTeamMembers } =
      await constructFilterArr(updatedData);
    contactStoreData.setFilteredList(updatedFilterArr);
    if (!updatedFilterArr?.length && selectedFilter === "") {
      setSelectedFilter(false);
    } else {
      applyFilterInit(
        updatedFilterArr,
        updatedData,
        selectedTeamMembers,
        false
      );
    }
  };

  const getInit = async () => {
    hideIntercomChat();
    settingsStoreData.getCustomFields();
    getSequenceList();
    getValueFromLocalStorage();
    let tempData = { ...contactStoreData.contactFilterObject };
    tempData.tags = [...(tagsStoreData?.tagsData || [])];
    tempData.owners = [...(hasTeamMember || [])];
    contactStoreData.setContactFilterObject(tempData);
  };

  const filterTeamDataBySelectedCbk = (value = [], selectedTeams = []) => {
    let tempObj = { ...data, ["team"]: teamFilterDropDownData };
    contactStoreData.setContactFilterObject(tempObj);
    if (value?.length > 0 || selectedTeams?.length > 0) {
      setTeamData(value);
      selectedFilterAction("add", "team");
    } else {
      setTeamData([]);
      selectedFilterAction("remove", "team");
    }
  };

  const getApiCalls = async () => {
    tagsStoreData.getAllTags();
    constructTeamDataInFilter();
    const resData = await Utils.userTeamList(true);
    userDetail.setUserInfo({
      ...userDetail.userInfo,
      hasTeamMember: resData?.length ? resData : false
    });
  };

  useEffect(() => {
    handleFilterObject("owners", userDetail.userInfo.hasTeamMember, true);
  }, [userDetail.userInfo.hasTeamMember]);

  useEffect(() => {
    handleFilterObject("tags", tagsStoreData.tagsData, true);
  }, [tagsStoreData.tagsData]);

  useEffect(() => {
    setSequenceLoader(false);
  }, [contactStoreData?.sequenceList]);

  useEffect(() => {
    getInit();
    getApiCalls();
    return () => {
      setActiveFilter("");
      setErrMsg("");
    };
  }, []);

  return (
    <div className="contactFilterByOption">
      <div className="filterHeader">
        <span className="name">Filter</span>
        <span
          className={`clearBtn ${
            (filterArr?.length === 0 && "disabled") || ""
          }`}
          onClick={clearAllFilter}
        >
          Clear all
        </span>
      </div>
      <div className="filterBody">
        <MultiSelectDropdown
          label="Tags"
          keyValue="tags"
          placeholder="Search by tags"
          selected={getSelectedItems(data?.tags)}
          data={data?.tags}
          setAction={handleFilterObject}
          activeFilter={activeFilter}
          setActiveFilter={handleActiveFilter}
          selectedFilterCbk={selectedFilterAction}
        />
        <MultiSelectDropdown
          label="Owners"
          keyValue="owners"
          placeholder="Search by owners"
          selected={getSelectedItems(data?.owners)}
          data={data?.owners}
          setAction={handleFilterObject}
          activeFilter={activeFilter}
          setActiveFilter={handleActiveFilter}
          selectedFilterCbk={selectedFilterAction}
          showEmptyText={true}
        />
        <ContactFieldFilter
          label="Contact Field"
          keyValue="contactFields"
          data={data?.contactFields}
          activeFilter={activeFilter}
          setActiveFilter={handleActiveFilter}
          setAction={handleFilterObject}
          selectedFilterCbk={selectedFilterAction}
          errMsg={errMsg}
          setErrMsg={setErrMsg}
          disableAddButton={disableContactFieldAddBtn}
        />
        <ContactMetricsFilter
          label="Metrics"
          keyValue="metrics"
          data={data?.metrics}
          activeFilter={activeFilter}
          setActiveFilter={handleActiveFilter}
          setAction={handleFilterObject}
          selectedFilterCbk={selectedFilterAction}
          errMsg={errMsg}
          setErrMsg={setErrMsg}
          pageType="contacts"
          customConditionFieldnameList={[
            ...contactMetricFilters,
            ...websiteFilters,
            ...linkedInFilters
          ]}
        />
        <ContactDateFilter
          label="Date"
          keyValue="dates"
          data={data?.dates}
          activeFilter={activeFilter}
          setActiveFilter={handleActiveFilter}
          setAction={handleFilterObject}
          selectedFilterCbk={selectedFilterAction}
          errMsg={errMsg}
          setErrMsg={setErrMsg}
        />
        <ContactSequenceFilter
          label="Sequence"
          keyValue="sequences"
          data={data?.sequences}
          sequenceLoader={sequenceLoader}
          sequenceList={contactStoreData?.sequenceList}
          activeFilter={activeFilter}
          setActiveFilter={handleActiveFilter}
          setAction={handleFilterObject}
          selectedFilterCbk={selectedFilterAction}
          handleScrollEnd={handleScrollEnd}
          errMsg={errMsg}
          setErrMsg={setErrMsg}
        />
        <ContactTeamFilter
          label="Team"
          keyValue="team"
          teamCount={teamCount}
          teamFilterDropDownData={teamFilterDropDownData}
          setTeamCount={setTeamCount}
          activeFilter={activeFilter}
          setActiveFilter={handleActiveFilter}
          setAction={handleFilterObject}
          selectedFilterCbk={selectedFilterAction}
          handleScrollEnd={handleScrollEnd}
          updateSelectedCbk={filterTeamDataBySelectedCbk}
        />
      </div>
      <div className="filterAction">
        {(actionList?.length > 0 &&
          actionList.map((item, index) => {
            const {
              className = "",
              name = "",
              id = "",
              method = () => {}
            } = item || {};
            return (
              <div
                className={className}
                onClick={method}
                key={`${id}Action${index}`}
              >
                {name}
              </div>
            );
          })) ||
          ""}
      </div>
    </div>
  );
});

export default ContactFilterByOption;
