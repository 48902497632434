import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { ImportNow } from "../common/ImportNow";
import AddTags from "../common/AddTags";
import AutoImport from "../common/AutoImport";
import UpdateContacts from "../common/UpdateContacts";
import { salesforceConnectedData } from "./SalesforceConnectedData";
import { AddContactWithCondition } from "./AddContactWithCondition";
import FieldMapping from "./FieldMapping";

const Leads = observer(props => {
  const { lead = {}, fieldMappingRef = null, id = "" } = props || {};
  const { resumeDate = "" } = salesforceConnectedData?.salesForceData || {};
  const [dropdownWidth, setDropdownWidth] = useState("25%");

  const findWidthOfDropdown = () => {
    return (
      document.querySelector(".crmListView .filterDropdownWrap")?.offsetWidth ||
      "270"
    );
  };

  useEffect(() => {
    setDropdownWidth(findWidthOfDropdown());
  }, []);

  return (
    <div className="leadsWrapper">
      <div className="autoImportSettings">
        <AddContactWithCondition
          config={salesforceConnectedData.sfLeadConfig}
          setConfig={salesforceConnectedData.setSfLeadConfig}
          fields={salesforceConnectedData?.sfLeadFields}
          crmName="Salesforce"
        />
        <AutoImport
          config={salesforceConnectedData.sfLeadConfig}
          setConfig={salesforceConnectedData.setSfLeadConfig}
          data={salesforceConnectedData?.salesForceData}
          crmName="Salesforce"
          hasConditions={true}
        />
        <AddTags
          type="LEADS"
          dropdownWidth={dropdownWidth}
          config={salesforceConnectedData?.sfLeadConfig}
          selectedTags={salesforceConnectedData?.sfLeadTags}
          setSelectedTags={salesforceConnectedData.setSfLeadTags}
        />
        <UpdateContacts
          config={salesforceConnectedData.sfLeadConfig}
          setConfig={salesforceConnectedData.setSfLeadConfig}
          crmName="Salesforce"
          hasConditions={true}
        />
        <ImportNow
          listDetails={salesforceConnectedData?.sfLeadConfig}
          type="LEAD"
          lastSynced={lead?.lastSynced}
          syncNow={lead?.syncNow}
          crmName="Salesforce"
          hasConditions={true}
          resumeDate={resumeDate}
          syncStartTime={lead?.syncStartTime}
          id={id}
        />
      </div>
      <div className="fieldMapping" ref={fieldMappingRef}>
        <FieldMapping
          config={salesforceConnectedData.sfLeadConfig}
          setConfig={salesforceConnectedData.setSfLeadConfig}
          mappingConfig={lead?.fields}
          type="LEADS"
          fields={salesforceConnectedData?.sfLeadFields}
          crmName="Salesforce"
        />
      </div>
    </div>
  );
});

export { Leads };
export default Leads;
