import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { MP_EVENT } from "Static/constant";
import Utils from "Utils/utils";
import OutboxUtils from "Utils/OutboxUtils";
import Button from "Components/common/Button";
import EmailInsightsComponent from "Components/common/EmailInsights";
import { outboxStoreData } from "./outboxStore";
import { DateUtils } from "Utils/DateUtils";
import InboxUtils from "Utils/InboxUtils";
import { EmailStatusButton } from "./CommonEmailContents";
import { emailPreviewContentPopupState } from "Components/PreviewPopup/EmailPreviewContentPopup";

const EmailPreviewContent = observer((props = {}) => {
  const { status = "", scheduleCode = "", emailId = "" } = props || {};
  const { isOutboxPage = false } =
    emailPreviewContentPopupState?.popupValues || {};

  const getPopupEmailContent = async (emailId = "") => {
    Utils.mixpanelTrack(MP_EVENT.OUTBOX_POPUP_VIEWED, {
      pageType: "Outbox",
      status: OutboxUtils.getEmailStatus(status, scheduleCode)
    });
    outboxStoreData.setPopupEmailContentLoader(true);
    await outboxStoreData.getOutboxPopupContent(emailId);
  };

  const getPreviewContent = () => {
    const { touchPoint = {}, replyContent = "" } =
      outboxStoreData?.outboxPopupContent || {};
    const { content = "", shortContent = "" } = touchPoint || {};
    let contentStr =
      status?.toLowerCase() === "auto_reply" ||
      outboxStoreData.showOutboxReplyContent
        ? replyContent
        : content || shortContent;
    return Utils.contentReadyOnly(contentStr);
  };

  useEffect(() => {
    if (!isOutboxPage) {
      getPopupEmailContent(emailId);
    }
  }, []);

  return outboxStoreData.popupEmailContentLoader ? (
    <div className="emailContentWrapper">
      <div className="emailContentSpinner" />
    </div>
  ) : (
    <p
      className="emailContent"
      dangerouslySetInnerHTML={{
        __html: getPreviewContent()
      }}
    />
  );
});

const OutboxCardPopup = observer(() => {
  const {
    contact = {},
    task = {},
    sequence = {},
    history = {}
  } = outboxStoreData?.activeDataInPopup || {};

  const { email: toEmailAddress = "" } = contact?.[0] || {};

  const { isOutboxPage = false } =
    emailPreviewContentPopupState?.popupValues || {};

  let {
    id: emailId,
    status = "",
    bounceCount = 0,
    taskDue = "",
    expectedScheduleDate = "",
    scheduleCode = "",
    replyContent = "",
    order = 1,
    sentCount = "",
    openCount = "",
    clickCount = "",
    replyCount = "",
    sentDate = "",
    taskType = "",
    timelineEmail = false,
    oneOffEmail = false,
    touchPoint = {},
    recipientStatus = {}
  } = task || {};
  const { name: sequenceName = "" } = sequence || {};
  const {
    ccAddress = [],
    bccAddress = [],
    toAddress = [],
    fromAddress = "",
    subject = ""
  } = touchPoint || {};

  const emailInsights = {
    sentCount,
    openCount,
    clickCount,
    replyCount
  };

  const getEmailArray = (arr = []) => {
    return (arr?.length > 0 && (typeof arr === "string" ? [arr] : arr)) || [];
  };

  let updatedFromAddress = !outboxStoreData.showOutboxReplyContent
    ? fromAddress
    : toEmailAddress;

  const handleClose = () => {
    outboxStoreData.setActiveDataInPopup("");
    outboxStoreData.setPopupEmailContentLoader(true);
    outboxStoreData.setShowOutboxReplyContent(false);
    if (isOutboxPage) {
      emailPreviewContentPopupState.setResetPopup();
    }
  };

  const redirectToSequence = event => {
    event.stopPropagation();
    outboxStoreData.setActiveDataInPopup("");
    history.push(OutboxUtils.getSequenceURL(sequence));
  };

  const addressList = [
    {
      id: "to",
      list: outboxStoreData.showOutboxReplyContent
        ? [fromAddress]
        : getEmailArray(toAddress),
      emailStr: typeof toAddress === "string"
    },
    {
      id: "cc",
      list: getEmailArray(ccAddress)
    },
    {
      id: "bcc",
      list: getEmailArray(bccAddress)
    }
  ];

  const handleEditAction = async () => {
    if (!outboxStoreData?.popupEmailContentLoader) {
      outboxStoreData.setOutboxCardPopup(false);
      outboxStoreData.setRelaodOutboxPage(false);
      let {
        content = "",
        attachments = [],
        bccAddress = [],
        ccAddress = []
      } = outboxStoreData?.outboxPopupContent?.touchPoint || {};
      let localStateObj = {
        ...emailPreviewContentPopupState.editorInputValue.localState,
        showCcInputAddress: ccAddress?.length > 0,
        showBccInputAddress: bccAddress?.length > 0
      };
      let tempObj = {
        emailType: "ONE_OFF_EMAIL",
        ...emailPreviewContentPopupState.popupValues,
        ...{
          emailData: {
            from: fromAddress,
            to: [{ email: toAddress }],
            content,
            subject,
            id: emailId,
            attachments,
            cc: InboxUtils.getListOfEmailAndName(ccAddress),
            bcc: InboxUtils.getListOfEmailAndName(bccAddress)
          }
        },
        ...{ localState: localStateObj }
      };
      emailPreviewContentPopupState.setPopupValues({
        ...emailPreviewContentPopupState.popupValues,
        isOutboxPage: true,
        hidePopupAnimate: true
      });
      emailPreviewContentPopupState.setEditorInputValue(tempObj);
      emailPreviewContentPopupState.setshowEditorComposePopup(true);
      emailPreviewContentPopupState.setShowPopup(true);
    }
  };

  const editButtonObj = {
    id: "editEmailBtn",
    name: "editEmailBtn",
    btnText: "Edit",
    type: "button",
    className: `editEmailBtn ${
      outboxStoreData.popupEmailContentLoader ? "disabled" : ""
    }`,
    click: handleEditAction
  };

  return (
    outboxStoreData?.activeDataInPopup !== "" &&
    outboxStoreData?.outboxCardPopup && (
      <div className="outboxCardPopupWrapper">
        <div
          className={`outboxCardPopupContainer ${
            !isOutboxPage ? "animated customzoomIn" : ""
          }`}
        >
          <div
            className={`outboxPopupHeader ${
              outboxStoreData.showOutboxReplyContent ? "replyHeader" : ""
            }`}
          >
            {outboxStoreData.showOutboxReplyContent && (
              <i
                className="material-icons backBtn"
                onClick={() => outboxStoreData.setShowOutboxReplyContent(false)}
              >
                keyboard_backspace
              </i>
            )}
            <div className="subjectBlock">
              <span className="labelName">Subject:</span>
              <span className="subjectText">{touchPoint?.subject}</span>
            </div>
            <span className="emailSequenceInsight">
              {emailInsights && Object.keys(emailInsights)?.length ? (
                <EmailInsightsComponent emailInsight={emailInsights} />
              ) : (
                "--"
              )}
            </span>
            <i className="material-icons closeLg" onClick={handleClose}>
              close
            </i>
          </div>
          <div className="outboxPopupContent">
            <div className="fromEmailDetails">
              <div className="fromAddress">
                <span className="labelName">From:</span>
                <a
                  className="fromEmailText highlightBlue"
                  href={`mailto:${updatedFromAddress}`}
                  target="_blank"
                  rel="noreferrer noopener nofollow"
                >
                  {updatedFromAddress}
                </a>
              </div>
              <div className="dateDetails">
                <span
                  className="eventDate"
                  style={{
                    color: outboxStoreData.showOutboxReplyContent
                      ? `#3953fb`
                      : "#8d8d8d"
                  }}
                >
                  {`${Utils.formatDate(
                    status?.toLowerCase() === "sent" ? sentDate : taskDue
                  )} at ${DateUtils.getTimeByMerdian(
                    status?.toLowerCase() === "sent" ? sentDate : taskDue
                  )}`}
                </span>
              </div>
            </div>
            <div className="toEmailDetails">
              <div className="wrapAddressList">
                {addressList?.map(item => (
                  <EmailAddressList
                    key={`${item?.id}AddressList`}
                    {...item}
                    contacts={contact}
                    recipientStatus={recipientStatus}
                    activeFilter={outboxStoreData?.activeFilter}
                  />
                ))}
              </div>
              <div className="emailStatusData">
                {replyCount > 0 &&
                  replyContent &&
                  outboxStoreData?.activeFilter?.id === "REPLY_COUNT" &&
                  !outboxStoreData.showOutboxReplyContent && (
                    <div
                      className="viewReply"
                      onClick={() =>
                        outboxStoreData.setShowOutboxReplyContent(true)
                      }
                    >
                      View Reply
                    </div>
                  )}
                <EmailStatusButton
                  status={
                    outboxStoreData?.activeFilter?.id === "BOUNCE_COUNT"
                      ? "bounced"
                      : status
                  }
                  scheduleCode={scheduleCode}
                  bounceCount={bounceCount}
                  taskDue={taskDue}
                  expectedScheduleDate={expectedScheduleDate}
                  {...outboxStoreData.activeDataInPopup}
                />
              </div>
            </div>
            <EmailPreviewContent
              status={status}
              scheduleCode={scheduleCode}
              emailId={emailId}
            />
          </div>
          {(taskType?.toLowerCase() !== "email" ||
            status?.toLowerCase() === "sent") &&
            !timelineEmail && (
              <div className="outboxPopupFooter">
                {oneOffEmail ? (
                  <span className="stageNumber">One-off email</span>
                ) : (
                  <>
                    {sequenceName && (
                      <span
                        className="sequenceName"
                        title={sequenceName}
                        onClick={e => redirectToSequence(e)}
                      >
                        {sequenceName}
                      </span>
                    )}
                    {(order && (
                      <span className="stageNumber">{`Step ${order} - ${
                        taskType?.toLowerCase() === "manualemail"
                          ? "Manual"
                          : "Auto"
                      } Email`}</span>
                    )) ||
                      ""}
                  </>
                )}
              </div>
            )}
          {["active"].includes(status?.toLowerCase()) && (
            <div className="btnCont">
              <Button {...editButtonObj} />
            </div>
          )}
        </div>
        <div className="common-popup-mask" />
      </div>
    )
  );
});

const EmailAddressList = observer((props = {}) => {
  const {
    id = "",
    emailStr = "",
    list = [],
    contacts = [],
    recipientStatus = {},
    activeFilter = {}
  } = props || {};
  const filterByEmailStatus = (email = "") => {
    const contact = contacts.find(item => {
      return email === item?.email;
    });
    if (emailStr) {
      return email;
    } else if (
      activeFilter?.id === "BOUNCE_COUNT" &&
      recipientStatus?.[contact?.id]?.status === "BOUNCED"
    ) {
      return email;
    } else if (
      activeFilter?.id !== "BOUNCE_COUNT" &&
      (recipientStatus?.[contact?.id]?.status !== "BOUNCED" ||
        !recipientStatus?.[contact?.id] ||
        !contact?.id)
    ) {
      return email;
    }
  };

  let emailList =
    (list?.length > 0 && list.filter(item => filterByEmailStatus(item))) || [];

  return (
    (emailList?.length > 0 && (
      <div className={`toAddress`}>
        <span className="labelName">{Utils.firstLetterCapitialize(id)}:</span>
        <div className="toEmailText">
          {emailList.map(email => {
            return (
              <a
                className="chipEmail"
                href={`mailto:${email}`}
                target="_blank"
                key={`${id}-${email}`}
                rel="noreferrer noopener nofollow"
              >
                {email}
              </a>
            );
          })}
        </div>
      </div>
    )) ||
    ""
  );
});

export { OutboxCardPopup };
export default withRouter(OutboxCardPopup);
