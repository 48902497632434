import React from "react";
import { observer } from "mobx-react";
import PageLimit from "Components/common/PageLimit";
import PaginationComponent from "Components/common/PaginationComponent";
import { syncLogsHistoryPopupState } from "./state";

const Pagination = observer(() => {
  const getContactsByLimit = (limit = 10) => {
    syncLogsHistoryPopupState.setLimit(limit);
    syncLogsHistoryPopupState.setPageNo(1);
  };

  const paginate = (pageObj = {}) => {
    const selectedPageNo = pageObj?.selected + 1 || 1;
    syncLogsHistoryPopupState.setPageNo(selectedPageNo);
  };

  return (
    <div className="paginationContent">
      <PageLimit
        limitPerPage={syncLogsHistoryPopupState.limit}
        text="Rows per page"
        cbk={getContactsByLimit}
        totalCount={syncLogsHistoryPopupState?.totalLogs}
      />
      <PaginationComponent
        callback={paginate}
        loading={syncLogsHistoryPopupState?.loader}
        totalPages={syncLogsHistoryPopupState?.totalPages}
        currentPage={syncLogsHistoryPopupState?.pageNo || 1}
      />
    </div>
  );
});

export { Pagination };
