/* eslint-disable array-callback-return */
import { observable, action } from "mobx";
import { makeApi, URLS } from "Utils/apiURL";
import Utils from "Utils/utils";
import { toasterState } from "../Components/common/toaster";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import { SequenceUtils } from "Utils/SequenceUtils";

export const defaultFilterPayload = {
  pageNo: 1,
  limit: 10,
  showReplySequence: true
};
class SequenceData {
  @observable sequenceData = "";
  @observable componentState = false;
  @observable allFolderResponse = "";
  @observable statisticsData = {};
  @observable sequenceStatus = null;
  @observable contactInfo = "";
  @observable missingSenderTokens = [];
  @observable addContactLoading = false;
  @observable selectedConnectedInbox = [];
  @observable activeSequenceList = [];
  @observable tempThrottlingData = {};
  @observable tempSequenceData = "";
  @observable seqActivated = false;
  @observable reloadSequenceContactPage = false;
  @observable selectedTemplateSequence = "";
  @observable currentPage = 1;
  @observable filterPayload = { ...defaultFilterPayload };
  @observable pageLimit = 15;
  @observable totalPage = 1;
  @observable totalSequences = "";
  @observable folderId = "";
  @observable dynamicCurrentPage = 1;
  @observable dynamicPageLimit = 15;
  @observable dynamicTotalPage = 1;
  @observable paginationDataLoading = true;
  @observable isSharedSeqFolder = false;

  cancelDailyLimitCall = null;
  cancelSequenceCall = null;

  @action
  setPaginationDataLoading = value => {
    this.paginationDataLoading = value;
  };

  @action
  setIsSharedSeqFolder = value => {
    this.isSharedSeqFolder = value;
  };

  @action
  setDynamicCurrentPage(dynamicCurrentPage = 1) {
    this.dynamicCurrentPage = dynamicCurrentPage;
  }
  @action
  setDynamicPageLimit(dynamicPageLimit = 1) {
    this.dynamicPageLimit = dynamicPageLimit;
  }
  @action
  setDynamicTotalPage(dynamicTotalPage = 1) {
    this.dynamicTotalPage = dynamicTotalPage;
  }

  @action
  setFolderId = (folderId = "") => {
    this.folderId = folderId;
  };

  @action
  setTotalSequences = (count = "") => {
    this.totalSequences = count;
  };

  @action
  setFilterPayload = (obj = {}) => {
    this.filterPayload = obj;
  };

  @action
  setTotalPage = (totalPage = 0) => {
    this.totalPage = totalPage;
  };

  @action
  setCurrentPage = val => {
    this.currentPage = val;
  };

  @action
  setPageLimit = (limit = 10) => {
    this.pageLimit = limit;
  };

  @action
  setFilterPayload = (obj = {}) => {
    this.filterPayload = { ...this.filterPayload, ...obj };
  };

  @action
  setSelectedTemplateSequence(value) {
    this.selectedTemplateSequence = value;
  }

  @action
  setSelectedTemplateSequence = (data = [], type) => {
    const newArr = data;
    newArr &&
      newArr.map((key, index) => {
        newArr[index].sortType = type;
      });
    this.selectedTemplateSequence = newArr;
  };

  @action
  setSequenceData(value) {
    this.sequenceData = value;
  }

  @action
  setSequenceStatus(value) {
    this.sequenceStatus = value;
  }

  @action
  setComponentState(value) {
    this.componentState = value;
  }

  @action
  setFolderResponse = value => {
    if (value) this.allFolderResponse = value;
  };

  @action
  setActiveSequenceList(value) {
    this.activeSequenceList = value;
  }

  @action
  setTempSequenceData(value) {
    this.tempSequenceData = value;
  }

  @action
  cancelSequenceCallAction = value => {
    this.cancelSequenceCall = value;
  };

  @action
  setReloadSequenceContactPage = (val = false) => {
    this.reloadSequenceContactPage = val;
  };

  // As there is no seperate store for daily limit. cancel previous request for daily limit is handled here

  @action
  cancelDailyLimitCallAction = value => {
    this.cancelDailyLimitCall = value;
  };

  @action
  setContactInfo = value => {
    this.contactInfo = value;
  };

  @action
  setMissingSenderTokens = value => {
    this.missingSenderTokens = value;
  };

  @action
  setAddContactLoading = value => {
    this.addContactLoading = value;
  };

  @action
  setSelectedConnectedInbox = value => {
    this.selectedConnectedInbox = value;
  };

  @action
  setTempThrottlingData = value => {
    this.tempThrottlingData = value;
  };

  @action
  setSeqActivated = (value = false) => {
    this.seqActivated = value;
  };

  @action
  getAllSequenceData = async (cbk, payload = null) => {
    try {
      const config = {
        url: URLS.getMySequenceData,
        method: "POST",
        data: payload
      };
      Utils.cancelPreviousSequenceRequest();
      let response = await makeApi(config, this.cancelSequenceCallAction);
      if (response && response?.data) {
        const {
          sequences = [],
          totalPages = 1,
          currentPage = 1,
          totalSequences = 1
        } = response.data || {};

        if (totalSequences) {
          this.setTotalSequences(totalSequences);
        }
        if (currentPage) {
          this.setCurrentPage(currentPage);
        }
        if (totalPages) {
          this.setTotalPage(totalPages);
        }
        if (sequences) {
          this.setSequenceData(sequences);
          SequenceUtils.addActiveSequenceIdToLocalStorage(sequences);
          // eslint-disable-next-line no-unused-expressions
          cbk ? cbk() : "";
        } else Utils.showApiFailureResponse(response);
      }
    } catch (err) {
      console.error(err);
    }
  };

  @action
  getStatisticsData = async id => {
    try {
      const config = {
        url: URLS.getSeqStatistics ? URLS.getSeqStatistics + id : "",
        method: "GET"
      };
      let res = await makeApi(config);
      if (res?.data) {
        this.statisticsData = res.data;
      }
    } catch (err) {
      console.error(err);
    }
  };

  @action
  getAllSequenceFolders = async () => {
    try {
      const config = {
        url: URLS.getAllSequenceFolders,
        method: "GET"
      };
      let res = await makeApi(config);
      if (res?.data) {
        this.setFolderResponse(res.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  @action
  updateSequenceFolderName = async (url, editedFolderName) => {
    try {
      const config = {
        url,
        method: "PUT"
      };
      let res = await makeApi(config);
      const { status } = res;
      if (res?.data?.folderName && status === 200) {
        toasterState.setToastMsg("Folder name updated successfully", "success");
        return this.updateFolderName(res.data);
      } else if (res && res.response && res.response.status === 409) {
        toasterState.setToastMsg(
          `${editedFolderName} is already taken. Please choose a different name`,
          "fail"
        );
      } else {
        Utils.showApiFailureResponse(res);
      }
    } catch (err) {
      console.error(err);
    }
  };

  @action
  updateFolderName = respData => {
    const { folderName, id } = respData;
    this.allFolderResponse &&
      this.allFolderResponse.map(value => {
        if (value?.id === id) {
          value.folderName = folderName;
        }
      });
    return this.allFolderResponse;
  };

  @action
  getSequenceStatusBySeqId = async seqId => {
    try {
      let targetURL = URLS.checkSeqStatus ? URLS.checkSeqStatus : "";
      targetURL = targetURL + seqId;
      const config = {
        url: targetURL,
        method: "GET"
      };
      let response = await makeApi(config);
      if (response?.data) {
        const { requestStatus, modifyAccess, message } = response.data;
        const tempObj = {
          isSequenceActive: requestStatus,
          hasModifyAccess: modifyAccess,
          message,
          sequenceId: seqId
        };
        this.setSequenceStatus(tempObj);
      }
    } catch (error) {
      console.error("Check seq status error ", error);
    }
  };

  @action
  deleteFolderFromStore = folderId => {
    const newFolderList = [];
    this.allFolderResponse.map(item => {
      if (item.id !== folderId) {
        newFolderList.push(item);
      }
    });
    this.allFolderResponse = newFolderList;
  };

  @action
  deleteSequenceFolder = async (folderId, cbk) => {
    try {
      let url = URLS.deleteSequenceFolder.replace("<<folderId>>", folderId);
      const config = {
        url,
        method: "DELETE"
      };
      const res = await makeApi(config);
      if (res?.data?.statusCode === 200) {
        const { status } = res.data;
        if (status === "success") {
          this.deleteFolderFromStore(folderId);
          toasterState.setToastMsg(
            "Sequences folder deleted successfully",
            "success"
          );
          cbk(true);
        } else if (status === "has_sequence") {
          confirmationPopupState.setPopupValues({
            title: "Folder has sequence?",
            content: `Sorry, you cannot delete a folder that has sequences. Delete the sequences in this folder and try again`,
            actionBtnText: "Ok",
            needCancelBtn: false,
            callback: () => {
              confirmationPopupState.setShowPopup(false);
            }
          });
          confirmationPopupState.setShowPopup(true);
        } else {
          Utils.showApiFailureResponse(res);
        }
      } else {
        Utils.showApiFailureResponse(res);
      }
    } catch (err) {
      console.error(err, "Delete Sequence page folder error");
    }
  };

  @action
  setSequenceData = (data = [], type) => {
    const newArr = data;
    newArr &&
      newArr.map((key, index) => {
        newArr[index].sortType = type;
      });
    this.sequenceData = newArr;
  };

  @action
  getSequenesBySorting = async (data, cbk) => {
    try {
      const config = {
        url: URLS.getMySequenceData,
        method: "POST",
        data
      };
      const res = await makeApi(config);
      if (res?.data?.sequences?.length) {
        cbk(data?.sortDirection, true, res?.data?.sequences);
      } else {
        Utils.showApiFailureResponse(res);
        cbk(data.sortDirection, false);
      }
      sequenceStoreData.setCurrentPage(1);
    } catch (err) {
      console.error("Error in sorting sequence", err);
    }
  };

  @action
  getSenderToken = async seqId => {
    try {
      let targetURL = URLS?.getSenderToken || "";
      targetURL = targetURL + seqId;
      const config = {
        url: targetURL,
        method: "GET"
      };
      let response = await makeApi(config);
      if (response?.data) {
        sequenceStoreData.setAddContactLoading(false);
        const { missingSenderTokens = [] } = response.data || {};
        this.setMissingSenderTokens(missingSenderTokens);
      }
    } catch (error) {
      console.error("Check sender token error ", error);
    }
  };

  @action
  removeInboxFromSequence = async (data = {}, cbk = () => {}) => {
    try {
      const config = {
        url: `${URLS.removeInboxFromSeq}/${data?.sequenceId}/${data?.inboxId}`,
        method: "DELETE",
        data
      };
      let response = await makeApi(config);
      const { response: { status = 500 } = {} } = response || {};
      if (response?.status === 200) {
        toasterState.setToastMsg(
          `Inbox deleted successfully from the sequence.`,
          "success"
        );
        cbk(true);
      } else if (+status === 403) {
        toasterState.setToastMsg(
          `You don't have access to remove inbox from this sequence.`,
          "fail"
        );
        cbk && cbk(false);
      } else {
        toasterState.setToastMsg(`Inbox deletion failed`, "fail");
        cbk(false);
      }
    } catch (e) {
      console.error("Removing inbox from sequence error", e);
    }
  };

  @action
  addInboxToSequence = async (data, cbk) => {
    try {
      const config = {
        url: `${URLS.addInboxToSeq}/${data?.sequenceId}/${data?.inboxId}/${data?.email}`,
        method: "POST",
        data
      };
      let response = await makeApi(config);
      const { response: { status = 500 } = {} } = response || {};
      if (response?.status === 200) {
        toasterState.setToastMsg(
          `Inbox added successfully to the sequence.`,
          "success"
        );
        cbk && cbk(true);
      } else if (+status === 403) {
        toasterState.setToastMsg(
          `You don't have access to add inbox to this sequence.`,
          "fail"
        );
        cbk && cbk(false);
      } else {
        toasterState.setToastMsg(`Inbox addition failed`, "fail");
        cbk && cbk(false);
      }
    } catch (e) {
      console.error("Removing inbox from sequence error", e);
    }
  };

  @action
  valideExcludeContact = async (sequenceId = "", email = "") => {
    if (sequenceId && email) {
      try {
        const config = {
          url: URLS.validateExcludeContact
            .replace("<<sequenceId>>", sequenceId)
            .replace("<<email>>", email)
        };
        let response = await makeApi(config);
        return response?.data?.status;
      } catch (e) {
        console.error("Error in API on validating exclude contact", e);
      }
    }
    return false;
  };

  @action
  validateToContactStatus = async (email = "") => {
    if (email) {
      try {
        const config = {
          url: URLS.validateToContactStatus.replace("<<email>>", email)
        };
        let response = await makeApi(config);
        return response?.data === "OPTED_OUT";
      } catch (e) {
        console.error("Error in API on validating exclude contact", e);
      }
    }
    return false;
  };

  @action
  getBackgroundPauseResumeSequence = async (data = {}) => {
    try {
      const config = {
        url: URLS.performContactsBulkOperation,
        method: "POST",
        data
      };
      let response = await makeApi(config);
      return response;
    } catch (e) {
      console.error("Error in getting validate pause resume sequence", e);
    }
  };
}

const sequenceStoreData = new SequenceData();
export { SequenceData, sequenceStoreData };
export default SequenceData;
