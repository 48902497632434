import React from "react";
import { useHistory, withRouter } from "react-router-dom";
import { salesgearFieldMapping } from "Static/constant";
import DateUtils from "Utils/DateUtils";
import { Utils } from "Utils/utils";
import { toasterState } from "Components/common/toaster";
import { ContactCardFunction } from "Components/ContactCard/ContactCardFunction";
import { TASK_EVENT_TYPE } from "Model/model";
import { ContactUtils } from "Utils/ContactUtils";

export const ActivityFeedCard = (props = {}) => {
  const {
    activityOwner = {},
    clickedLinkDetails = "",
    contactEmail = "",
    contactFirstName = "",
    contactLastName = "",
    createTs = "",
    eventType = "",
    replySource = "",
    skippedStageTo = "",
    emailData = {},
    taskNotes = "",
    taskType = "",
    oneOffEmail = false,
    timelineEmail = false,
    group = "",
    sequenceName = "",
    category = "",
    autoEmail = false,
    meetingType = "",
    meetingBookedTimeZone = "",
    meetingBookedDate = "",
    isTaskPage = false,
    contactPrevOwner = {},
    contactOwner = {},
    executedBy = "",
    websiteVisitorData = [],
    triggerName = "",
    accountName = "",
    accountDomain = "",
    replyContent = "",
    autoReplyType = ""
  } = props || {};

  const { pageDetails = [] } = websiteVisitorData?.[0] || {};

  const {
    from = "",
    receivedDate = "",
    sentDate = "",
    subject = "",
    to = [],
    cc = [],
    bcc = []
  } = emailData || {};

  let { content = "" } = emailData || {};

  content =
    eventType.toLowerCase() === "replied" ? replyContent || content : content;

  const receiverContact = {
    firstName: contactFirstName,
    lastName: contactLastName,
    email: contactEmail
  };

  // Executed By RULES / TRIGGERS
  const executedType = {
    CONTACT_STAGE_RULES: "contact stage rule",
    TRIGGERS: "trigger"
  };

  const executedByList = ["CONTACT_STAGE_RULES", "TRIGGERS"];

  let executedByText =
    executedBy && executedByList.includes(executedBy)
      ? ` through the ${executedType[executedBy]}`
      : "";
  // End

  const receiverName = ContactCardFunction.getContactName(receiverContact);
  const senderName = ContactCardFunction.getOwnerName(activityOwner);

  const getFullContactName = (list = [], name = "", recipientCount = 0) => {
    if (list?.length > 0) {
      let tempName = `${(list?.[0]?.name || list?.[0]?.email)
        ?.trim()
        ?.replace(/['"]+/g, "")} ${
        recipientCount > 1
          ? `and ${recipientCount - 1} other${recipientCount > 2 ? "s" : ""}`
          : ""
      }`;
      return tempName;
    }
    return name;
  };

  const fromName = (from?.name || from?.email || senderName)
    ?.trim()
    ?.replace(/['"]+/g, "");

  const getRecipientCount = () => {
    let count = 0;
    if (to && typeof to === "object") {
      count += to?.length;
    }
    if (cc && typeof cc === "object" && Utils.arrayContainsValidValues(cc)) {
      count += cc?.length;
    }
    if (bcc && typeof bcc === "object" && Utils.arrayContainsValidValues(bcc)) {
      count += bcc?.length;
    }
    return count;
  };

  const toName = getFullContactName(to, receiverName, getRecipientCount());

  const tempAccountName =
    accountName || accountDomain?.split(".")?.[0] || accountDomain || "";

  const type =
    taskType?.toLowerCase() === "linkedinauto"
      ? "LinkedIn (Automatic)"
      : ["TASK", "PHONE"].includes(group)
      ? TASK_EVENT_TYPE[taskType?.toLowerCase()]
      : group === "EMAIL" && autoEmail
      ? "Email (Automatic)"
      : "Email (Manual)";

  // Important! executedByText will have space in text starting
  // String literal sentence don't need any space for executedByText
  const eventContent = {
    SENT: `${toName} was sent an email from ${fromName}${executedByText}.`,
    OPENED: `${toName} opened an email${executedByText}.`,
    CLICKED: `${toName} clicked a link in the email${executedByText}.`,
    REPLIED: `${toName} replied to an email${executedByText}.`,
    LINKEDIN_REPLIED: `${toName} replied to a message on LinkedIn`,
    LI_MESSAGE_SENT: `${toName} was sent a message on LinkedIn`,
    LI_CONNECTION_REQ_SENT: `${toName} was sent a connection request on LinkedIn`,
    LI_CONNECTION_REQ_ACCEPTED: `${toName} accepted a connection request on LinkedIn`,
    BOUNCED: `${toName} was bounced${executedByText}.`,
    OPTED_OUT: `${toName} opted out from all the sequences${executedByText}.`,
    PAUSED: `${toName} was paused in the sequence${executedByText}.`,
    RESUMED: `${toName} was resumed in the sequence${executedByText}.`,
    CALL_MADE: `${fromName} called ${toName}${executedByText}.`,
    CALL_LOG: `${fromName} logged a call${executedByText}.`,
    TASK_COMPLETED: `${fromName} completed a task for ${toName}${executedByText}.`,
    TASK_SKIPPED: `${fromName} skipped task for ${toName}${executedByText}.`,
    TASK_DELETED: `${fromName} deleted task for ${toName}${executedByText}.`,
    MARKED_AS_COMPLETE: `${toName} has been marked as completed in the sequence by ${fromName}${executedByText}.`,
    ADD_TO_SEQUENCE: `${toName} was added to the sequence by ${fromName}${executedByText}.`,
    SKIP_TO_NEXT_STEP: `${toName} was skipped a step ${skippedStageTo} in the sequence by ${fromName}${executedByText}.`,
    ACCOUNT_CREATED: `${tempAccountName} account was created by ${fromName}${executedByText}.`,
    ACCOUNT_FIELD_UPDATE: `${tempAccountName} was updated by ${fromName}${executedByText}.`,
    ACCOUNT_STAGE_UPDATE: `${tempAccountName} was updated by ${fromName}${executedByText}.`,
    CONTACT_CREATED: `${toName} was created by ${fromName}${executedByText}.`,
    CONTACT_FIELD_UPDATE: `${toName} was updated by ${fromName}${executedByText}.`,
    NOTES_CREATED: `A note was created for the contact ${toName}${executedByText}.`,
    NOTES_UPDATED: `A note was updated for the contact ${toName}${executedByText}.`,
    OOTO_PAUSED: `${toName} was automatically paused in the ${sequenceName} by OOO rule${executedByText}.`,
    OOTO_RESUMED: `${toName} was automatically resumed in the ${sequenceName} by OOO rule${executedByText}.`,
    OOTO_RESUME_FAILED: `Failed to resume ${toName} in the sequence ${sequenceName} as the sequence is currently disabled${executedByText}.`,
    EMAIL_VALIDATION_SERVICE_OPTED_OUT: `${toName} was marked as opted out automatically by Salesgear's email validation service${executedByText}.`,
    EMAIL_VALIDATION_SERVICE_BOUNCED: `${toName} was marked as bounced automatically by Salesgear's email validation service${executedByText}.`,
    REMOVE_CONTACT: `${toName} was removed from a sequence by ${fromName}${executedByText}.`,
    SENT_GMAIL: `${toName} ${
      getRecipientCount() > 1 ? "were" : "was"
    } sent an email from ${fromName}${executedByText}.`,
    RECEIVED_EMAIL: `${toName?.trim()} received an email from ${fromName}${executedByText}.`,
    TRIGGER: `${toName} was automatically added to the sequence${executedByText}.`,
    MEETING_BOOKED: `${
      meetingType === "PROSPECT" ? toName : fromName
    } booked a meeting with ${
      meetingType === "PROSPECT" ? fromName : toName
    } on ${DateUtils.getDateFullFormat(
      meetingBookedDate
    )} at ${DateUtils.getTimeByMerdian(meetingBookedDate)} ${
      meetingBookedTimeZone || ""
    }${executedByText}.`,
    CONTACT_OWNER_UPDATED: `${senderName} updated the contact owner${executedByText}.`,
    WEBSITE_VISITOR: `${Utils.getVisitorFullName(props)} visited ${
      pageDetails?.length > 0 ? "multiple" : "a"
    } page${pageDetails?.length > 0 ? "s" : ""} of your website.`,
    AUTO_REPLY: `${fromName} received an email from ${toName}.`
  };

  let dateValue =
    category === "INBOX_SYNC"
      ? eventType === "RECEIVED_EMAIL"
        ? receivedDate
        : sentDate
      : createTs;

  const isFromLinkedIn =
    (eventType?.toLowerCase() === "replied" &&
      replySource?.toLowerCase() === "linkedin") ||
    [
      "LI_MESSAGE_SENT",
      "LI_CONNECTION_REQ_SENT",
      "LI_CONNECTION_REQ_ACCEPTED"
    ].includes(eventType);

  return (
    <div className="activityFeedCard">
      <div className="contentCard">
        <p>
          {
            eventContent[
              eventType === "SENT" && category === "INBOX_SYNC"
                ? "SENT_GMAIL"
                : eventType?.toLowerCase() === "replied" &&
                  replySource?.toLowerCase() === "linkedin"
                ? "LINKEDIN_REPLIED"
                : eventType
            ]
          }
        </p>
        {triggerName && <TriggerDisplayComponent triggerName={triggerName} />}
        {["AUTO_REPLY"].includes(eventType?.toUpperCase()) && replyContent && (
          <span className="emailSubjectContent content">
            {Utils.convertDomToPlainText(replyContent)}
          </span>
        )}
        {(subject || content) &&
          !["WEBSITE_VISITOR", "AUTO_REPLY"].includes(
            eventType?.toUpperCase()
          ) && (
            <div className="emailSubjectContent">
              {subject && (
                <span className="subject">
                  {subject}
                  {content ? " - " : ""}
                </span>
              )}
              {content && (
                <span className="content">
                  {Utils.convertDomToPlainText(content)}
                </span>
              )}
            </div>
          )}
        {isFromLinkedIn && replyContent && (
          <div className="emailSubjectContent">
            <span className="taskType">{`Message - `}</span>
            <span className="content">
              {Utils.convertDomToPlainText(replyContent)}
            </span>
          </div>
        )}
        {["TASK_COMPLETED", "TASK_SKIPPED", "TASK_DELETED"].includes(
          eventType
        ) &&
          (type || taskNotes) && (
            <div className="contactTaskNotes">
              {type && (
                <>
                  <span className="taskType">{type} Task</span>
                  {taskNotes ? " - " : ""}
                </>
              )}
              {taskNotes}
            </div>
          )}
        {clickedLinkDetails?.url && (
          <ClickedLink url={clickedLinkDetails?.url} />
        )}
        {!oneOffEmail &&
          !timelineEmail &&
          !["WEBSITE_VISITOR", "AUTO_REPLY"].includes(eventType) && (
            <SequenceNameAndOrder
              {...props}
              showTitle={true}
              type={type}
              eventType={eventType}
              isTaskPage={isTaskPage}
            />
          )}
        {(eventType === "CALL_LOG" || taskType === "phone") && (
          <CallLogOutcomeAndNotes {...props} />
        )}
        {[
          "CONTACT_CREATED",
          "CONTACT_FIELD_UPDATE",
          "ACCOUNT_CREATED",
          "ACCOUNT_FIELD_UPDATE",
          "ACCOUNT_STAGE_UPDATE"
        ].includes(eventType) && <ContactFieldCreatedAndUpdated {...props} />}
        {eventType === "CONTACT_OWNER_UPDATED" && (
          <ContactOwnerUpdated
            contactPrevOwner={contactPrevOwner}
            contactOwner={contactOwner}
          />
        )}
        {oneOffEmail &&
          !["WEBSITE_VISITOR", "AUTO_REPLY"].includes(
            eventType?.toUpperCase()
          ) && (
            <div className="sequenceDetails">
              <span className="contactStepNumber">One-off email</span>
            </div>
          )}
        {["WEBSITE_VISITOR"].includes(eventType) && (
          <VisitedLinkList pageDetails={pageDetails} />
        )}
        {["AUTO_REPLY"].includes(eventType) &&
          ContactUtils.getAutoReplyDom(autoReplyType)}
      </div>
      <CalendarDate date={dateValue || createTs} />
    </div>
  );
};

const ClickedLink = ({ url = "" }) => {
  const handleRedirect = (event = "") => {
    Utils.preventDefaultFn(event);
    Utils.windowRedirect(Utils.removeHttpFromString(url), "_blank", true, true);
  };
  return (
    url && (
      <div className="clickLink">
        <b>Link:</b>{" "}
        <span className="linkText" onClick={e => handleRedirect(e)}>
          View Summary
        </span>
      </div>
    )
  );
};

const CalendarDate = props => {
  const { date = "" } = props || {};
  return (
    date && (
      <div className="calenderDate">
        {DateUtils.getFormatedDateAndTime(date)}
      </div>
    )
  );
};

const VisitedLinkList = (props = {}) => {
  let history = useHistory();
  const { pageDetails = [] } = props || {};

  const navigateToWebsiteTracking = () => {
    history.push("/website-tracking");
  };

  return (
    <div>
      <ul className="visitedLinkList">
        {pageDetails.map((item, index) => {
          if (index <= 1) {
            const { pageUrl = "" } = item || {};
            return <li>{pageUrl}</li>;
          }
        })}
        {pageDetails?.length > 1 && (
          <li className="showMoreButton" onClick={navigateToWebsiteTracking}>
            <a>{`+ ${pageDetails?.length - 2} more`}</a>
          </li>
        )}
      </ul>
    </div>
  );
};

const SequenceNameAndLink = withRouter(props => {
  let history = useHistory();
  const {
    sequenceId = "",
    folderId = "",
    sequenceShareType = "",
    email = "",
    sequenceName = "",
    currentUserEmail = "",
    order = ""
  } = props || {};

  const redirectToSequence = event => {
    Utils.preventDefaultFn(event);
    currentUserEmail === email || sequenceShareType
      ? history.push(
          `/sequences/${
            sequenceShareType === "SHARED" && currentUserEmail !== email
              ? "shared"
              : folderId || "all"
          }/${sequenceId}`
        )
      : toasterState.setToastMsg(
          "Sorry, you cannot access this private sequence",
          "fail"
        );
  };

  return (
    sequenceId &&
    sequenceName && (
      <span
        className={`sequenceName ${order ? "hasStep" : ""}`}
        onClick={event => redirectToSequence(event)}
      >
        {sequenceName}
      </span>
    )
  );
});

const SequenceNameAndOrder = props => {
  const {
    order = "",
    sequenceId = "",
    type = "",
    eventType = "",
    isTaskPage = false
  } = props || {};

  return (
    ((sequenceId || order) && (
      <div className="sequenceDetails">
        {(!["SKIP_TO_NEXT_STEP"].includes(eventType) && order > 0 && (
          <span className="contactStepNumber">
            Step {order} - {type}
          </span>
        )) ||
          ""}
        {!["SKIP_TO_NEXT_STEP"].includes(eventType) &&
          order > 0 &&
          sequenceId &&
          !isTaskPage &&
          "|"}
        {sequenceId && <SequenceNameAndLink {...props} showTitle={true} />}
      </div>
    )) ||
    ""
  );
};

const CallLogOutcomeAndNotes = ({ callNotes = "", callOutcome = "" }) => {
  return (
    <>
      {(callOutcome || callNotes) && (
        <div className="callDetails">
          {callOutcome}
          {callOutcome && callNotes ? " - " : ""}
          <span className="contactCallNotes">{callNotes}</span>
        </div>
      )}
    </>
  );
};

const ContactFieldCreatedAndUpdated = props => {
  const { fieldUpdateList = [] } = props || {};

  let activityFieldMapping = {
    ...salesgearFieldMapping,
    ...{ stage: "Stage" }
  };

  const getFieldName = (fieldName = "") => {
    return (
      (fieldName?.includes("address1") && fieldName?.split("_")?.[1]) ||
      fieldName ||
      ""
    );
  };

  return (
    (fieldUpdateList?.length && (
      <div className="fieldUpdateList">
        {fieldUpdateList.map((item, index) => {
          const { fieldName = "", newValue = "", oldValue = "" } = item || {};
          return (
            <div className="fieldNameList" key={`${index}${fieldName}`}>
              <span className="fieldName">
                <b>{activityFieldMapping[getFieldName(fieldName)]}:</b>{" "}
              </span>
              {oldValue && (
                <>
                  <span className="strikeThrough">{oldValue}</span>
                  {` | `}
                </>
              )}
              {newValue || "--"}
            </div>
          );
        })}
      </div>
    )) ||
    ""
  );
};

const ContactOwnerUpdated = (props = {}) => {
  const { contactPrevOwner = {}, contactOwner = {} } = props || {};

  let oldValue = Utils.getFullNameFromUserInfo(contactPrevOwner);
  let newValue = Utils.getFullNameFromUserInfo(contactOwner);

  return (
    <div className="fieldUpdateList">
      <div className="fieldNameList" key="contactOwnerUpdated">
        <span className="fieldName">
          <b>Contact Owner:</b>{" "}
        </span>
        {oldValue && (
          <>
            <span className="strikeThrough">{oldValue}</span>
            {` | `}
          </>
        )}
        {newValue || "--"}
      </div>
    </div>
  );
};

const TriggerDisplayComponent = (props = {}) => {
  const { triggerName = "" } = props || {};

  return (
    <div className="fieldUpdateList">
      <span className="fieldName">
        <b>Trigger:</b> {triggerName}
      </span>
    </div>
  );
};

export default ActivityFeedCard;
