/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Utils } from "Utils/utils";
import makeApi, { URLS } from "Utils/apiURL";
import { userDetail } from "Stores/userDetail";
import { tagsStoreData } from "Stores/TagsData";
import { PAYWALL_CONTENT } from "Model/model";
import { SALESFORCE_OAUTH_URL } from "Static/constant";
import salesForceIcon from "Assets/png/salesforce.png";
import { ReactComponent as ThunderOrangeIcon } from "Assets/svg/thunderOrange.svg";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import { toasterState } from "Components/common/toaster";
import UpgradeTooltip from "Components/Paywall/UpgradeTooltip";
import FormActionButton from "../../ConnectInbox/Configure/FormActionButton";
import ConnectOrDisconnectCard from "../ConnectOrDisconnectCard";
import { salesforceConnectedData } from "./SalesforceConnectedData";
import { SalesforceTabFields } from "./SalesforceTabFields";
import { MXP_EVENT } from "Static/MixPanelEvents";
import { settingsStoreData } from "Stores/settingsData";
import NewTooltip from "Components/common/NewTooltip";
import { useLocation, useHistory } from "react-router-dom";

const SalesforceConfigure = observer(props => {
  const { salesforceIntegrationEnabled: isFeatureEnabled = false } =
    userDetail.userFeatureFlag || {};
  const {
    hideCbk = () => {},
    showAdminPopup = () => {},
    planName = "",
    infreePlan = false
  } = props || {};
  const {
    salesforceConnected = false,
    connectedEmail = "",
    crmSettings = {}
  } = salesforceConnectedData.salesForceData || {};
  const [removeLoader, setRemoveLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showUpgradeTp, setShowUpgradeTp] = useState(false);
  const [apiLimitErr, setApiLimitErr] = useState(false);
  const [goBackUrl, setGoBackUrl] = useState(false);
  const location = useLocation();
  const history = useHistory();

  const updatedSalesforceConnected =
    salesforceConnected && crmSettings && Object.keys(crmSettings).length > 0;

  const handleRedirection = (interval = 1000) => {
    setTimeout(() => {
      if (settingsStoreData.saveFromContacts) {
        settingsStoreData.setSaveFromContacts(false);
        settingsStoreData.setFromContacts(false);
        Utils.showRedirection(goBackUrl, 5, history);
      }
    }, interval);
  };

  const openSalesForceAuthWindow = () => {
    if (isFeatureEnabled && SALESFORCE_OAUTH_URL) {
      const w = 600;
      const h = 800;
      const left = window.screen.width / 2 - w / 2;
      const top = window.screen.height / 2 - h / 2;
      let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=${w},height=${h},left=${left},top=${top}`;
      const oAuthWindow = window.open(
        SALESFORCE_OAUTH_URL,
        "Salesforce Login",
        params
      );
      const oauthSuccess = async ev => {
        if (ev.data === "windowRedirectSuccess") {
          oAuthWindow.close();
          Utils.mixpanelTrack(MXP_EVENT.CRM_CONNECTED, {
            type: "Salesforce"
          });
          salesforceConnectedData.getSalesForceOauthData();
        }
        handleRedirection(1500);
      };
      window.addEventListener("message", oauthSuccess);
    }
  };

  const removeAccount = () => {
    const cbk = async () => {
      setRemoveLoader(true);
      salesforceConnectedData.removeSfAccount(() => {
        setRemoveLoader(false);
      });
    };

    if (Utils.isAdmin()) {
      confirmationPopupState.setPopupValues({
        content:
          "Are you sure? Disconnecting the CRM will stop all current and scheduled sync jobs.",
        actionBtnText: "Disconnect",
        cancelBtnText: "Cancel",
        callback: () => {
          cbk();
        }
      });
      confirmationPopupState.setShowPopup(true);
    } else {
      showAdminPopup("Salesforce");
    }
  };

  const makeAction = () => {
    !salesforceConnected ? openSalesForceAuthWindow() : removeAccount();
  };

  const removeUnwantedFields = (config = {}, setConfig = () => {}) => {
    const fields = [...config?.fields];
    fields?.length > 0 &&
      fields.map((item, index) => {
        if (!item?.sgField && !item?.crmField) {
          fields.splice(index, 1);
        }
        return fields;
      });
    setConfig({ ...config, fields });
    return {
      ...config,
      fields
    };
  };

  const removeUnwantedContactConditions = () => {
    const conditions = [...salesforceConnectedData?.sfContactConditions];
    conditions?.length > 0 &&
      conditions.map((item, index) => {
        if (!item?.fieldName || !item?.operator || !item?.value) {
          conditions.splice(index, 1);
        }
      });
    salesforceConnectedData.setSfContactConditions(conditions);
  };

  const removeUnwantedLeadConditions = () => {
    const conditions = [...salesforceConnectedData?.sfLeadConditions];
    conditions?.length > 0 &&
      conditions.map((item, index) => {
        if (!item?.fieldName || !item?.operator || !item?.value) {
          conditions.splice(index, 1);
        }
      });
    salesforceConnectedData.setSfLeadConditions(conditions);
  };

  const constructSalesforceConfig = (isFromSave = false) => {
    const extractedLeadTags =
      Utils.extractTagNameFromList(salesforceConnectedData.sfLeadTags) || [];
    const extractedContactTags =
      Utils.extractTagNameFromList(salesforceConnectedData.sfContactTags) || [];
    isFromSave && removeUnwantedLeadConditions();
    isFromSave && removeUnwantedContactConditions();

    const updatedSfLeadConfig = isFromSave
      ? removeUnwantedFields(
          salesforceConnectedData?.sfLeadConfig,
          salesforceConnectedData.setSfLeadConfig
        )
      : salesforceConnectedData?.sfLeadConfig;
    const updatedSfContactConfig = isFromSave
      ? removeUnwantedFields(
          salesforceConnectedData?.sfContactConfig,
          salesforceConnectedData.setSfContactConfig
        )
      : salesforceConnectedData?.sfContactConfig;
    const payload = {
      config: {
        lead: {
          ...updatedSfLeadConfig,
          tags: extractedLeadTags,
          conditions: salesforceConnectedData?.sfLeadConditions
        },
        contact: {
          ...updatedSfContactConfig,
          tags: extractedContactTags,
          conditions: salesforceConnectedData?.sfContactConditions
        }
      },
      apiLimitPerDay: salesforceConnectedData?.apiLimitPerDay,
      owner: salesforceConnectedData?.sfOwnerConfig,
      preference: {
        ...salesforceConnectedData?.SfSettingPreference
      }
    };
    return payload;
  };

  const saveChangesAction = async () => {
    setLoader(true);
    if (Utils.isAdmin()) {
      if (
        salesforceConnectedData?.apiLimitPerDay > 15000 &&
        salesforceConnectedData?.apiLimitPerDay < 1
      ) {
        setApiLimitErr(true);
      } else {
        const payload = constructSalesforceConfig(true);
        try {
          const config = {
            url: URLS.updateSfConfig,
            method: "POST",
            data: payload
          };
          let res = await makeApi(config);
          if (res?.data) {
            const { responseCode = "", missingFields = [] } = res?.data || {};
            if (+responseCode === 200) {
              tagsStoreData.getAllTags();
              salesforceConnectedData.setSalesforceData(res?.data);
              setLoader(false);
              toasterState.setToasterTimeout(1000);
              toasterState.setToastMsg(
                "Salesforce Integration settings updated successfully.",
                "success"
              );
            } else if (+responseCode === 400) {
              setLoader(false);
              toasterState.setToastMsg(
                `The ${missingFields?.length > 1 ? "fields" : "field"} ${
                  missingFields?.length > 1
                    ? missingFields?.join(", ")
                    : missingFields[0]
                } should be part of the field mapping as it is being used in the condition.`,
                "failure"
              );
            } else {
              toasterState.setToastMsg(
                "Something went wrong. Please try again later.",
                "failure"
              );
            }
          } else {
            Utils.showApiFailureResponse(res);
          }
          handleRedirection();
        } catch (err) {
          console.error("Salesforce Owners listing error", err);
        }
      }
    } else {
      showAdminPopup();
    }
  };

  useEffect(() => {
    const {
      config: { lead = {}, contact = {} } = {},
      owner = [],
      syncClickEnabled = false,
      syncOpenEnabled = false,
      syncPhoneCallEnabled = true,
      syncReplyEnabled = false,
      syncSentEnabled = false,
      apiLimitPerDay = 1,
      syncLiAcceptRequest = false,
      syncLiSendRequest = false,
      syncLiMessageSent = false,
      syncLiReplyReceived = false,
      syncCustomTask = false
    } = crmSettings || {};

    const { conditions: leadConditions = [] } = lead || {};
    const { conditions: contactConditions = [] } = contact || {};

    if (lead && Object.keys(lead)?.length > 0) {
      const leadConfig = Utils.createObjWithoutRef(lead);
      salesforceConnectedData.setSfLeadConfig(leadConfig);
      leadConditions
        ? salesforceConnectedData.setSfLeadConditions([...leadConditions])
        : salesforceConnectedData.setSfLeadConditions([]);
    }
    if (contact && Object.keys(contact).length > 0) {
      const contactConfig = Utils.createObjWithoutRef(contact);
      salesforceConnectedData.setSfContactConfig(contactConfig);
      contactConditions
        ? salesforceConnectedData.setSfContactConditions([...contactConditions])
        : salesforceConnectedData.setSfContactConditions([]);
    }
    salesforceConnectedData.setSfOwnerConfig(owner);
    salesforceConnectedData.setSfSettingPreference({
      syncClickEnabled,
      syncOpenEnabled,
      syncPhoneCallEnabled,
      syncReplyEnabled,
      syncSentEnabled,
      syncLiAcceptRequest,
      syncLiSendRequest,
      syncLiMessageSent,
      syncLiReplyReceived,
      syncCustomTask
    });
    salesforceConnectedData.setApiLimitPerDay(apiLimitPerDay);
  }, [crmSettings?.config]);

  useEffect(() => {
    let queryParams = Utils.getQueryParams(location.search);
    let isFromContacts = queryParams.get("source") === "contact" || false;
    if (isFromContacts) {
      settingsStoreData.setFromContacts(true);
      settingsStoreData.setSaveFromContacts(true);
      const tempGoBackUrl = localStorage.getItem("goBackUrl") || "/contacts";
      setGoBackUrl(tempGoBackUrl);
      localStorage.removeItem("goBackUrl");
    }
  }, []);

  return (
    <div className="crmConfigurePage salesForce">
      {settingsStoreData?.fromContacts && (
        <NewTooltip
          className={
            !salesforceConnected ? "crmNotConnected" : "salesforceConnected"
          }
          closeIconCbk={() => settingsStoreData.setFromContacts(false)}
          heading={"Action Required"}
          text={
            !salesforceConnected
              ? `Click to connect your Salesforce CRM and start syncing contacts.`
              : `Set field sync direction to ‘Outbound’ or ‘2-Way Sync’ for fields you want to export to Salesforce`
          }
        />
      )}
      <ConnectOrDisconnectCard
        isConnected={salesforceConnected}
        addOrRemoveAccount={makeAction}
        connectedEmail={connectedEmail}
        removeLoader={removeLoader}
        type="Salesforce"
        icon={salesForceIcon}
      />
      {updatedSalesforceConnected && (
        <div
          className="activityLogWrapper"
          onMouseEnter={() => !isFeatureEnabled && setShowUpgradeTp(true)}
          onMouseLeave={() => !isFeatureEnabled && setShowUpgradeTp(false)}
        >
          <SalesforceTabFields
            className={`${!isFeatureEnabled ? "disabledConfigure" : ""}`}
            crmSettings={crmSettings}
            constructSalesforceConfig={constructSalesforceConfig}
            setApiLimitErr={setApiLimitErr}
            apiLimitErr={apiLimitErr}
          />
          {!isFeatureEnabled && <ThunderOrangeIcon className="thunderOrange" />}

          {showUpgradeTp &&
            !isFeatureEnabled &&
            (infreePlan ||
              ["pro", "basic"].includes(planName?.toLowerCase())) && (
              <UpgradeTooltip
                {...PAYWALL_CONTENT[
                  infreePlan || planName?.toLowerCase() === "basic"
                    ? "salesforceSettings"
                    : "salesforceSettingsUnlimited"
                ]}
              />
            )}
        </div>
      )}
      <FormActionButton
        cancel={() => hideCbk()}
        save={saveChangesAction}
        saveBtnText="Save changes"
        loader={loader}
        className={`saveChangesBtn ${loader ? "loading" : ""}`}
        disabled={
          !(updatedSalesforceConnected && isFeatureEnabled) || apiLimitErr
        }
      />
      {settingsStoreData?.fromContacts && (
        <div
          className="highlightContactExportOverlay"
          onClick={() => settingsStoreData.setFromContacts(false)}
        />
      )}
    </div>
  );
});

export default SalesforceConfigure;
export { SalesforceConfigure };
