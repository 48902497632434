import React from "react";
import { ReactComponent as OpenedSvg } from "./images/svg/openTrack.svg";
import { ReactComponent as AutoReplySvg } from "./images/svg/autoReply.svg";
import { ReactComponent as ClickedSvg } from "./images/svg/linkTrack.svg";
import { ReactComponent as SentSvg } from "./images/svg/sentTrack.svg";
import { ReactComponent as RepliedSvg } from "./images/svg/replyTrack.svg";
import { ReactComponent as BouncedSvg } from "./images/svg/bounce.svg";
import { ReactComponent as OptoutSvg } from "./images/svg/optout.svg";
import { ReactComponent as LiSentSvg } from "./images/svg/linkedIn/liSent.svg";
import { ReactComponent as LiRepliedSvg } from "./images/svg/linkedIn/liReplied.svg";
import { ReactComponent as LiCrSentSvg } from "./images/svg/linkedIn/liCrSent.svg";
import { ReactComponent as LiCrAcceptedSvg } from "./images/svg/linkedIn/liCrAccepted.svg";
import { ReactComponent as CalendarIcon } from "Assets/svg/calendar.svg";

export const SVG_ICON = {
  openTrack: <OpenedSvg className="openTrack" />,
  clickTrack: <ClickedSvg className="clickTrack" />,
  sentTrack: <SentSvg className="sentTrack" />,
  replyTrack: <RepliedSvg className="replyTrack" />,
  bouncedTrack: <BouncedSvg className="bounceTrack" />,
  optedOutTrack: <OptoutSvg className="optedoutTrack" />,
  meetingBooked: <CalendarIcon className="meetingBooked" />,
  autoReply: <AutoReplySvg className="autoReplySvg" />,
  liCrSent: <LiCrSentSvg className="liCrSent" />,
  liCrAccepted: <LiCrAcceptedSvg className="liCrAccepted" />,
  liSent: <LiSentSvg className="liSent" />,
  liReplied: <LiRepliedSvg className="liReplied" />
};
