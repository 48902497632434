import makeApi, { URLS } from "Utils/apiURL";
import { toasterState } from "../common/toaster";
import { priceRangeSliderPopupState } from "../popup/PriceRangeSliderPopup/main";
const { observable, action } = require("mobx");

class PhoneNumberStore {
  @observable phoneNumberLoading = false;

  @action
  setPhoneNumberLoading = (value = "") => {
    this.phoneNumberLoading = value;
  };

  @action
  purchasePhoneNumber = async (contactId = "") => {
    try {
      const config = {
        url: `${URLS.purchasePhoneNumber}/${contactId}`,
        method: "GET"
      };
      let response = await makeApi(config);
      const responseCode = +response?.data?.code;
      this.setPhoneNumberLoading(false);
      if ([200, 201, 404]?.includes(responseCode)) {
        return response?.data;
      } else {
        if ([202, 103, 104, 402, 105]?.includes(responseCode)) {
          priceRangeSliderPopupState.setShowPopup(true);
        } else {
          toasterState.setToastMsg(
            "Something went wrong. Please try again later",
            "fail"
          );
        }
        return false;
      }
    } catch (err) {
      console.error(err);
    }
  };
}

const phoneNumberStore = new PhoneNumberStore();

export { phoneNumberStore };
