import React from "react";
import { ReactComponent as FlashIcon } from "Assets/svg/flash.svg";

export const UpgradeBadge = props => {
  const {
    plan = "basicPlan",
    badgeSize = "lg",
    showBadgeIcon = true,
    customText = false,
    text = ""
  } = props || {};

  const badgeGroup = {
    freePlan: {
      text: "Free",
      style: {
        border: "solid 1px #505050",
        backgroundImage: "linear-gradient(to bottom, #505050, #505050)"
      }
    },
    basicPlan: {
      text: "Basic",
      style: {
        border: "solid 1px #3b50fa",
        backgroundImage: "linear-gradient(to bottom, #596af8, #3c51ff)"
      }
    },
    proPlan: {
      text: "Pro",
      style: {
        border: "solid 1px #ff6057",
        backgroundImage: "linear-gradient(to bottom, #ff766f, #fa6b63)"
      }
    },
    unlimitedPlan: {
      text: "Unlimited",
      style: {
        border: "solid 1px #00cb1b",
        backgroundImage: "linear-gradient(to bottom, #00cb1b, #00cb1b)"
      }
    },
    customPlan: {
      text: "Custom",
      style: {
        border: "solid 1px #AD007F",
        backgroundImage: "linear-gradient(to bottom, #AD007F, #AD007F)"
      }
    },
    enterprisePlan: {
      text: "Enterprise",
      style: {
        border: "solid 1px #AD007F",
        backgroundImage: "linear-gradient(to bottom, #AD007F, #AD007F)"
      }
    }
  };

  return (
    <div
      className={`upgradeBadge ${badgeSize}`}
      style={badgeGroup?.[plan]?.style}
    >
      <FlashIcon className={`flashIcon ${badgeSize}`} />
      {showBadgeIcon && (
        <span className={`text ${badgeSize}`}>
          {customText ? text : badgeGroup?.[plan]?.text}
        </span>
      )}
    </div>
  );
};

export default UpgradeBadge;
