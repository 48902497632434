/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, createContext } from "react";
import { observer } from "mobx-react";
import Cookies from "js-cookie";
import SearchTableHeader from "./SearchTableHeader";
import SearchTableBody from "./SearchTableBody";
import { upgradePopupState } from "Components/Paywall/UpgradePopup";
import { userDetail } from "Stores/userDetail";
import { dataSearchState } from "../state";
import { Utils } from "Utils/utils";
import { initialWelcomePopupState } from "Components/common/InitialWelcomePopup";
import { DOMAIN_KEY } from "Static/constant";
import PaywallUtils from "Utils/PaywallUtils";

const ContactSearchTableContext = createContext({});

const ContactSearchTable = observer(props => {
  const { tableLoading = false, setTableLoading = () => {} } = props;
  const [contactData, setContactData] = useState({});
  const [allTableContactChecked, setAllTableContactChecked] = useState(false);

  const resetAllDynamicState = () => {
    dataSearchState.setSelectedContactData([]);
    setAllTableContactChecked(false);
    dataSearchState.setRangeSelected("");
    dataSearchState.setSelectionReset(false);
  };

  const allContactCheckboxAction = value => {
    setAllTableContactChecked(value);
  };

  const updateLocalTableData = newData => {
    setTableLoading(false);
    const clonedData = Utils.createObjWithoutRef(newData);
    setContactData(clonedData);
  };

  const resetAllSelectedContacts = () => {
    const clonedData = Utils.createObjWithoutRef(contactData);
    clonedData?.contacts &&
      clonedData.contacts.map(value => {
        value.isSelected = false;
      });
    setContactData(clonedData);
  };

  useEffect(() => {
    if (!dataSearchState?.selectedContactData?.length) {
      resetAllSelectedContacts();
      setAllTableContactChecked(false);
    }
  }, [dataSearchState.selectedContactData]);

  useEffect(() => {
    updateLocalTableData(dataSearchState.contactTableData);
  }, [dataSearchState.contactTableData]);

  useEffect(() => {
    (tableLoading || dataSearchState?.isSelectionReset) &&
      resetAllDynamicState();
  }, [tableLoading, dataSearchState.isSelectionReset]);

  useEffect(() => {
    const seoNameFromLocalStorage = Cookies.get("sg-seoName");
    if (seoNameFromLocalStorage) {
      dataSearchState.setFilterApplied(true);
      dataSearchState.setIsFromSeoPage(true);
      dataSearchState.setFilterComponentData({
        ...dataSearchState.filterComponentData,
        seoName: seoNameFromLocalStorage,
        excludeTeamOwns: false
      });
      initialWelcomePopupState.setRestrictOnboardingPopup(true);
      setTableLoading(true);
      dataSearchState.searchPageData();
      Cookies.remove("sg-seoName", { path: "", domain: DOMAIN_KEY });
      const updatedFilterComponentData = {
        ...dataSearchState.filterComponentData,
        excludeTeamOwns: true
      };
      delete updatedFilterComponentData["seoName"];
      dataSearchState.setFilterComponentData({
        ...updatedFilterComponentData
      });
    } else {
      dataSearchState.setIsFromSeoPage(false);
    }
    return () => {
      upgradePopupState.setPopup(false);
    };
  }, []);

  return (
    <section className="contactTableWrapper">
      <ContactSearchTableContext.Provider
        value={{
          tableLoading,
          setTableLoading,
          contactData,
          allTableContactChecked,
          setAllTableContactChecked,
          resetAllDynamicState,
          allContactCheckboxAction,
          setContactData
        }}
      >
        <SearchTableHeader />
        <SearchTableBody />
      </ContactSearchTableContext.Provider>
    </section>
  );
});

export { ContactSearchTable, ContactSearchTableContext };
export default ContactSearchTable;
