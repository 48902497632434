import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { action, observable } from "mobx";
import { observer } from "mobx-react";
import Button from "../../common/Button";
import Utils from "Utils/utils";
import { REQUEST_DEMO_CALENDAR_LINK } from "Static/constant";
import { PAGE_TYPE, MXP_EVENT } from "Static/MixPanelEvents";
import PricingStore from "Stores/Pricing";
import UpgradeBadge from "../../Paywall/UpgradeBadge";

class PriceRangeSliderPopupState {
  @observable showPopup = false;
  @observable sliderValue = "0";

  @action
  setShowPopup = (value = false) => {
    this.showPopup = value;
  };

  @action
  setSliderValue = (value = "0") => {
    this.sliderValue = value;
  };
}

const priceRangeSliderPopupState = new PriceRangeSliderPopupState();

const priceSliderArr = [
  { label: 50, value: "0" },
  { label: 100, value: "1" },
  { label: 300, value: "2" },
  { label: 500, value: "3" },
  { label: 1000, value: "4" },
  { label: "1000+", value: "5" }
];

const PriceRangeSliderPopup = observer(() => {
  const history = useHistory();

  let unlimitedPlan = PricingStore?.activePlanData.filter(
    item => item.displayName === "Unlimited"
  )[0];

  const getValueLabel = () => {
    const markers = document.getElementById("rangeSliderMarkers")?.options;
    if (priceRangeSliderPopupState.sliderValue && markers) {
      const selectedOption = markers[priceRangeSliderPopupState.sliderValue];
      return selectedOption.label;
    }
    return "";
  };

  const labelValue = getValueLabel();

  const handleChange = event => {
    priceRangeSliderPopupState.setSliderValue(event.currentTarget.value);
  };

  const handleBookDemo = () => {
    Utils.mixpanelTrack(MXP_EVENT.BOOK_A_DEMO_SHOWN, {
      pageType: PAGE_TYPE.discover
    });
    Utils.windowRedirect(REQUEST_DEMO_CALENDAR_LINK, "_blank", true);
  };

  const handlePayment = () => {
    priceRangeSliderPopupState.setShowPopup(false);
    priceRangeSliderPopupState.setSliderValue("0");
    PricingStore.setPaymentFormInfo(unlimitedPlan);
    localStorage.setItem("proceedToPay", true);
    history.push("/pricing/payment");
  };

  const btnProps = {
    id: "sliderBtn",
    name: "sliderBtn",
    btnText:
      +labelValue > 50 || labelValue === "1000+"
        ? "Book a Demo"
        : "Subscribe to $99/mo",
    type: "button",
    className: `sliderBtn`,
    click:
      +labelValue > 50 || labelValue === "1000+"
        ? handleBookDemo
        : handlePayment,
    loader: false
  };

  useEffect(() => {
    if (priceRangeSliderPopupState.showPopup) {
      Utils.mixpanelTrack(MXP_EVENT.UPGRADE_POPUP_SHOWN, {
        pageType: PAGE_TYPE.discover
      });
    }
  }, [priceRangeSliderPopupState.showPopup]);

  return (
    priceRangeSliderPopupState.showPopup && (
      <div className="priceRangeSliderWrapper">
        <div className="card">
          <div className="badgeText">
            <UpgradeBadge
              plan={"unlimitedPlan"}
              badgeSize="lg"
              customText={true}
              text={"Upgrade to Unlimited plan"}
            />
          </div>
          <div className="slideRangeHeader">
            <h5 className="title">Upgrade to find phone number</h5>
          </div>
          <div className="slideRangeContent">
            <label for="temp">
              Choose the number of phone numbers you need per month
            </label>
            <div className="rangeCont">
              <br />
              <input
                type="range"
                id="rangeSlider"
                name="temp"
                className="range-slider"
                value={priceRangeSliderPopupState.sliderValue || "0"}
                onChange={handleChange}
                min={0}
                max={5}
                step={1}
              />
              <datalist id="rangeSliderMarkers">
                {priceSliderArr?.map(item => (
                  <option
                    value={item?.sliderValue}
                    label={item?.label}
                  ></option>
                ))}
              </datalist>
            </div>
            <div className="btnContainer">
              <Button {...btnProps} />
            </div>
          </div>
        </div>
        <div
          className="common-popup-mask"
          onClick={() => {
            priceRangeSliderPopupState.setShowPopup(false);
            priceRangeSliderPopupState.setSliderValue("0");
          }}
        />
      </div>
    )
  );
});

export { PriceRangeSliderPopup, priceRangeSliderPopupState };
export default PriceRangeSliderPopup;
