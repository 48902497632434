import React from "react";
import { NavLink } from "react-router-dom";
import { sequenceStoreData } from "Stores/sequenceData";
import CreateFolder from "Components/common/CreateFolder";
import { observer } from "mobx-react";
import EditActionIcon from "Components/common/EditIcon";
import DeleteIcon from "Components/common/DeleteIcon";
import { useDrop } from "react-dnd";
import { URLS, makeApi } from "Utils/apiURL";
import Utils from "Utils/utils";
import { MP_EVENT } from "Static/constant";
import { toasterState } from "Components/common/toaster";
import { useScheduleDataOnPathMatch } from "../CustomHooks/useScheduleDataOnPathMatch ";

const SequenceFolderList = observer(props => {
  const {
    folderData,
    savedEditFolder,
    newFolderNameFocus,
    editFolderNameAction,
    cancelEditFolder,
    editedFolderName,
    folderLoader
  } = props;

  return (
    folderData?.length > 0 &&
    folderData.map((folderVal, index) => {
      const { folderName, id, isEdit, showEditIcon, showDeleteIcon } =
        folderVal || {};
      return isEdit ? (
        <CreateFolder
          saveNewFolder={savedEditFolder}
          newFolderNameFocus={newFolderNameFocus}
          newFolderNameAction={editFolderNameAction}
          newFolderNameCancelAction={cancelEditFolder}
          newFolderName={editedFolderName}
          folderLoader={folderLoader}
          id={id}
          key={"editFolder" + index}
        />
      ) : (
        <SequenceFolderRecord
          key={index}
          index={index}
          {...folderVal}
          {...props}
        />
      );
    })
  );
});

const SequenceFolderRecord = (props = {}) => {
  const {
    index,
    folderName,
    id,
    showEditIcon,
    showDeleteIcon,
    toggleEditIcon,
    deleteFolder,
    editFolder
  } = props || {};

  useScheduleDataOnPathMatch(`/sequences/${id}`);

  const removeSequenceFromList = (obj = "") => {
    const { id = "", isSharedFolder = false } = obj || {};
    sequenceStoreData.setTempSequenceData(sequenceStoreData.sequenceData);
    let tempList = [...(sequenceStoreData?.sequenceData || [])];
    if (isSharedFolder) {
      return tempList;
    }
    return tempList.filter(item => {
      if (item?.id !== id) {
        return item;
      }
    });
  };

  const moveSequenceToAnotherFolder = async (
    item = {},
    showUndoButton = true
  ) => {
    !showUndoButton && toasterState.close();
    if (item?.id && item?.folderId !== id) {
      let removedList = showUndoButton
        ? removeSequenceFromList(item)
        : sequenceStoreData.tempSequenceData;
      const config = {
        url: URLS.moveSequenceToAnotherFolder
          .replace("<<sequenceId>>", item.id)
          .replace("<<folderId>>", showUndoButton ? id : item?.folderId)
      };
      const res = await makeApi(config);
      const { status = "", success = false } = res?.data || {};
      Utils.mixpanelTrack(MP_EVENT.SQ_DRAG_DROP_PERFORMED, {
        pageType: "Sequences"
      });
      if (+status === 200 && success) {
        sequenceStoreData.sequenceData = removedList;
        !showUndoButton && sequenceStoreData.setTempSequenceData("");
        toasterState.setPopupValues({
          showUndoButton,
          callback: () => {
            showUndoButton && moveSequenceToAnotherFolder(item, false);
          }
        });
        let name = showUndoButton
          ? folderName
          : item?.folderName || "My Sequences";
        let msg = showUndoButton
          ? `to ${name} successfully.`
          : `back to ${name}`;
        toasterState.setToastMsg(`Sequence moved ${msg}`, "success");
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "fail"
        );
      }
    }
  };

  const [{ isOver }, dropRef] = useDrop({
    accept: "moveSequence",
    drop: moveSequenceToAnotherFolder,
    collect: monitor => ({
      isOver: monitor.isOver()
    })
  });

  return (
    <NavLink
      activeClassName="selected"
      className={`folder ${isOver ? "dropOver" : ""}`}
      exact
      key={"sequenceFolder" + index}
      data-name={folderName}
      data-id={id}
      to={`/sequences/${id}`}
      onMouseEnter={e => toggleEditIcon(e, id, "enter")}
      onMouseLeave={e => toggleEditIcon(e, id, "leave")}
      ref={dropRef}
    >
      <i className="material-icons-outlined">folder</i>
      <span className="folderName">{folderName}</span>
      {showEditIcon && (
        <EditActionIcon
          action={editFolder}
          id={id}
          itemValue={folderName}
          title="Edit Folder Name"
          spaceRight={5}
        />
      )}
      {showDeleteIcon && (
        <DeleteIcon
          action={deleteFolder}
          id={id}
          itemValue={folderName}
          title="Delete Folder"
          spaceRight={0}
        />
      )}
      {isOver && (
        <span className="material-icons-outlined dropHereText sequence">
          drag_handle
        </span>
      )}
    </NavLink>
  );
};

export default SequenceFolderList;
export { SequenceFolderList };
