/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { checkTeamSubscriptionActive } from "Utils/commonAPI";
import EmailInsightsComponent from "Components/common/EmailInsights";
import contactStoreData from "Stores/contactData";
import DialerServiceData from "Stores/DialerServiceStore";
import { ReactComponent as DeleteIcon } from "Assets/svg/delete.svg";
import DropDownMenu from "Components/common/DropDownMenu";
import { toasterState } from "Components/common/toaster";
import { settingsStoreData } from "Stores/settingsData";
import Utils from "Utils/utils";
import { URLS, makeApi } from "Utils/apiURL";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import CustomTooltip from "Components/common/CustomTooltip";
import PaginationComponent from "Components/common/PaginationComponent";
import userDetail from "Stores/userDetail";
import CheckBox from "Components/common/CheckBox";
import { editContactPopupState } from "./EditContactPopup";
import AddContactBtn from "./AddContact";
import NoContactImg from "Assets/png/contact.png";
import ContactLoader from "./ContactLoader";
import { deselectCheckedPopupState } from "Components/common/DeselectCheckedPopup";
import FeatureDemoVideo from "Components/common/FeatureDemoVideo";
import { ReactComponent as MakeACallIcon } from "Assets/svg/call.svg";
import { ReactComponent as LogCallIcon } from "Assets/svg/callLog.svg";
import { ReactComponent as UpdateIcon } from "Assets/svg/update.svg";
import { ReactComponent as AccountIcon } from "Global/SideBar/Icons/accounts.svg";
import globalCallState from "Stores/GlobalCallState";
import { dialerLogsPopupState } from "Components/DailerService/DialerLogsAfterCallEndPopup";
import PaywallUtils from "Utils/PaywallUtils";
import { ContactUtils } from "Utils/ContactUtils";
import { customListState } from "Pages/Dashboard/dataSearch/CustomList/state";
import { updateContactOwnerState } from "Components/common/UpdateContactOwner";
import { MXP_EVENT } from "Static/MixPanelEvents";

const selectedOption = "";

const RenderContactTable = observer((props = {}) => {
  const {
    filterEnabled,
    exportAllContactSelected,
    setExportAllContactSelected,
    setExportData,
    loading,
    tableLoadingFn = () => {},
    filterLoadingFn = () => {},
    contactData,
    contactUploading,
    setContactData,
    totalPages,
    selectedContactCard,
    activeTab,
    validContactUpload,
    checkEligibleContactCount,
    setContactUploading,
    contactLimit,
    allContactSelected,
    resetToSelectedCheckboxCbk = () => {},
    setSearchKeyword = () => {},
    setEmailClose = () => {},
    setActiveTab = () => {},
    contactFilterData = [],
    stageId = "",
    entireContactFilterData = [],
    setSelectedContactObj = () => {},
    updateContactOwnerCbk = () => {},
    setSelectedContactsNameList = () => {},
    selectedFilter = "",
    history = {}
  } = props || {};

  let userData = userDetail?.userFeatureFlag || {};

  const { inFreePlan = false, planName = "" } =
    userDetail?.userFeatureFlag || {};
  const { hasTeamMember = false } = userDetail?.userInfo || {};

  let settingsData = settingsStoreData.settingsData || {};

  const [showTooltip, setShowTooltip] = useState({});
  const [showUpdateDropDown, setShowUpdateDropDown] = useState(false);

  // start of show edit contact popup
  const showEditContactPopup = async (email, showDropdown = true) => {
    showDropdown && showDropDownMenu(email, false);
    if ((await checkTeamSubscriptionActive()) && contactData?.contacts) {
      const contactsList = JSON.parse(JSON.stringify(contactData?.contacts));
      let contact = contactsList.find(item => {
        if (item?.email === email) {
          return item;
        }
      });
      if (contact) {
        contact = extractPhoneNumbersFromContact(contact);
        editContactPopupState.setContact(contact);
        editContactPopupState.setShowPopup(true);
        editContactPopupState.setCallback(handleUpdateContactSuccess);
      }
    }
  };

  const extractPhoneNumbersFromContact = contact => {
    if (contact?.phone?.length) {
      contact.phone = contact.phone[0];
    }
    if (contact?.mobilePhone?.length) {
      contact.mobilePhone = contact.mobilePhone[0];
    }
    if (contact?.otherPhone?.length) {
      contact.otherPhone = contact.otherPhone[0];
    }
    return contact;
  };

  const handleUpdateContactSuccess = (contact = {}, updatedTags) => {
    if (!contactStoreData?.updateContactEmail) {
      let contactList = contactData?.contacts || [];
      const updatedContactList = contactList.map(item => {
        if (item?.email === contact?.email) {
          modifyTableDataFromResponse(item, contact, updatedTags);
        }
        return item;
      });
      contactData.contacts = updatedContactList;
      setContactData(contactData);
    }
    contactStoreData.setUpdateContactEmail("");
  };

  const modifyTableDataFromResponse = (
    tableData,
    responseData,
    updatedTags
  ) => {
    tableData.name = responseData.firstName;
    tableData.firstName = responseData.firstName;
    tableData.lastName = responseData.lastName;
    tableData.phone = responseData.phone;
    tableData.linkedinId = responseData.linkedinId;
    tableData.jobTitle = responseData.jobTitle;
    tableData.company = responseData.company;
    tableData.mobilePhone = responseData.mobilePhone;
    tableData.otherPhone = responseData.otherPhone;
    tableData.industry = responseData.industry;
    tableData.street = responseData.street;
    tableData.city = responseData.city;
    tableData.state = responseData.state;
    tableData.country = responseData.country;
    tableData.zipCode = responseData.zipCode;
    tableData.website = responseData.website;
    tableData.facebook = responseData.facebook;
    tableData.twitter = responseData.twitter;
    tableData.contactNotes = responseData.contactNotes;
    tableData.customFields = responseData.customFields;
    tableData.contactTags = updatedTags;
    return tableData;
  };

  const deleteContact = async email => {
    showDropDownMenu(email, false);
    if (await checkTeamSubscriptionActive()) {
      if (email) {
        const deleteContactCbk = async buttonLoadingFn => {
          const targetContact = findCurrentContact(email);
          if (targetContact) {
            const response = await deleteContactApi(targetContact.id);
            buttonLoadingFn(false);
            confirmationPopupState.setShowPopup(false);
            if (response?.status) {
              const respStatus = response.status;
              if (respStatus === 200) {
                userData.noOfContactsUsed -= 1;
                userDetail.setUserFeatureFlag(userData);
                handleContactDeleteSccess();
              } else {
                handleContactDeleteFailure();
              }
            } else if (response?.response?.status) {
              const respStatus = response.response.status;
              if (respStatus === 403) {
                toasterState.setToastMsg(
                  "You don't have deleting access to this contact",
                  "failure"
                );
              } else if (respStatus === 426) {
                Utils.showAccountSuspendedPopup();
              } else {
                handleContactDeleteFailure();
              }
            }
          }
        };

        confirmationPopupState.setPopupValues({
          title: "Are you sure",
          content:
            "Are you sure you want to delete this contact?<br/>All the scheduled emails and open tasks for this contact will be canceled.",
          actionBtnText: "Yes",
          callback: deleteContactCbk,
          loadingMsg: "Deleting contact. Please wait..."
        });
        confirmationPopupState.setShowPopup(true);
      }
    }
  };

  const updateStageApiCall = async (contactId, stageId, stageName, emailId) => {
    let payload = {
      contactId,
      stageId
    };
    const config = {
      url: URLS.updateStageInDirectory,
      method: "POST",
      data: payload
    };
    const response = await makeApi(config);
    handleApiResponse(response, stageId, stageName, emailId);
  };

  const handleApiResponse = (response, stageId, stageName, emailId) => {
    const responseCode = response?.status || "";
    if (responseCode === 200) {
      contactStoreData.filterContactAnalyticsData(
        contactStoreData.filterPayload,
        () => {}
      );
      let message = "Contact stage updated successfully.";
      let contactList = contactData?.contacts || [];
      const updatedContactList = contactList.map(e => {
        if (e?.email === emailId) {
          e.stageDisplayName = stageName;
          e.stageId = stageId;
        }
        return e;
      });
      contactData.contacts = updatedContactList;
      setContactData(contactData);
      toasterState.setToastMsg(message, "success");
    } else if (responseCode === 403) {
      let message = "You don't have editing access to this contact";
      toasterState.setToastMsg(message, "failure");
    } else toasterState.setToastMsg("Failed to updated contact", "failure");
  };

  const updateStage = (emailId, stageParam) => {
    let contact = null,
      contactId = null;
    let contactList = contactData?.contacts || [];
    const updatedContactList = contactList.map(e => {
      if (e?.email === emailId) {
        contact = e;
        e.showContactDropDown = false;
        contactId = e.id;
      }
      return e;
    });
    contactData.contacts = updatedContactList;
    setContactData(contactData);
    if (contactId) {
      updateStageApiCall(
        contactId,
        stageParam?.key,
        stageParam?.value,
        emailId
      );
    }
  };

  const updateStageDropDown = () => {
    setShowUpdateDropDown(!showUpdateDropDown);
  };

  const deleteContactApi = id => {
    const config = {
      url: URLS.addContactsToDirectory,
      method: "DELETE",
      data: { id }
    };
    return makeApi(config);
  };

  const handleContactDeleteSccess = () => {
    toasterState.setToastMsg("Contact deleted successfully", "success");
    customListState.getContactList();
    filterLoadingFn(true);
    tableLoadingFn(true);
    setExportData([]);
    setSelectedContactObj([]);
    setSelectedContactsNameList([]);
    setExportAllContactSelected(false);
    ContactUtils.contactFilterByPayload(
      contactStoreData.filterPayload,
      tableLoadingFn,
      filterLoadingFn,
      true
    );
  };

  const handleContactDeleteFailure = () => {
    toasterState.setToastMsg(
      "Contact delete failed. Please try again later",
      "failure"
    );
  };

  const optOutContact = async email => {
    showDropDownMenu(email, false);
    if (await checkTeamSubscriptionActive) {
      if (email) {
        const selectedContact = findCurrentContact(email);
        const name = selectedContact?.name || email;
        const optOutContactCbk = async buttonLoadingFn => {
          if (selectedContact) {
            const response = await optOutContactThrouhgApi(selectedContact.id);
            const respStatus =
              response?.status || response?.response?.status || "";
            if (respStatus === 200) {
              handleOptOutContactSuccess(selectedContact);
              toasterState.setToastMsg(`${name} was opted out`, "success");
              ContactUtils.contactFilterByPayload(
                contactStoreData?.filterPayload,
                tableLoadingFn,
                filterLoadingFn,
                true
              );
            } else if (respStatus === 403) {
              toasterState.setToastMsg(
                "You don't have access to this contact",
                "failure"
              );
            }
            buttonLoadingFn(false);
            confirmationPopupState.setShowPopup(false);
          }
        };

        confirmationPopupState.setPopupValues({
          content: `Are you sure you want to Opt out ${name}? The contact will be opted out from all sequences they are currently in.`,
          actionBtnText: "Yes",
          callback: optOutContactCbk,
          loadingMsg: "Opting out the contact. Please wait..."
        });
        confirmationPopupState.setShowPopup(true);
      }
    }
  };

  const optOutContactThrouhgApi = contactId => {
    const config = {
      url: URLS.optOutContact,
      method: "POST",
      data: { contactId }
    };
    return makeApi(config);
  };

  const handleOptOutContactSuccess = contact => {
    contactStoreData.filterContactAnalyticsData(
      { ...contactStoreData.filterPayload, stageId },
      () => {}
    );
    let contactList = contactData?.contacts || [];
    const updatedContactList = contactList.map(e => {
      if (e?.email === contact?.email) {
        e.stage = "OPTED_OUT";
        e.showContactDropDown = false;
      }
      return e;
    });
    contactData.contacts = updatedContactList;
    setContactData(contactData);
  };

  const openDailerServiceCard = async contact => {
    showDropDownMenu(contact?.email, false);
    if (
      contact &&
      contact?.stageDisplayName?.toLowerCase() === "do not contact"
    ) {
      toasterState.setToastMsg(
        "Oops! You cannot make call for a contact with 'do not contact' stage.",
        "failure"
      );
    } else {
      if (await checkTeamSubscriptionActive()) {
        if (userData?.dialerEnabled !== "") {
          if (userData?.dialerEnabled) {
            if (DialerServiceData?.dialerDefaultNo !== "purchaseNumber") {
              await DialerServiceData.getDailerToken();
              DialerServiceData.setDialerCallLogs(false);
              globalCallState.setPopupValues({
                ...globalCallState.popupValues,
                ...{ isFromContactSection: true, contactData: contact || {} }
              });
              globalCallState.setShowCallSlider(true);
              Utils.mixpanelTrack(MXP_EVENT.MAKE_CALL_CLICKED, {
                pageType: "Contacts Page",
                type: "Contact more option"
              });
            } else {
              Utils.showUpgradeSettingsPopup("dialerPurchase", true, true);
            }
          } else {
            PaywallUtils.upgradeUtilsFunction(
              "dialer",
              false,
              planName,
              inFreePlan
            );
          }
        } else {
          toasterState.setToastMsg("Please wait...", "info");
        }
      }
    }
  };

  const openCallLogPopup = (email = "") => {
    let contactList = [...contactData?.contacts] || [];
    let tempContact = {};
    const updatedContactList = contactList.map(item => {
      if (item?.email === email) {
        tempContact = item;
        item.showContactDropDown = false;
      }
      return item;
    });
    setContactData({ ...contactData, contacts: updatedContactList });
    if (tempContact) {
      dialerLogsPopupState.setActionType("SINGLE_CONTACT");
      dialerLogsPopupState.setContactData(tempContact);
      dialerLogsPopupState.setShowLogCallPopup(true);
    }
  };

  const openAssignContactOwnerPopup = (email = "", showDropdown = false) => {
    showDropdown && showDropDownMenu(email, false);
    const targetContact = findCurrentContact(email);
    const handleCallback = (selectedOwnerId = "") => {
      updateContactOwnerCbk(selectedOwnerId, "single", targetContact?.id);
    };
    updateContactOwnerState.setCurrentOwnerEmail(
      targetContact?.owner?.email || ""
    );
    updateContactOwnerState.setTotalCount(1);
    updateContactOwnerState.setCallback(handleCallback);
    updateContactOwnerState.setShowPopup(true);
  };

  const viewContactAccount = (email = "", showDropdown = false) => {
    if (email) {
      showDropdown && showDropDownMenu(email, false);
      let { accountId = "" } = findCurrentContact(email);
      accountId && history.push(`/accounts/${accountId}/overview`);
    }
  };

  const dropdownOptions = [
    hasTeamMember && {
      icon: {
        ele: '<i style="font-size: 15px" class="material-icons-outlined">person_pin</i>',
        style: { width: "15px", height: "15px" }
      },
      value: "Update Owner",
      key: "updateOwner",
      method: openAssignContactOwnerPopup
    },
    {
      icon: {
        ele: '<i style="font-size: 15px" class="material-icons-outlined list-icon">edit</i>',
        style: { width: "15px", height: "15px" }
      },
      value: "Edit",
      key: "Edit",
      method: showEditContactPopup
    },
    {
      icon: {
        ele: <MakeACallIcon />,
        style: { width: "16px", height: "16px" }
      },
      value: "Call",
      key: "Call",
      method: openDailerServiceCard,
      showUpgrade: !userData?.dialerEnabled,
      type: "callContact",
      plan: "proPlan"
    },
    {
      icon: {
        ele: <LogCallIcon />,
        style: { width: "16px", height: "16px" }
      },
      value: "Log call",
      key: "Log call",
      method: openCallLogPopup
    },
    {
      icon: { ele: <DeleteIcon />, style: { width: "16px", height: "16px" } },
      value: "Delete",
      key: "Delete",
      method: deleteContact
    }
  ];

  const viewAccountsOption = {
    icon: { ele: <AccountIcon />, style: { width: "16px", height: "16px" } },
    value: "View Account",
    key: "viewAccount",
    method: viewContactAccount
  };

  const getUpdatedOptions = disabled => {
    return [
      {
        icon: { ele: <UpdateIcon />, style: { width: "16px", height: "16px" } },
        value: "Update Stage",
        key: "Update Stage",
        method: updateStageDropDown,
        disabled
      }
    ];
  };

  const getOptedOutOptions = showOptedOut => {
    return !showOptedOut
      ? [
          {
            icon: {
              ele: '<i style="font-size: 15px" class="material-icons-outlined list-icon">block</i>',
              style: { width: "15px", height: "15px" }
            },
            value: "Opt out",
            key: "optout",
            method: optOutContact
          }
        ]
      : [];
  };

  const construcstageOptions = () => {
    let temp = [];
    entireContactFilterData.forEach(element => {
      let nameCheck = element?.name?.toLowerCase();
      if (!["bounced", "opted out", "total", "no stage"].includes(nameCheck)) {
        temp.push({
          value: element?.name,
          key: element?.id,
          method: updateStage
        });
      }
    });
    return temp;
  };
  const stageOptions = [...construcstageOptions(contactFilterData)];

  const paginate = pageNoObj => {
    if (!loading) {
      let pageNo = pageNoObj?.selected + 1 || 1;
      contactStoreData.setCurrentPage(pageNo);
      setExportData([]);
      setSelectedContactObj([]);
      setSelectedContactsNameList([]);
      setExportAllContactSelected(false);
      tableLoadingFn(true);
      let payload = { ...contactStoreData.filterPayload, pageNo };
      contactStoreData.setFilterPayload(payload);
      contactStoreData.filterContactData(payload, tableLoadingFn);
    }
  };

  const showDropDownMenu = (email, arg, referenceEle) => {
    if (!arg) {
      setShowUpdateDropDown(false);
    }
    if (email) {
      let contactList = contactData && contactData.contacts;
      const updatedContactList = contactList.map(e => {
        if (e.email === email) {
          if (referenceEle) {
            e.positionY = referenceEle.pageY;
          }
          if (arg) {
            e.showContactDropDown = false;
            setShowUpdateDropDown(false);
          } else {
            e.showContactDropDown = !e.showContactDropDown;
          }
        }
        return e;
      });
      contactData.contacts = updatedContactList;
      setContactData(contactData);
    }
  };

  const findCurrentContact = email => {
    const contactList = contactData.contacts;
    if (contactList) {
      return contactList.find(arrayValue => arrayValue.email === email);
    }
  };

  const getFullName = (name, fName, lName) => {
    if (fName && lName) {
      return `${fName} ${lName}`;
    } else if (name && name.trim().length > 0) {
      return name;
    } else if (fName) {
      return fName;
    } else if (lName) {
      return lName;
    }
    return "";
  };

  const getContactName = (name, fName, lName, contact) => {
    return (
      (fName || lName) && (
        <div
          className="name triggerContactCard underlineHighlight"
          onClick={() => slideContactCard(contact)}
        >
          {getFullName(name, fName, lName)}
        </div>
      )
    );
  };

  const markSelectedContact = (value, contactId) => {
    if (allContactSelected) {
      deselectCheckedPopupState.setPopupValues({
        callback: resetToSelectedCheckboxCbk
      });
      deselectCheckedPopupState.setPopup(true);
    } else {
      if (contactId === "all") {
        setExportAllContactSelected(value);
        toggleSelectAllTableContact();
        constructExportData("all", value);
      } else {
        setExportAllContactSelected(false);
        toggleSelectContactById(contactId);
        constructExportData();
      }
    }
  };

  const toggleSelectContactById = contactId => {
    if (contactId) {
      contactData?.contacts &&
        contactData.contacts.map(value => {
          value?.id === contactId && (value.isSelected = !value.isSelected);
        });
      setContactData(contactData);
    }
  };

  const toggleSelectAllTableContact = () => {
    contactData?.contacts &&
      contactData.contacts.map(value => {
        if (value?.id) {
          if (exportAllContactSelected) {
            value.isSelected = false;
            setExportAllContactSelected(false);
          } else {
            value.isSelected = true;
          }
        }
      });
    setContactData(contactData);
  };

  const constructExportData = (flag, value) => {
    const tempArr = [];
    const contactIdAndStageObj = [];
    const tempSelectedNames = [];
    contactData.contacts.map(value => {
      if (value?.id && (value?.isSelected || flag === "all")) {
        const {
          firstName = "",
          lastName = "",
          name = "",
          email = ""
        } = value || {};
        let contactName = Utils.getName(firstName, lastName, name, email);
        tempArr.push(value.id);
        tempSelectedNames.push(contactName);
        contactIdAndStageObj.push({
          id: value.id,
          stageName: value?.stageDisplayName
        });
      }
    });
    setExportData(flag === "all" ? (value ? tempArr : []) : tempArr);
    setSelectedContactObj(
      flag === "all"
        ? value
          ? contactIdAndStageObj
          : []
        : contactIdAndStageObj
    );

    setSelectedContactsNameList(tempSelectedNames);
  };

  const slideContactCard = contact => {
    selectedContactCard(contact);
  };

  const showDemoVideoCard = () => {
    return (
      !(
        contactData &&
        Object.keys(contactData)?.length &&
        contactData?.totalContacts > 0
      ) && activeTab === "total"
    );
  };

  const getStage = (stageDisplayName, stage) => {
    stage = stage?.toLowerCase();
    const className = stage === "opted_out" ? "optedout" : stage;

    return (
      <div className="status">
        <div className="stageCont">
          <span className={`stage ${className}`}>{stageDisplayName}</span>
        </div>
      </div>
    );
  };

  useEffect(() => {
    settingsData = settingsStoreData.settingsData;
  }, [settingsStoreData.settingsData]);

  useEffect(() => {
    if (!contactStoreData?.updateContactEmail && customListState?.activeList) {
      let payload = {
        ...contactStoreData.filterPayload,
        pageNo: 1,
        listId: customListState?.activeList?.id
      };
      tableLoadingFn(true);
      filterLoadingFn(true);
      contactStoreData.setFilterPayload(payload);
      ContactUtils.contactFilterByPayload(
        payload,
        tableLoadingFn,
        filterLoadingFn,
        true,
        false
      );
    }
  }, [customListState?.activeList]);

  useEffect(() => {
    if (!contactStoreData?.updateContactEmail) {
      setSearchKeyword("");
      setEmailClose(false);
      contactStoreData.setSearchContactFilter("total");
      setActiveTab("total");
      contactStoreData.setCurrentPage(1);
    }
  }, []);

  useEffect(() => {
    if (selectedFilter !== "" && selectedFilter === false) {
      if (!selectedFilter) {
        let payload = {
          filter: {},
          stageId: "",
          pageNo: 1,
          searchTerm: "",
          listId: customListState?.activeList?.id
        };
        tableLoadingFn(true);
        ContactUtils.contactFilterByPayload(
          payload,
          tableLoadingFn,
          filterLoadingFn,
          true,
          false
        );
      }
    }
  }, [selectedFilter]);

  useEffect(() => {
    if (contactStoreData?.updateContactEmail && contactData?.contacts) {
      showEditContactPopup(contactStoreData?.updateContactEmail, false);
    }
  }, [contactStoreData.updateContactEmail, contactData.contacts]);

  return (
    <>
      <div className="contactTable">
        <div className="contactBody" id="overflowCont">
          <div
            className={`ctTableHeader ${filterEnabled ? "filterEnabled" : ""}`}
          >
            <div className="ctSelectAll">
              {!loading && contactData?.contacts?.length && (
                <CheckBox
                  key="allContactsCheck"
                  id="all"
                  checked={exportAllContactSelected}
                  cbk={markSelectedContact}
                  allContactsChecked={allContactSelected}
                />
              )}
            </div>
            <div className="nameEmail">Name & Email</div>
            <div className="companyJobTile" title="Job Title & Company">
              Job Title & Company
            </div>
            {!filterEnabled && <div className="tag">Tags</div>}
            <div className="status">Stage</div>
            <div className="stageInsight">Email Insights</div>
            <div className="lastContacted">Last Contacted</div>
            {!filterEnabled && <div className="ctOwner">Owner</div>}
            <div className="actionDp">Actions</div>
          </div>
          {contactUploading && (
            <div className="contactUploadLoader">
              <div className="circleLoader animatedButton loading">
                <span className="load-text"></span>
              </div>
              &nbsp;We are processing your contacts
            </div>
          )}
          <div className="ctTableBodyWrapper">
            {!loading ? (
              contactData?.contacts?.length ? (
                <>
                  {contactData.contacts.map((contact, key) => {
                    let {
                      id,
                      firstName,
                      lastName,
                      email,
                      name,
                      insights,
                      company,
                      jobTitle,
                      stage,
                      lastContacted,
                      contactTags,
                      owner,
                      isSelected,
                      stageDisplayName,
                      accountId = ""
                    } = contact || {};
                    const defaultStage = stage;
                    stage =
                      stage &&
                      stage.length &&
                      stage.toLowerCase().replace("_", " ");
                    const options = {
                      year: "numeric",
                      month: "short",
                      day: "numeric"
                    };
                    lastContacted =
                      lastContacted &&
                      new Date(lastContacted).toLocaleDateString(
                        "en-US",
                        options
                      );

                    return (
                      <div
                        key={key}
                        className={`ctTableBody ${
                          filterEnabled ? "filterEnabled" : ""
                        }`}
                      >
                        <div className="ctSelectAll">
                          <CheckBox
                            key="autoBcc"
                            id={id}
                            checked={isSelected || false}
                            cbk={markSelectedContact}
                          />
                        </div>
                        <div className="nameEmail">
                          {getContactName(name, firstName, lastName, contact)}
                          <span
                            className="email triggerContactCard underlineHighlight"
                            onClick={() => slideContactCard(contact)}
                          >
                            {email}
                          </span>
                        </div>
                        <div className="companyJobTile">
                          <p title={jobTitle} className="job">
                            {jobTitle}
                          </p>
                          <p title={company} className="company">
                            {company}
                          </p>
                        </div>
                        {!filterEnabled && (
                          <div className="tag">
                            {ContactUtils.getTagNameDom(contactTags)}
                          </div>
                        )}
                        {getStage(stageDisplayName, defaultStage)}
                        <div className="stageInsight">
                          {insights !== "undefined" &&
                          insights !== null &&
                          Object.keys(insights).length > 0 ? (
                            <EmailInsightsComponent emailInsight={insights} />
                          ) : (
                            "--"
                          )}
                        </div>
                        <div className="lastContacted">
                          <div className="lastContacted column">
                            {lastContacted || "--"}
                          </div>
                        </div>
                        {!filterEnabled && (
                          <div
                            className="ctOwner"
                            onMouseEnter={() =>
                              setShowTooltip({ key, value: true })
                            }
                            onMouseLeave={() =>
                              setShowTooltip({ key, value: false })
                            }
                          >
                            {(owner && (
                              <>
                                <span className="circleOwner">
                                  {Utils.getOwnerInitial(owner)}
                                </span>
                                {showTooltip.key === key &&
                                  showTooltip.value && (
                                    <CustomTooltip
                                      text={`Added by ${Utils.getOwnerName(
                                        owner
                                      )}`}
                                    />
                                  )}
                              </>
                            )) ||
                              "--"}
                          </div>
                        )}
                        <div className="actionDp moreOption">
                          <i
                            className="material-icons-outlined icon-hover-btn"
                            onClick={e => showDropDownMenu(email, null, e)}
                            title="More Options"
                          >
                            more_vert
                          </i>
                          <div style={{ position: "relative" }}>
                            {contact.showContactDropDown && (
                              <>
                                <DropDownMenu
                                  options={[
                                    ...getUpdatedOptions(
                                      stage === "opted out" ||
                                        stage === "bounced"
                                    ),
                                    ...dropdownOptions,
                                    ...getOptedOutOptions(
                                      stage === "opted out"
                                    ),
                                    accountId && viewAccountsOption
                                  ].filter(Boolean)}
                                  selected={selectedOption}
                                  referenceId={email}
                                  direction={
                                    key >= 0 && key <= 1 ? "topLeft" : "left"
                                  }
                                  parentPosition={contact.positionY}
                                  contact={contact}
                                />
                                {showUpdateDropDown &&
                                  !["opted out", "bounced"]?.includes(
                                    stage
                                  ) && (
                                    <DropDownMenu
                                      options={stageOptions}
                                      referenceId={email}
                                      direction={"bottomUp"}
                                      parentPosition={contact.positionY}
                                      contact={contact}
                                    />
                                  )}

                                <div
                                  className="dropdownMask"
                                  onClick={e => showDropDownMenu(email, false)}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                !contactUploading && (
                  <div className="add-contact-stage">
                    {activeTab !== "total" && (
                      <>
                        <i className="material-icons-outlined">
                          supervisor_account
                        </i>
                        No Contacts Found
                      </>
                    )}
                    {showDemoVideoCard() && (
                      <>
                        <img src={NoContactImg} alt="" />
                        <h6>Add Contacts</h6>
                        <AddContactBtn
                          validContactUpload={validContactUpload}
                          checkEligibleContactCount={checkEligibleContactCount}
                          setContactUploading={setContactUploading}
                          tableLoading={loading}
                          activeTab={activeTab}
                          setTableLoading={tableLoadingFn}
                          setFilterLoading={filterLoadingFn}
                          id="addContactBtnEmpty"
                          pageType="contacts"
                          isFromScheduleSeq={false}
                          contactLimit={contactLimit}
                        />
                        <FeatureDemoVideo
                          name={"Add Contacts"}
                          linkText={`How to Add contacts on Salesgear`}
                          type={"contactsPage"}
                        />
                      </>
                    )}
                  </div>
                )
              )
            ) : (
              <ContactLoader
                count={contactLimit}
                filterEnabled={filterEnabled}
              />
            )}
          </div>
        </div>
        <PaginationComponent
          callback={paginate}
          loading={loading}
          totalPages={totalPages}
          currentPage={contactData?.currentPage || 1}
        />
      </div>
    </>
  );
});

export { RenderContactTable };
export default RenderContactTable;
