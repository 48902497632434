import { action, observable } from "mobx";
import { URLS, makeApi } from "Utils/apiURL";
import { toasterState } from "Components/common/toaster";

class SyncLogsHistoryPopupState {
  @observable showPopup = false;
  @observable data = {};
  @observable limit = 10;
  @observable pageNo = 1;
  @observable totalLogs = 0;
  @observable totalPages = 0;
  @observable crmId = "";
  @observable source = "";
  @observable loader = true;

  @action
  setShowPopup = (value = "") => {
    this.showPopup = value;
  };

  @action
  setData = (value = {}) => {
    this.data = value;
  };

  @action
  setLimit = (value = "") => {
    this.limit = value;
  };

  @action
  setPageNo = (value = "") => {
    this.pageNo = value;
  };

  @action
  setTotalLogs = (value = "") => {
    this.totalLogs = value;
  };

  @action
  setTotalPages = (value = "") => {
    this.totalPages = value;
  };

  @action
  setCrmId = (value = "") => {
    this.crmId = value;
  };

  @action
  setSource = (value = "") => {
    this.source = value;
  };

  @action
  setLoader = (value = false) => {
    this.loader = value;
  };

  @action
  getSyncLog = async () => {
    this.setLoader(true);
    if (this.crmId) {
      try {
        const config = {
          url: URLS.getSyncLogs,
          method: "POST",
          data: {
            crmSettingId: this.crmId,
            pageNo: this.pageNo,
            limit: this.limit,
            ...(this.source && { source: this.source })
          }
        };
        let res = await makeApi(config);
        this.setLoader(false);
        if (res?.status === 200) {
          syncLogsHistoryPopupState.setData(res?.data?.logs);
          syncLogsHistoryPopupState.setPageNo(res?.data?.currentPage);
          syncLogsHistoryPopupState.setTotalPages(res?.data?.totalPages);
          syncLogsHistoryPopupState.setTotalLogs(res?.data?.totalLogs);
        } else {
          this.showPopup(false);
          toasterState.setToastMsg(
            "Something went wrong. Please try again later",
            "fail"
          );
        }
      } catch (err) {
        console.error("Sync logs listing error", err);
      }
    }
  };
}

const syncLogsHistoryPopupState = new SyncLogsHistoryPopupState();

export { syncLogsHistoryPopupState };
