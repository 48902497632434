import React, { useState, useContext, useEffect } from "react";
import { observer } from "mobx-react";
import Utils from "Utils/utils";
import makeApi, { URLS } from "Utils/apiURL";
import { toasterState } from "Components/common/toaster";
import { exportProcessingState } from "Components/popup/ExportProcessing";
import { exportEmailNotifyState } from "Components/popup/ExportEmailNotify";
import ExportBtn from "Components/common/ExportBtn";
import PageLimit from "Components/common/PageLimit";
import ContactSelectedCount from "Components/common/ContactSelectedCount";
import { ReactComponent as FilterIcon } from "Assets/svg/filter.svg";
import { outboxStoreData } from "./outboxStore";
import { OutboxContext } from "./main";

export const FilterComponent = observer(() => {
  const {
    inputValues = {},
    handleStateChange = () => {},
    setInputValues = () => {},
    allEmailsSelectedInView = false,
    setAllEmailsSelectedInView = () => {},
    setAllEmailsSelectedCheckbox = () => {}
  } = useContext(OutboxContext);
  const [selectedCount, setSelectedCount] = useState(0);
  const { name = "" } = outboxStoreData?.activeFilter;
  const { limit = 0, outboxData = {}, dataLoading = false } = inputValues || {};
  const { totalElements = 0 } = outboxData || {};
  const { result = [] } = outboxData || {};

  const handleLimit = (value = 10) => {
    outboxStoreData.setCurrentPage(1);
    handleStateChange("limit", value);
  };

  const limitObj = {
    limitPerPage: limit,
    text: "Showing",
    cbk: handleLimit,
    totalCount: totalElements
  };

  const getSelectedCount = () => {
    let count = 0;
    if (result?.length > 0) {
      count = ([...result] || []).reduce(
        (acc, item) => acc + (item?.isChecked ? 1 : 0),
        0
      );
    }
    setSelectedCount(count);
  };

  const exportCbk = () => {
    if (!allEmailsSelectedInView) {
      let tempEmailIds = [];
      if (result?.length > 0) {
        tempEmailIds =
          [...result]
            ?.filter(item => item?.isChecked)
            .map(item => item?.task?.id) || [];
      }
      handleExportAction(tempEmailIds);
    } else {
      handleExportAction([]);
    }
  };

  const constructExportPayload = (emailIds = []) => {
    if (!allEmailsSelectedInView) {
      return { outboxPayload: { ids: emailIds } };
    } else {
      let tempFilters = outboxStoreData.getFiltersPayload();
      return {
        outboxPayload: {
          ids: [],
          filters: { ...tempFilters, all: true }
        }
      };
    }
  };

  const clearSelectedLogs = (isClearLogs = false) => {
    setAllEmailsSelectedCheckbox(!isClearLogs);
    setAllEmailsSelectedInView(!isClearLogs);
    if (result?.length > 0) {
      setInputValues({
        ...inputValues,
        outboxData: {
          ...inputValues?.outboxData,
          result: inputValues?.outboxData?.result.map(item => ({
            ...item,
            isChecked: !isClearLogs
          }))
        }
      });
    }
  };

  const handleExportResponse = downloadLink => {
    if (!downloadLink) {
      exportProcessingState.setShowPopup(false);
      toasterState.setToastMsg(
        "Something went wrong. Please try again",
        "failure"
      );
    } else {
      exportProcessingState.completeAnimation();
      setTimeout(() => {
        Utils.downloadExportedContact(downloadLink);
        exportProcessingState.setShowPopup(false);
        setAllEmailsSelectedInView(false);
        clearSelectedLogs(true);
        toasterState.setToastMsg("Export is successfully completed", "success");
      }, 1000);
    }
  };

  const handleExportAction = async (emailIds = []) => {
    exportProcessingState.setShowPopup(true);
    const config = {
      url: URLS.exportOutboxEmails,
      method: "POST",
      data: constructExportPayload(emailIds)
    };
    let response = await makeApi(config);
    const { status = "", type = "", csvData = [] } = response?.data || {};
    const typeToCompare = "limit_reached";
    if (+status === 200 && type?.toLowerCase() === typeToCompare) {
      exportProcessingState.completeAnimation();
      exportProcessingState.setShowPopup(false);
      exportEmailNotifyState.setShowText("emails");
      exportEmailNotifyState.setShowPopup(true);
      setAllEmailsSelectedInView(false);
      clearSelectedLogs(true);
    } else if (+status === 200 && csvData?.length > 0) {
      Utils.exportCSVFile(
        [],
        csvData,
        "Salesgear-Inbox",
        handleExportResponse,
        true
      );
    } else {
      setAllEmailsSelectedInView(false);
      clearSelectedLogs(true);
      exportProcessingState.setShowPopup(false);
      toasterState.setToastMsg(
        "Something went wrong. Please try again",
        "failure"
      );
    }
  };

  useEffect(() => {
    getSelectedCount();
  }, [inputValues]);

  return (
    <div className="headerContents">
      <div className="leftHeaderContents">
        {!dataLoading && selectedCount > 0 && (
          <ContactSelectedCount
            selectedCount={selectedCount}
            totalCount={totalElements}
            loading={dataLoading}
            allContactSelected={allEmailsSelectedInView}
            setAllContactSelected={setAllEmailsSelectedInView}
            resetCbk={() => clearSelectedLogs(true)}
            selectAllCbk={() => clearSelectedLogs(false)}
            selectedType="item"
          />
        )}
      </div>
      <div className="rightHeaderContents">
        <PageLimit {...limitObj} />
        {!["all", "scheduled"].includes(name?.toLowerCase()) && (
          <ExportBtn
            selectedEntityCount={selectedCount || 0}
            cbk={exportCbk}
            loading={dataLoading}
            isExportEnabled={true}
            checked={selectedCount > 0}
            pageType="inbox"
            tooltipText="emails"
          />
        )}
        <FilterButton />
      </div>
    </div>
  );
});

const FilterButton = () => {
  const {
    inputValues = {},
    showFilter = false,
    setShowFilter = () => {}
  } = useContext(OutboxContext);
  const { filterLoading = false, dataLoading = false } = inputValues || {};

  let isClickable = !filterLoading && !dataLoading;

  const handleClick = () => {
    isClickable && setShowFilter(!showFilter);
  };

  return (
    <div
      className={`outboxFilterIconToggle ${isClickable ? "" : "disabled"} ${
        outboxStoreData?.tempFilterList?.length > 0 ? "filterUsed" : ""
      }`}
      onClick={handleClick}
    >
      <FilterIcon />
      <span className="text">Filter</span>
      {outboxStoreData?.tempFilterList?.length > 0 && (
        <span className="count">{outboxStoreData?.tempFilterList?.length}</span>
      )}
    </div>
  );
};

export default FilterComponent;
