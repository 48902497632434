import React from "react";
import { observer } from "mobx-react";
import TableHeader from "./TableHeader";
import { TableBody } from "./TableBody";
import { Pagination } from "./Pagination";
import { syncLogsHistoryPopupState } from "./state";

const SyncLogsHistoryPopup = observer(() => {
  const resetPopup = () => {
    syncLogsHistoryPopupState.setShowPopup(false);
    syncLogsHistoryPopupState.setPageNo(1);
    syncLogsHistoryPopupState.setLimit(10);
    syncLogsHistoryPopupState.setSource("");
  };

  return (
    syncLogsHistoryPopupState.showPopup && (
      <>
        <section className="syncLogsHistoryPopupWrapper">
          <div className="syncLogsHistoryPopupContainer card">
            <i className="material-icons closeIcon" onClick={resetPopup}>
              close
            </i>
            <div className="popupHeading">CRM Sync History</div>
            <div className="popupContentTable">
              <TableHeader />
              <TableBody />
            </div>
            <Pagination />
          </div>
        </section>
        <div className="syncConfirmationPopupOverlay" onClick={resetPopup} />
      </>
    )
  );
});

export { SyncLogsHistoryPopup };
export default SyncLogsHistoryPopup;
