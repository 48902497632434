import React from "react";
import { observer } from "mobx-react";
import sequenceContactStoreData from "./sequenceContactStore";
import { sequenceContactData } from "Stores/sequence/Contact";
import { sequenceRenderState } from "Pages/Dashboard/sequences/SequencePage/sequenceProperties";
import DateUtils from "Utils/DateUtils";

const SequenceContactFilterFooter = observer((props = {}) => {
  const {
    data = {},
    teamData = {},
    filterArr = [],
    limit = 10,
    searchTerm = "",
    pageNo = 1,
    seqId = "",
    filterLoadingFn = () => {},
    tableLoadingFn = () => {},
    setContactFilter = () => {},
    clearAllFilter = () => {}
  } = props || {};

  const getContactFields = (
    updatedFilterArr = filterArr,
    updatedData = data
  ) => {
    const validateKey = getValidateList(
      updatedFilterArr,
      updatedData,
      "contactFields"
    );
    if (validateKey) {
      return updatedData.contactFields.filter(
        item => item?.fieldName && item?.operator
      );
    }
    return [];
  };

  const getValidateList = (
    updatedFilterArr = [],
    updatedData = {},
    paramKey = ""
  ) => {
    if (paramKey) {
      return (
        updatedFilterArr?.length &&
        updatedFilterArr.includes(paramKey) &&
        updatedData?.[paramKey]?.length > 0
      );
    }
  };

  const getTagIds = (updatedFilterArr = filterArr, updatedData = data) => {
    let tagIds = [];
    const validateKey = getValidateList(updatedFilterArr, updatedData, "tags");
    if (validateKey) {
      updatedData.tags.forEach(ele => {
        if (ele?.checked) {
          tagIds.push(ele.id);
        }
      });
    }
    return tagIds;
  };

  const getMemberIds = (updatedFilterArr = filterArr, updatedData = data) => {
    let memberIds = [];
    const validateKey = getValidateList(
      updatedFilterArr,
      updatedData,
      "owners"
    );
    if (validateKey) {
      updatedData.owners.forEach(ele => {
        if (ele?.checked && !memberIds?.includes(ele?.memberId)) {
          memberIds.push(ele.memberId);
        }
      });
    }
    return memberIds;
  };

  const mapObj = {
    hasvisitedwebsite: "seqWebsiteVisitedCount",
    profileviewscount: "profileViewsCount",
    linkedinconnectionrequest: {
      sent: "liCrSentCount",
      accepted: "liCrAcceptedCount"
    },
    linkedinmessage: {
      sent: "liMessageSentCount",
      replied: "liMessageReplyCount"
    }
  };

  const mapMetricsItem = updatedValue => {
    return updatedValue.map(item => {
      if (item?.fieldName && item?.operator) {
        return ["hasvisitedwebsite", "profileviewscount"].includes(
          item?.fieldName?.toLowerCase()
        )
          ? {
              ...item,
              value: 0,
              operator: Boolean(item?.value === "true")
                ? "GREATER_THAN"
                : "EQUALS",
              fieldName: mapObj[item?.fieldName?.toLowerCase()]
            }
          : ["linkedinconnectionrequest", "linkedinmessage"].includes(
              item?.fieldName?.toLowerCase()
            )
          ? {
              ...item,
              value: 0,
              operator: "GREATER_THAN",
              fieldName:
                mapObj[item?.fieldName?.toLowerCase()][
                  item.value?.toLowerCase()
                ]
            }
          : item;
      }
    });
  };

  const getMetrics = (updatedFilterArr = filterArr, updatedData = data) => {
    const validateKey = getValidateList(
      updatedFilterArr,
      updatedData,
      "metrics"
    );
    const updatedValue = mapMetricsItem(updatedData?.metrics);
    let tempObj = { ...updatedData, metrics: updatedValue };
    if (validateKey) {
      return tempObj.metrics.filter(item => item?.fieldName && item?.operator);
    }
    return [];
  };

  const getDates = (updatedFilterArr = filterArr, updatedData = data) => {
    const validateKey = getValidateList(updatedFilterArr, updatedData, "dates");
    if (validateKey) {
      let dateObj = {};
      const fieldKey = {
        CREATED: "created",
        UPDATED: "updated",
        FIRST_CONTACTED: "firstContacted",
        LAST_CONTACTED: "lastContacted"
      };
      updatedData.dates.forEach(item => {
        const {
          fieldName = "",
          operator = "BETWEEN",
          fromDate = "",
          toDate = "",
          isSet = false,
          isNotSet = false
        } = item || {};
        if (
          fieldName &&
          ((operator === "BETWEEN" && fromDate && toDate) || isSet || isNotSet)
        ) {
          if (operator === "BETWEEN") {
            dateObj[fieldKey[fieldName]] = {
              fromDate: DateUtils.getTimeStamp(fromDate, "start"),
              toDate: DateUtils.getTimeStamp(toDate, "end")
            };
          } else {
            isSet && (dateObj[fieldKey[fieldName]] = { isSet });
            isNotSet && (dateObj[fieldKey[fieldName]] = { isNotSet });
          }
        }
      });
      return dateObj;
    }
    return "";
  };

  const applyFilter = async () => {
    filterLoadingFn(true);
    tableLoadingFn(true);
    const currentStep =
      sequenceContactStoreData?.filterPayload?.filters?.currentStep ||
      sequenceRenderState.filterByStep
        ? [
            sequenceContactStoreData?.filterPayload?.filters?.currentStep ||
              sequenceRenderState.filterByStep
          ]
        : [];

    const payload = {
      filters: {
        tagIds: getTagIds(filterArr, data),
        memberIds: getMemberIds(filterArr, data),
        searchTerm,
        ...(currentStep && { stepMetics: currentStep })
      },
      pageNo,
      limit,
      campaignId: seqId
    };
    let fields = getContactFields(filterArr, data);
    if (fields?.length) {
      payload.filters = { ...payload.filters, fields };
    }
    let metrics = getMetrics(filterArr, data);
    if (metrics?.length) {
      fields = fields?.concat(metrics);
      payload.filters = { ...payload.filters, fields };
    }
    let memberIdsFromTeam = [];
    if (teamData?.length > 0) {
      memberIdsFromTeam = [...teamData];
    }
    if (memberIdsFromTeam?.length > 0) {
      memberIdsFromTeam.forEach(item => {
        if (!payload?.filters?.memberIds?.includes(item)) {
          payload.filters.memberIds.push(item);
        }
      });
    }

    let dates = getDates(filterArr, data);
    if (dates && Object.keys(dates)?.length) {
      payload.filters = { ...payload.filters, ...dates };
    }

    sequenceContactStoreData.setFilterPayload(payload);

    const previousFields = sequenceContactStoreData?.topFilter?.fields || [];
    const statuses = sequenceContactStoreData?.topFilter?.statuses || [];
    let updatedFields = [];
    if (previousFields?.length) {
      if (payload?.filters?.fields?.length) {
        updatedFields = previousFields.concat(payload?.filters?.fields);
      } else {
        updatedFields = previousFields;
      }
    }

    const payloadForApi = {
      ...payload,
      filters: {
        ...payload?.filters,
        ...(statuses?.length > 0 && { statuses }),
        ...(updatedFields?.length && {
          fields: updatedFields
        })
      }
    };

    const analyticsPayload = {
      ...payload?.filters
    };
    sequenceContactStoreData.setOverallPayload(payloadForApi);
    sequenceContactData.getSeqContacts(payloadForApi);
    sequenceContactData.getSeqAnalyticsTab(seqId, analyticsPayload);
  };

  const cancelFilter = () => {
    clearAllFilter();
    setContactFilter(false);
  };

  const actionList = [
    {
      className: "cancelFilter",
      method: cancelFilter,
      name: "Cancel",
      id: "cancelFilter"
    },
    {
      className: "applyFilter",
      method: applyFilter,
      name: "Apply Filter",
      id: "applyFilter"
    }
  ];

  return (
    <div className="filterAction">
      {(actionList?.length > 0 &&
        actionList.map((item, index) => {
          const {
            className = "",
            name = "",
            id = "",
            method = () => {}
          } = item || {};
          return (
            <div
              className={className}
              onClick={method}
              key={`${id}Action${index}`}
            >
              {name}
            </div>
          );
        })) ||
        ""}
    </div>
  );
});

export { SequenceContactFilterFooter };
export default SequenceContactFilterFooter;
