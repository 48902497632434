import React, { useState, useEffect, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import { Utils } from "Utils/utils";
import { settingsStoreData } from "Stores/settingsData";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import { TabNavigation } from "../common/TabNavigation";
import ContactsTab from "./Contacts";
import OwnersTab from "../common/Owners";
import ActivityTab from "./ActivityTab";
import { hubspotStore } from "./HubspotStore";

const HubspotTabFields = observer(props => {
  const {
    className = "",
    crmSettings = {},
    constructHubspotConfig = () => {}
  } = props;
  const [activeTab, setActiveTab] = useState("activity");
  const [previousTab, setPreviousTab] = useState(activeTab);
  const fieldMappingRef = useRef();

  let location = useLocation(),
    history = useHistory(),
    hashValue = location?.hash;
  let hashUrlArr = ["#activity", "#contacts", "#owners"];

  const tabList = [
    { value: "Activity", id: "activity", path: "activity" },
    {
      value: "Contacts",
      id: "contacts",
      path: "contacts"
    },
    {
      value: "Owners",
      id: "owners",
      path: "owners"
    }
  ];

  const compareFields = (oldField = {}, newField = {}) => {
    let hasChange = false;
    if (oldField?.length !== newField?.length) {
      hasChange = true;
      return hasChange;
    } else {
      for (let i = 0, len = oldField.length; i < len; i++) {
        const {
          crmField: oldCrmField = "",
          sgField: oldSgField = "",
          syncDirection: oldSyncDirection = ""
        } = oldField[i];
        const {
          crmField: newCrmField = "",
          sgField: newSgField = "",
          syncDirection: newSyncDirection = ""
        } = newField[i];
        if (oldCrmField === newCrmField) {
          if (
            oldSgField !== newSgField ||
            oldSyncDirection !== newSyncDirection
          ) {
            hasChange = true;
            return hasChange;
          }
        } else {
          hasChange = true;
          return hasChange;
        }
      }
    }
    return hasChange;
  };

  const checkForChanges = (oldData = {}, newData = {}) => {
    let hasChange = false;
    const oldDataTags = oldData?.tags || [];
    const newDataTags = newData?.tags || [];
    if (
      oldData?.autoSyncEnabled !== newData?.autoSyncEnabled ||
      oldData?.sourceId !== newData?.sourceId ||
      oldData?.updateContact !== newData?.updateContact ||
      JSON.stringify(oldData?.pollingInterval) !==
        JSON.stringify(newData?.pollingInterval) ||
      compareFields(oldData?.fields, newData?.fields) ||
      JSON.stringify(oldDataTags?.sort()) !==
        JSON.stringify(newDataTags?.sort())
    ) {
      hasChange = true;
      return hasChange;
    }
    return hasChange;
  };

  const handleTabNavigation = (id, cbkVal = false) => {
    if (cbkVal) {
      setActiveTab(id);
    } else {
      const cbkValue = getActiveTab(id);
      cbkValue && setActiveTab(id);
    }
    history.push(`#${id}`);
  };

  const confirmationCbk = activeTab => {
    const { config: { contact = {} } = {} } = crmSettings || {};
    setActiveTab(activeTab);
    setPreviousTab(activeTab);
    confirmationPopupState.setShowPopup(false);
    hubspotStore.setHubspotContactConfig(contact);
    hubspotStore.setHubspotContactTags([...(contact?.tags || [])]);
    handleTabNavigation(activeTab, true);
  };

  const getActiveTab = activeTab => {
    let hasChange = false;
    if (previousTab !== activeTab) {
      const payload = constructHubspotConfig();
      const { config: { contact: payloadContact = {} } = {} } = payload || {};
      const { config: { contact = {} } = {} } = crmSettings || {};

      if (previousTab === "contacts") {
        hasChange = checkForChanges(contact, payloadContact);
      }
      if (hasChange) {
        confirmationPopupState.setPopupValues({
          content:
            "Are you sure you want to switch tab ?<br>  Any changes made will be lost.",
          actionBtnText: "Ok",
          callback: () => {
            confirmationCbk(activeTab);
          }
        });
        confirmationPopupState.setShowPopup(true);
        return false;
      } else {
        setActiveTab(activeTab);
        setPreviousTab(activeTab);
        return true;
      }
    }
  };

  const scrollToElement = () => {
    const { current } = fieldMappingRef || {};
    if (current) {
      current.scrollIntoView({
        behavior: "smooth",
        inline: "start",
        block: "nearest"
      });
    }
  };

  useEffect(() => {
    const updatedHashValue = hashValue || "";
    if (hashUrlArr?.includes(hashValue)) {
      setActiveTab(updatedHashValue.replace("#", ""));
    }
    return () => {
      settingsStoreData.setFromContacts(false);
      settingsStoreData.setSaveFromContacts(false);
    };
  }, []);

  useEffect(() => {
    if ("contacts"?.includes(activeTab)) {
      hubspotStore.getAllHubspotFields();
    }
    let queryParams = Utils.getQueryParams(location.search);
    let isFromContacts = queryParams.get("source") === "contact" || false;
    if (isFromContacts) {
      settingsStoreData.setFromContacts(true);
      settingsStoreData.setSaveFromContacts(true);
      scrollToElement();
    }
  }, [activeTab]);

  return (
    <div className={`crmWrapper ${className}`}>
      <div className="tabFieldsWrapper">
        <TabNavigation
          tabList={tabList}
          handleTabNavigation={handleTabNavigation}
          activeTab={activeTab}
        />
      </div>
      {activeTab === "contacts" && (
        <ContactsTab
          contact={Utils.createObjWithoutRef(crmSettings?.config?.contact)}
          fieldMappingRef={fieldMappingRef}
          id={crmSettings?.id}
        />
      )}
      {activeTab === "owners" && (
        <OwnersTab
          crmName="Hubspot"
          ownersList={hubspotStore?.hubspotOwnersList}
          setOwnersList={hubspotStore?.setHubspotOwnersList}
          config={hubspotStore?.hubspotOwnerConfig}
          setConfig={hubspotStore?.setHubspotOwnerConfig}
          getAllOwners={hubspotStore?.getAllOwners}
        />
      )}
      {activeTab === "activity" && <ActivityTab />}
    </div>
  );
});

export { HubspotTabFields };
export default HubspotTabFields;
