import { observable, action } from "mobx";

class EmailEditor {
  @observable dropFile = false;
  @observable imgCount = 0;

  @action
  setImgCount = val => {
    this.imgCount = val;
  };

  @action
  setDropFile = val => {
    this.dropFile = val;
  };
}
const emailEditor = new EmailEditor();
export { emailEditor };
