/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import SelectChannel from "./SelectChannel";
import MemberConfiguration from "./MemberConfiguration";
import ConnectOrDisconnectCard from "../ConnectOrDisconnectCard";
import slackIcon from "Assets/png/Slack.png";
import { SLACK_OAUTH_URL } from "Static/constant";
import { toasterState } from "Components/common/toaster";
import { Utils } from "Utils/utils";
import { ReactComponent as ThunderOrangeIcon } from "Assets/svg/thunderOrange.svg";
import UpgradeTooltip from "Components/Paywall/UpgradeTooltip";
import { PAYWALL_CONTENT } from "Model/model";
import FormActionButton from "../../ConnectInbox/Configure/FormActionButton";
import { MXP_EVENT } from "Static/MixPanelEvents";
import { slackDataStore } from "Stores/SlackDataStore";
import { confirmationPopupState } from "Components/common/confirmationPopup";

const selectChannelPlaceHolder = { channelName: "Select channel..." };

const defaultFunc = () => {};
const SlackConfigure = observer(props => {
  const {
    hideCbk = defaultFunc,
    showAdminPopup = defaultFunc,
    isFeatureEnabled = false,
    planName = "",
    infreePlan = false
  } = props;

  const {
    slackData: {
      slackConnected = false,
      settings: {
        authDetails: { slackTeamName = "" } = {},
        teamMembers = {},
        channelId = "",
        channelName = ""
      } = {}
    },
    slackChannels = []
  } = slackDataStore;

  const existingSelectedChannel = channelId
    ? { channelId, channelName }
    : selectChannelPlaceHolder;

  const [saveBtnLoader, setSaveBtnLoader] = useState(false);
  const [removeAccountLoader, setRemoveAccountLoader] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState(
    existingSelectedChannel
  );
  const [selectedChannelError, setSelectedChannelError] = useState(false);
  const [memberDetails, setMemberDetails] = useState(teamMembers);
  const [enableSaveChangeBtn, setEnableSaveChangeBtn] = useState(false);
  const [showUpgradeTp, setShowUpgradeTp] = useState(false);

  const openSlackOauthWindow = () => {
    if (SLACK_OAUTH_URL) {
      const w = 600;
      const h = 800;
      const left = window.screen.width / 2 - w / 2;
      const top = window.screen.height / 2 - h / 2;
      let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=${w},height=${h},left=${left},top=${top}`;
      window.open(SLACK_OAUTH_URL, "Slack Login", params);
      const oauthCbk = async ev => {
        if (ev?.data?.type === "SLACK_CONNECT_OAUTH") {
          window.removeEventListener("message", oauthCbk);
          if (ev?.data?.code?.toString() === "200") {
            toasterState.setToastMsg(
              "Slack account connected successfully",
              "success"
            );
            Utils.mixpanelTrack(MXP_EVENT.CRM_CONNECTED, {
              type: "Slack"
            });
            slackDataStore.getSlackData();
            slackDataStore.getSlackChannels();
          } else {
            toasterState.setToastMsg(
              "Something went wrong. Please try again later",
              "fail"
            );
          }
        }
      };
      window.addEventListener("message", oauthCbk);
    }
  };

  const removeAccount = async () => {
    const cbk = async () => {
      setRemoveAccountLoader(true);
      await slackDataStore.removeSlackAccount();
      setRemoveAccountLoader(false);
      slackDataStore.setSlackChannels([]);
      slackDataStore.setSlackData("");
    };

    if (Utils.isAdmin()) {
      confirmationPopupState.setPopupValues({
        content:
          "Are you sure? Disconnecting the CRM will stop all current and scheduled sync jobs.",
        actionBtnText: "Disconnect",
        cancelBtnText: "Cancel",
        callback: () => {
          cbk();
        }
      });
      confirmationPopupState.setShowPopup(true);
    } else {
      showAdminPopup("slack");
    }
  };

  const addOrRemoveAccount = () => {
    !slackConnected ? openSlackOauthWindow() : removeAccount();
  };

  const saveSettings = async () => {
    if (Utils.isAdmin()) {
      if (selectedChannel?.channelId && isFeatureEnabled) {
        setSaveBtnLoader(true);
        const payload = getSlackMemberConfigPayload();
        await slackDataStore.updateMemberConfig(payload);
        setSaveBtnLoader(false);
        setEnableSaveChangeBtn(false);
      } else {
        setSelectedChannelError(true);
        setEnableSaveChangeBtn(false);
      }
    } else {
      showAdminPopup();
      setEnableSaveChangeBtn(false);
    }
  };

  const getSlackMemberConfigPayload = () => {
    const { channelId = "", channelName = "" } = selectedChannel;
    return { teamMembers: memberDetails, channelId, channelName };
  };

  const getSaveButtonClassName = () => {
    let className = "slackConfig ";
    className += saveBtnLoader ? "loading " : "";
    className += !enableSaveChangeBtn ? "disabled " : "";
    return className;
  };

  const updateStateValues = () => {
    const existingSelectedChannel = channelId
      ? { channelId, channelName }
      : selectChannelPlaceHolder;
    setSelectedChannel(existingSelectedChannel);
    setMemberDetails(teamMembers);
  };

  useEffect(() => {
    slackDataStore?.slackData && updateStateValues();
  }, [slackDataStore.slackData]);

  return (
    <div className="crmConfigurePage slack">
      <ConnectOrDisconnectCard
        isConnected={slackConnected}
        addOrRemoveAccount={addOrRemoveAccount}
        connectedEmail={slackTeamName}
        removeLoader={removeAccountLoader}
        type="Slack"
        icon={slackIcon}
      />
      {slackConnected && (
        <>
          <SelectChannel
            selectedChannel={selectedChannel}
            setSelectedChannel={setSelectedChannel}
            selectedChannelError={selectedChannelError}
            setSelectedChannelError={setSelectedChannelError}
            setEnableSaveChangeBtn={setEnableSaveChangeBtn}
          />
          <div
            className="activityLogWrapper"
            onMouseEnter={() => setShowUpgradeTp(true)}
            onMouseLeave={() => setShowUpgradeTp(false)}
          >
            <MemberConfiguration
              memberDetails={memberDetails}
              setMemberDetails={setMemberDetails}
              setEnableSaveChangeBtn={setEnableSaveChangeBtn}
              isFeatureEnabled={isFeatureEnabled}
            />
            {!isFeatureEnabled && (
              <ThunderOrangeIcon className="thunderOrange" />
            )}

            {showUpgradeTp &&
              !isFeatureEnabled &&
              (infreePlan ||
                ["pro", "basic"].includes(planName?.toLowerCase())) && (
                <UpgradeTooltip
                  {...PAYWALL_CONTENT[
                    infreePlan || planName?.toLowerCase() === "basic"
                      ? "slackSettings"
                      : "slackSettingsUnlimited"
                  ]}
                />
              )}
          </div>
        </>
      )}{" "}
      <FormActionButton
        cancel={() => hideCbk()}
        save={saveSettings}
        saveBtnText="Save changes"
        loader={saveBtnLoader}
        className={getSaveButtonClassName()}
        disabled={!isFeatureEnabled}
      />
    </div>
  );
});

export default SlackConfigure;
export { SlackConfigure };
