import React, { useContext } from "react";
import { StepsContext } from "./Steps";
import Utils from "Utils/utils";
import { addStagesPopupState } from "Pages/Dashboard/sequences/Stages/AddStagesPopupState";
import MoreOptionSlide from "Components/common/MoreOptionSlide";

const StageHeader = props => {
  const {
    stepNo = 1,
    date = "",
    type = "",
    steps = {},
    name = "",
    seqId = "",
    currentStep = {},
    daysFromStart = 1,
    analyticStepLoader = false,
    setAnalyticStepLoader = () => {}
  } = props || {};

  const {
    moveStagesAction = () => {},
    deleteStep = () => {},
    stepBadgeAction = () => {}
  } = useContext(StepsContext);

  const stageLength = Object.keys(steps).length || 0;
  const {
    variants = {},
    daysLater = 1,
    uniqueId = 0,
    analytics = {}
  } = currentStep || {};

  const {
    scheduledCount = 0,
    pausedCount = 0,
    completedCount = 0,
    bounceCount = 0,
    replyCount = 0,
    failedCount = 0,
    stageType = "",
    liMessageReplyCount = 0
  } = analytics || {};

  const editStep = () => {
    let tempDaysLater = stepNo === 1 ? 0 : daysLater;

    const popupValues = {
      btnText: "Update",
      currentStep: stepNo,
      stageType: type,
      daysLater: tempDaysLater,
      isEdit: true,
      daysFromStart,
      steps,
      previousDaysLater: tempDaysLater,
      notes: variants?.[1]?.notes || "",
      text: variants?.[1]?.text || variants?.[1]?.notes || "",
      subject: variants?.[1]?.subject || "",
      defaultTask: variants?.[1]?.linkedInTaskType || "",
      defaultLiAutomaticTask: variants?.[1]?.linkedInTaskType || ""
    };
    addStagesPopupState.setPopupValues(popupValues);
    addStagesPopupState.setShowPopup(true);
  };

  const additionalStagesMoreAction = [
    {
      icon: "arrow_upward",
      name: "Move up",
      key: "moveUp",
      method: () => moveStagesAction(stepNo, type, "moveUp", seqId)
    },
    {
      icon: "arrow_downward",
      name: "Move down",
      key: "moveDown",
      method: () => moveStagesAction(stepNo, type, "moveDown", seqId)
    }
  ];

  const getUpdatedStageMoreAction = () => {
    if (stageLength === 1) {
      if (type !== "email") {
        return [...editAction, ...deleteAction];
      } else {
        return [...deleteAction];
      }
    } else if (stageLength > 1) {
      if (+stepNo === 1) {
        if (type !== "email") {
          return [
            ...editAction,
            additionalStagesMoreAction[1],
            ...deleteAction
          ];
        } else {
          return [additionalStagesMoreAction[1], ...deleteAction];
        }
      } else if (+stepNo === +stageLength) {
        return [...editAction, additionalStagesMoreAction[0], ...deleteAction];
      } else {
        return [...editAction, ...additionalStagesMoreAction, ...deleteAction];
      }
    }
  };

  const editAction = [
    {
      icon: "create",
      name: "Edit",
      key: "edit",
      method: editStep
    }
  ];

  const deleteAction = [
    {
      icon: "delete",
      name: "Delete",
      key: "delete",
      method: event => deleteStep(event, seqId, stepNo, steps[stepNo])
    }
  ];

  const renderData = [
    {
      id: "completed",
      name: "Completed",
      className: "stageCompleted",
      count: completedCount
    },
    {
      id: "ongoing",
      name: "Scheduled",
      className: "stageScheduled",
      count: scheduledCount
    },
    {
      id: "paused",
      name: "Paused",
      className: "stagePaused",
      count: pausedCount
    },
    {
      id: "failed",
      name: "Failed",
      className: "stageFailed",
      count: failedCount
    },
    {
      id: "bounced",
      name: "Bounced",
      className: "stageBounced",
      count: bounceCount,
      fieldName: "bounceCount"
    },
    {
      id: "replied",
      name: "Replied",
      className: "stageReplied",
      count:
        stageType.toLowerCase() === "manualemail" ||
        stageType.toLowerCase() === "email"
          ? replyCount
          : liMessageReplyCount,
      fieldName: "totalReplyCount"
    }
  ];

  return (
    <div className="stageHeader">
      <div className="stepNo">
        <span className="text">Step {stepNo}</span>
        <span className="sharpPointer" />
      </div>
      <span className="stageType">
        <span className="text">{name}</span>
        <span className="sharpPointer" />
      </span>
      <span
        className={`stageDate ${
          parseInt(stepNo) === 1 ? "stageEditDisabled" : ""
        }`}
        onClick={editStep}
      >
        <span className="text">{date}</span>
        <span className="sharpPointer" />
      </span>
      <div className="stageStatusBadgeWrap" onClick={e => stepBadgeAction(e)}>
        {renderData.map(val => {
          const {
            id = "",
            name = "",
            count = 0,
            className = "",
            fieldName = ""
          } = val || {};

          return (
            <>
              {analyticStepLoader ? (
                <span className="stageStatusBadgeWrap linear-background" />
              ) : (
                (count > 0 && (
                  <span
                    id={id}
                    data-uniqueid={uniqueId}
                    data-id={fieldName}
                    key={`${id}stepBadge`}
                    className={className}
                  >{`${Utils.formatNumberToString(count)} ${name}`}</span>
                )) ||
                ""
              )}
            </>
          );
        })}
      </div>
      <MoreOptionSlide
        dropdownOptions={getUpdatedStageMoreAction()}
        id={seqId}
        stepNo={stepNo}
      />
    </div>
  );
};

export default StageHeader;
export { StageHeader };
