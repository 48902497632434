import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { ImportNow } from "../common/ImportNow";
import FieldMapping from "../common/FieldMapping";
import { zohoDataStore } from "./ZohoStoreData";
import AddTags from "../common/AddTags";
import AutoImport from "../common/AutoImport";
import UpdateContacts from "../common/UpdateContacts";
import ListView from "../common/ListView";

const Contacts = observer(props => {
  const { contact = {}, id = "" } = props;
  const [dropdownWidth, setDropdownWidth] = useState("25%");

  const findWidthOfDropdown = () => {
    return (
      document.querySelector(".crmListView .filterDropdownWrap")?.offsetWidth ||
      "270"
    );
  };

  useEffect(() => {
    zohoDataStore.getContactsListView();
    setDropdownWidth(findWidthOfDropdown());
    return () => {
      zohoDataStore.setZohoLeadsList("");
    };
  }, []);

  return (
    <div className="contactsWrapper">
      <div className="autoImportSettings">
        <ListView
          crmName="Zoho"
          list={zohoDataStore?.zohoContactsList}
          config={zohoDataStore.zohoContactConfig}
          setConfig={zohoDataStore.setZohoContactConfig}
        />
        <AutoImport
          crmName="Zoho"
          data={zohoDataStore?.zohoData}
          config={zohoDataStore.zohoContactConfig}
          setConfig={zohoDataStore.setZohoContactConfig}
        />
        <AddTags
          type="CONTACTS"
          dropdownWidth={dropdownWidth}
          config={zohoDataStore.zohoContactConfig}
          selectedTags={zohoDataStore.zohoContactTags}
          setSelectedTags={zohoDataStore.setZohoContactTags}
        />
        <UpdateContacts
          config={zohoDataStore.zohoContactConfig}
          setConfig={zohoDataStore.setZohoContactConfig}
        />
        <ImportNow
          type="CONTACTS"
          lastSynced={contact?.lastSynced}
          syncNow={contact?.syncNow}
          syncStartTime={contact?.syncStartTime}
          listDetails={zohoDataStore?.zohoContactConfig}
          crmName="Zoho"
          id={id}
        />
      </div>
      <div className="fieldMapping">
        <FieldMapping
          type="CONTACTS"
          mappingConfig={contact?.fields}
          config={zohoDataStore.zohoContactConfig}
          setConfig={zohoDataStore.setZohoContactConfig}
          crmName="Zoho"
          fields={zohoDataStore?.zohoFields}
        />
      </div>
    </div>
  );
});

export { Contacts };
export default Contacts;
