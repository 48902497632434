import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { scheduleDataStore } from "Stores/ScheduleData";

export const useScheduleDataOnPathMatch = path => {
  const location = useLocation();
  const isSelected = location.pathname === path;

  useEffect(() => {
    if (isSelected) {
      scheduleDataStore.getScheduleData();
    }
  }, [isSelected]);
};
